import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { GET_ALL_VENDORS } from '../../queries';
import Paper from '../modules/Paper';
import { Row, Col } from '../modules/Grid';
import { Overline } from '../modules/Typography';
import { VendorsContext } from '../pages/Vendors';
import { UserContext } from '../../contexts/UserContextProvider';

const VendorCards = props => {
    const { setVendorCount, isVendor } = useContext(VendorsContext);
    const { publicationState } = useContext(UserContext);
    const { data, loading } = useQuery(GET_ALL_VENDORS, { variables: { sort: props.sort, publicationState } });
    useEffect(() => {
        if (data) {
            const vendors = data.vendors || data.teams;
            setVendorCount(vendors.length);
        }
    }, [data]);

    if (loading) {
        const cards = [];
        for (let i = 0; i < 16; i++) {
            cards.push(
                <Col sm={3} key={i}>
                    <Paper className='Vendors__card flex center align-center' />
                </Col>
            );
        }
        return (
            <Row>
                {cards}
            </Row>
        );
    }

    const vendors = data ? data.vendors || data.teams : [];
    const link = isVendor ? 'vendors' : 'teams';
    return (
        <Row>
            {vendors.map(vendor => (
                <Col xs={6} md={4} lg={3} key={vendor.id}>
                    <Link to={`/${link}/${vendor.slug}`}>
                        <Paper className='Vendors__card flex center align-center'>
                            {!vendor.published_at && <Overline className='Vendors__card__unpublished error-text'>Unpublished</Overline>}
                            <img className='Vendors__card__image' src={vendor.darkImage?.url || vendor.image?.url || ''} />
                        </Paper>
                    </Link>
                </Col>
            ))}
        </Row>
    );
};

VendorCards.propTypes = {
    sort: PropTypes.string
};

export default VendorCards;
