import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col } from '../modules/Grid';
import { Overline, Heading, Body, Subtitle, Caption } from '../modules/Typography';
import Button from '../modules/Button';
import Paper from '../modules/Paper';
import parseMarkdown from '../../utils/markdownParser';
import getCategoryName from '../../utils/getCategoryName';
import { WidthContext } from '../../contexts/WidthContextProvider';

const FeaturedPost = props => {
    const { isMobile } = useContext(WidthContext);
    const { title, content, date, media, slug } = props.post;
    const categoryName = getCategoryName(props.post);
    const text = content.find(comp => comp.body);
    const body = text ? text.body : '';
    const words = body.split(' ').slice(0, 25).join(' ') + '...';
    return (
        <Paper className='Spotlight__FeaturedPost' elevation={4}>
            {isMobile && <div className='Spotlight__FeaturedPost__mobileImage' style={{ backgroundImage: `url(${media.url})` }} />}
            <Row className='Spotlight__FeaturedPost__row'>
                <Col xs={12} md={5} className='Spotlight__FeaturedPost__details flex column align-flex-start center'>
                    <Overline color='secondary-dark'>{categoryName}</Overline>
                    {isMobile ? (
                        <Fragment>
                            <Subtitle className='Spotlight__FeaturedPost__title' number={3}>{title}</Subtitle>
                            <Caption className='Spotlight__FeaturedPost__date' number={1} fadedTwoThirds>{moment(date).format('LL')}</Caption>
                            <Body number={2} fadedTwoThirds className='Spotlight__FeaturedPost__body'>{parseMarkdown(words)}</Body>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Heading className='Spotlight__FeaturedPost__title' number={4} heavy>{title}</Heading>
                            <Body className='Spotlight__FeaturedPost__date' number={2} fadedTwoThirds>{moment(date).format('LL')}</Body>
                            <Subtitle number={3} className='Spotlight__FeaturedPost__body'>{parseMarkdown(words)}</Subtitle>
                            <Button
                                type='tertiary'
                                trailingIcon='arrow-std-right'
                                color='primary-light'
                                size='large'
                                className='Home__FeaturedProduct__button'
                                link={`/industry-spotlight/${slug}`}
                            >Read More
                            </Button>
                        </Fragment>
                    )}
                </Col>
                {!isMobile && (
                    <Col className='Spotlight__FeaturedPost__image__container' sm={7}>
                        {media.mime.includes('image') ? (
                            <div className='Spotlight__FeaturedPost__image' style={{ backgroundImage: `url(${media.url})` }} />
                        ) : (
                            <video className='Spotlight__FeaturedPost__image' autoPlay>
                                <source src={media.url} type='video/mp4' />
                            </video>
                        )}
                    </Col>
                )}
            </Row>
        </Paper>
    );
};
FeaturedPost.propTypes = {
    post: PropTypes.object
};

export default FeaturedPost;
