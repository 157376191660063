import React, { useContext } from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useQuery } from '@apollo/client';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';
import {
    GET_HOME_PAGE
} from '../../queries';
import { Container } from '../modules/Grid';
import Loading from '../modules/Loading';
import Button from '../modules/Button';
import HomeSectionHeader from '../home/HomeSectionHeader';
import ProductSlider from '../home/ProductSlider';
import FeaturedPosts from '../home/FeaturedPosts';
import HomeHero from '../home/HomeHero';
import { DataContext } from '../../contexts/DataContextProvider';

export const HomeContext = React.createContext();

const Home = props => {
    const { data, loading } = useQuery(GET_HOME_PAGE);
    const { allProducts: products } = useContext(DataContext);
    if (loading) return <Loading />;
    const { slides, header, headerDescription, productContainer = [] } = data.homePage;
    const allProducts = _.uniqBy([...productContainer.map(prod => (prod.product)), ...products], 'id');

    return (
        <HomeContext.Provider value={{ }}>
            <div className='Home'>
                <div className='fixed-center' />
                <Helmet><title>Production Technology Portal</title></Helmet>
                <HomeHero header={header} headerDescription={headerDescription} slides={slides} />
                <Container>
                    <Element name='section-1'>
                        <div className='Home__sectionSpacer' />
                    </Element>
                    <HomeSectionHeader name='Products' />
                    <div className='Home__Industry mobile-container'>
                        <Link to='/categories' className='Home__Industry__link'>
                            <Button
                                type='primary'
                                color='primary-light'
                                size='large'
                            >Browse All
                            </Button>
                        </Link>
                    </div>
                </Container>
                <ProductSlider slides={allProducts} />
                <div className='Home__Industry__area'>
                    <Container>
                        <Element name='section-2'>
                            <div className='Home__sectionSpacer' />
                        </Element>
                        <HomeSectionHeader name='Industry Spotlight' />
                        <div className='Home__Industry mobile-container'>
                            <Link to='/industry-spotlight' className='Home__Industry__link'>
                                <Button
                                    type='primary'
                                    color='primary-light'
                                    size='large'
                                >Browse All
                                </Button>
                            </Link>
                            <FeaturedPosts posts={data.posts.slice(0, 4)} />
                        </div>
                    </Container>
                </div>
            </div>
        </HomeContext.Provider>
    );
};

export default Home;
