import React from 'react';
import PropTypes from 'prop-types';
import { Caption } from '../modules/Typography';

const VendorFooter = props => {
    return (
        <div className={`VendorFooter flex center ${props.home ? 'home-footer' : ''}`}>
            <Caption className='VendorFooter__finePrint'>© Copyright - The Walt Disney Studios - Studio Technology - Production Technology Portal</Caption>
        </div>
    );
};

VendorFooter.propTypes = {
    home: PropTypes.bool
};

export default VendorFooter;
