import { gql } from '@apollo/client';

export const GET_VENDOR_CATEGORIES = gql`
	query categories ($publicationState: PublicationState) {
		categories (sort: "name", publicationState: $publicationState) {
    id
    name
    slug
    image {
      url
    }
    products  {
      id
      name
      published_at
      vendor {
        id
      }
    }
    subcategories {
      id
      name
      slug
      products  {
        id
        name
        published_at
        vendor {
          id
        }
      }
    }
  }
	}
`;

export const GET_SUBCATEGORY_VENDOR_PRODUCTS = gql`
	query getSubcategoryProducts ($id: ID! $publicationState: PublicationState) {
		subcategory(id: $id, publicationState: $publicationState) {
			id
			name
			category {
				id
				name
			}
			products {
				id
				name
        		slug
        		published_at
				original {
					id
				}
				thumbnail {
					url
				}
				subcategories {
					id
					name
					category {
						id
						name
					}
				}
				vendor {
					id
					name
    			slug
    			published_at
				}
			}
		}
	}
`;

export const GET_CATEGORY_VENDOR_PRODUCTS = gql`
	query getCategoryProducts ($id: ID! $publicationState: PublicationState) {
		category(id: $id, publicationState: $publicationState) {
			id
			name
			products {
					id
					name
					slug
					published_at
					original {
						id
					}
					thumbnail {
						url
					}
					categories {
						id
						name
					}
					subcategories {
						id
						name
						category {
						  id
						  name
						}
					}
					vendor  {
						id
						slug
						name
						published_at
					}
			}
			subcategories {
				id
				name
				products {
					id
					name
					slug
					published_at
					original {
						id
					}
					thumbnail {
						url
					}
					subcategories {
						id
						name
						category {
						  id
						  name
						}
					}
					vendor  {
						id
						slug
						published_at
						name
					}
				}
			}
		}
	}
`;

export const GET_ALL_CATEGORIES = gql`
  query categories {
    categories (sort: "name") {
    id
    name
    slug
    image {
      url
    }
		products {
      id
    }
    subcategories {
      id
      name
      slug
      products {
      	id
    	}
      category {
        id
        name
      }
      }
    }
  }
`;

export const GET_ALL_PRODUCTS = gql`
	query products ($publicationState: PublicationState) {
	products(publicationState: $publicationState limit: 10000) {
		id
		name
		marvel
        pixar
        disney
        twentiethCentury
        searchlight
        lucasfilm
        disneyAnimation
		slug
    	published_at
		original {
			id
		}
		thumbnail {
			url
		}
		vendor  {
			name
			id
      	published_at
			slug
		}
		categories {
			name
			id
		}
		subcategories {
			name
			id
			category {
			  id
			  name
			}
		}
	}
}
`;
