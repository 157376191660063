import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Switch = props => {
    const [id] = useState(props.id || `switch-${Math.floor(Math.random() * 1000)}`);

    return (
        <div className={`Switch ${props.className} ${props.disabled ? 'Switch--disabled' : ''} ${props.loading ? 'Switch--loading' : ''}`}>
            <input
                id={id}
                className='Switch__input'
                type='checkbox'
                checked={props.checked}
                onChange={props.onChange}
            />
            <label className='Switch__label' htmlFor={id} />
            <div className={`Switch__track ${props.checked ? 'Switch__track--checked' : ''}`} />
            <div className={`Switch__thumb ${props.checked ? 'Switch__thumb--checked' : ''}`} />
        </div>
    );
};

export default Switch;

Switch.propTypes = {
    // whether or not the switch is checked
    checked: PropTypes.bool,

    // handle the switch change event
    onChange: PropTypes.func,

    // whether or not the switch is disabled
    disabled: PropTypes.bool,

    // className to pass down to tags
    className: PropTypes.string,

    // id to to associate the switch with
    id: PropTypes.string,

    // whether or not the mutation is loading
    loading: PropTypes.bool
};

Switch.defaultProps = {
    disabled: false,
    className: '',
    loading: false
};
