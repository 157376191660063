import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Heading, Subtitle } from '../modules/Typography';
import IconButton from '../modules/IconButton';

import { DataContext } from '../../contexts/DataContextProvider';
import Category from './Category';
import { CategoryContext } from '../pages/Categories';
import { WidthContext } from '../../contexts/WidthContextProvider';

const CategoriesList = props => {
    const { listOpen, setOpen } = useContext(CategoryContext);
    const { isMobile } = useContext(WidthContext);
    const { setSubcategory, setCategory, subcategory, selectedCategory } = useContext(DataContext);

    const handleClick = () => {
        setSubcategory(null);
        setCategory(null);
    };
    const isAllActive = !subcategory && !selectedCategory;

    return (
        <div className='Categories__List flex align-flex-start'>
            <div className={`flex column flex-start align-flex-start ${!listOpen ? 'visually-hidden' : ''}`}>
                <Heading className='Categories__List__header' number={5}>Categories</Heading>
                <Subtitle
                    className={`Categories__category__name ${isAllActive ? 'active' : ''} button`}
                    number={1}
                    onClick={handleClick}
                >All Categories
                </Subtitle>

                {props.categories && props.categories.map(category => (
                    <Category category={category} key={category.id} />
                ))}
            </div>
            {isMobile &&
            <IconButton
                name={listOpen ? 'caret-left' : 'caret-right'}
                className='Categories__List__mobileIcon'
                onClick={() => setOpen(!listOpen)}
            />}
        </div>
    );
};

CategoriesList.propTypes = {
    categories: PropTypes.array
};

export default CategoriesList;
