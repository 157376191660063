import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import Loading from '../modules/Loading';

const PdfViewer = props => {
    const [pages, setPages] = useState(0);

    const handleLoadSuccess = ({ numPages }) => {
        setPages(numPages);
    };
    const handleError = (err) => {
        console.log('err', err);
    };

    return (
        <Document
            file={props.src}
            onLoadSuccess={handleLoadSuccess}
            onLoadError={handleError}
            className='PdfViewer__Document'
            loading={<Loading />}
            noData='Loading...'
        >

            {Array.from(
                new Array(pages),
                (el, index) => (
                    <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        className='PdfViewer__Page'
                        scale={1}
                    />
                )
            )}
        </Document>

    );
};

PdfViewer.propTypes = {
    src: PropTypes.string
};

export default PdfViewer;
