import { gql } from '@apollo/client';

export const GET_VENDOR_DETAIL = gql`
query getVendors($slug: String!, $publicationState: PublicationState) {
  vendors (publicationState: $publicationState, where: {slug: $slug
  }) {
    id
    name
    slug
    published_at
    image {
      url
    }
    darkImage {
      url
    }
    users {
      id
    }
    description
    Contacts {
      name
      type
      info
      title
    }
    locations {
      city
      state
      country
    }
    websiteUrl
    facebookUrl
    instagramUrl
    twitterUrl
    linkedinUrl
    products {
      id
      name
      slug
      published_at
      categories {
        id
        name
      }
      thumbnail {
        url
      }
      subcategories {
        id
        name
        category {
          id
          name
        }
      }
    }
  }
}`;

export const VENDOR_GET_VENDOR = gql`
  query getVendors($slug: String!) {
    vendors (publicationState: PREVIEW, where: {slug: $slug
    }) {
      id
      name
      slug
      image {
        url
        name
      }
      darkImage {
        url
        name
      }
      published_at
      approvalStatus
      updated_at
      updater {
        username
      }
      original {
        id
        name
        slug
        products (sort: "name") {
          id
          name
          slug
          description
          elevatorPitch
          constraints
          status
          published_at
          approvalStatus
          original {
            id
            name
            slug
            published_at
          }
          notifications {
            id
            published
            users_permissions_users {
              id
            }
          }
          videoThumbnail {
            url
          }
          subcategories {
            id
            name
            category {
              id
              name
            }
          }
          videoUrl
          media {
            ext
            url
          }
          applications {
            id
            title
          }
          relatedFiles {
            id
            title
            description
            document {
              id
              url
            }
          }
          details
          gallery{
            id
            text
            image{
              id
              caption
              height
              width
              url
            }
          }
          comments{
            id
            text
            restricted
          }
          qa{
            id
            question
            answer
          }
          contacts{
            id
            name
            type
            info
            title
          }
        }
      }
      description
      Contacts {
        name
        type
        info
        title
      }
      locations {
        city
        state
        country
      }
      websiteUrl
      facebookUrl
      instagramUrl
      twitterUrl
      linkedinUrl
      otherUrl
      notifications {
        id
        published
        users_permissions_users {
          id
        }
      }
    }
    teams: vendors (publicationState: PREVIEW, where: {slug: $slug
    }) {
      id
      name
      slug
      image {
        url
        name
      }
      published_at
      approvalStatus
      updated_at
      updater {
        username
      }
      description
      Contacts {
        name
        type
        info
        title
      }
      websiteUrl
      facebookUrl
      instagramUrl
      twitterUrl
      locations{
        city
        state
        country
      }
      shortName
      youtubeUrl
      notifications {
        id
        published
        users_permissions_users {
          id
        }
      }
      original {
        id
        name
        slug
        products (sort: "name") {
          id
          name
          slug
          description
          elevatorPitch
          constraints
          status
          published_at
          approvalStatus
          original {
            id
            name
            slug
            published_at
          }
          notifications {
            id
            published
            users_permissions_users {
              id
            }
          }
          videoThumbnail {
            url
          }
          subcategories {
            id
            name
            category {
              id
              name
            }
          }
          videoUrl
          media {
            ext
            url
          }
          applications {
            id
            title
          }
            relatedFiles {
              id
              title
              description
              document {
                id
                url
              }
            }
            details
          gallery{
            id
            text
            image{
              id
              caption
              height
              width
              url
            }
          }
          comments{
            id
            text
            restricted
          }
          qa{
            id
            question
            answer
          }
          contacts{
            id
            name
            type
            info
            title
          }
        }
      }
    }
  }
`;

export const VENDOR_GET_PRODUCT = gql`
  query getProducts($slug: String!) {
    products (publicationState: PREVIEW, where: {slug: $slug
    }) {
      id
      name
      slug
      approvalStatus
      description
      constraints
      elevatorPitch
      status
      updated_at
      marvel
      pixar
      disney
      twentiethCentury
      searchlight
      lucasfilm
      disneyAnimation
      updater {
        username
      }
      vendor {
        name
        id
        published_at
        slug
        image{
          id
          url
        }
        locations {
          city
          state
          country
        }
        Contacts {
          name
          type
          info
          title
        }
      }
      thumbnail {
        url
        name
      }
      videoThumbnail {
        url
        name
      }
      categories {
        name
        id
        subcategories {
          id
          name
        }
      }
      original {
        id
        name
        slug
      }
      subcategories {
        id
        name
        category {
          id
          name
          subcategories {
            id
            name
          }
        }
      }
      videoUrl
      media {
        ext
        url
        name
      }
      applications {
        id
        title
      }
      relatedFiles {
        id
        title
        description
        document {
            id
            url
          }
      }
      details
      gallery{
        id
        text
        image{
          id
          caption
          height
          width
          url
        }
      }
      comments{
        id
        text
        restricted
      }
      qa{
        id
        question
        answer
      }
      contacts{
        id
        name
        type
        info
        title
      }
    }
  }
`;

export const GET_ALL_VENDORS = gql`
  query getAllVendors ($sort: String, $publicationState: PublicationState) {
    vendors (publicationState: $publicationState, sort: $sort) {
      id
      created_at
      slug
      published_at
      image {
        url
      }
      darkImage {
        url
      }
    }
  }
`;

export const UPDATE_VENDOR = gql`
  mutation updateVendor($id: ID!, $data: editVendorInput) {
    updateVendor(input: {
      where: {
        id: $id
      },
      data: $data
    }) {
      vendor {
        id
        name
        approvalStatus
        updated_at
        updater {
          username
        }
      }
    }
  }
`;

export const SUBMIT_VENDOR = gql`
  mutation submitForApproval($id: ID!) {
    updateVendor(input: {
      where: {
        id: $id
      },
      data: {
        approvalStatus: pendingApproval
      }
    }) {
      vendor {
        id
        approvalStatus
        updated_at
        updater {
          username
        }
      }
    }
  }
`;

export const MOVE_VENDOR_TO_DRAFT = gql`
  mutation moveToDraft($id: ID!) {
    updateVendor(input: {
      where: {
        id: $id
      },
      data: {
        approvalStatus: draft
      }
    }) {
      vendor {
        id
        approvalStatus
        updated_at
        updater {
          username
        }
      }
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($id: ID!, $data: editProductInput) {
    updateProduct(input: {
      where: {
        id: $id
      },
      data: $data
    }) {
      product {
        id
        name
        slug
        approvalStatus
        updated_at
        updater {
          username
        }
        videoThumbnail {
          url
        }
        applications {
          id
          title
        }
        relatedFiles {
          id
          title
          description
          document {
            id
            url
          }
        }
        description
        elevatorPitch
        details
        constraints
        gallery{
          id
          text
          image{
            id
            caption
            height
            width
            url
          }
        }
        comments{
          id
          text
          restricted
        }
        qa{
          id
          question
          answer
        }
        contacts{
          id
          name
          info
          title
          type
        }
      }
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: ID!) {
    deleteProduct (input: {
      where: {
        id: $id
      }
    }) {
      product {
        id
      }
    }
  }
`;

export const ADD_PRODUCT = gql`
  mutation addProduct($data: ProductInput) {
    createProduct (input: {
      data: $data
    }) {
      product {
        id
        slug
        original{
          id
          slug
        }
      }
    }

  }
`;

export const SUBMIT_FOR_APPROVAL = gql`
  mutation submitForApproval($id: ID!) {
    updateProduct(input: {
      where: {
        id: $id
      },
      data: {
        approvalStatus: pendingApproval
      }
    }) {
      product {
        id
        approvalStatus
        updated_at
        updater {
          username
        }
      }
    }
  }
`;

export const MOVE_TO_DRAFT = gql`
  mutation moveToDraft($id: ID!) {
    updateProduct(input: {
      where: {
        id: $id
      },
      data: {
        approvalStatus: draft
      }
    }) {
      product {
        id
        approvalStatus
        updated_at
        updater {
          username
        }
      }
    }
  }
`;

export const REQUEST_DELETE = gql`
  mutation requestDelete($id: ID!) {
    updateProduct(input: {
      where: {
        id: $id
      },
      data: {
        approvalStatus: deleteRequested
      }
    }) {
      product {
        id
        approvalStatus
        updated_at
        updater {
          username
        }
      }
    }
  }
`;

export const UPLOAD = gql`
  mutation upload($file: Upload!) {
    upload(file: $file) {
      id
    }
  }
`;

export const CLEAR_NOTIFICATIONS = gql`
  mutation clearNotifications($id: ID!, $notifications: [editComponentVendorsNotificationInput]) {
    updateVendor(input: {
      where: {
        id: $id
      },
        data: {
          notifications: $notifications
        }
      
    }) {
      vendor {
        id
        notifications {
          id
          published
          users_permissions_users {
            id
          }
        }

      }
      
    }
  }
`;

export const CLEAR_PRODUCT_NOTIFICATIONS = gql`
    mutation clearProductNotifications($id: ID!, $notifications: [editComponentVendorsNotificationInput]) {
    updateProduct(input: {
      where: {
        id: $id
      },
        data: {
          notifications: $notifications
        }
      
    }) {
      product {
        id
        notifications {
          id
          published
          users_permissions_users {
            id
          }
        }

      }
      
    }
  }
`;

export const GET_PRODUCT_PAGE = gql`
query productShowcasePage{
    productShowcasePage{
      header
      headerDescription
      banner{
        url
      }
  }
}
`;
