import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Snackbar from './Snackbar';

const RouterPrompt = (props) => {
    const { when, onOK, onCancel } = props;

    const history = useHistory();

    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState('');

    useEffect(() => {
        if (when) {
            history.block((prompt) => {
                setCurrentPath(prompt.pathname);
                setShowPrompt(true);
                return 'true';
            });
        } else {
            history.block(() => {});
        }

        return () => {
            history.block(() => {});
        };
    }, [history, when]);

    const handleOK = useCallback(async () => {
        if (onOK) {
            const canRoute = await Promise.resolve(onOK());
            if (canRoute) {
                history.block(() => {});
                history.push(currentPath);
            }
        }
    }, [currentPath, history, onOK]);

    const handleCancel = useCallback(async () => {
        if (onCancel) {
            const canRoute = await Promise.resolve(onCancel());
            if (canRoute) {
                history.block(() => {});
                history.push(currentPath);
            }
        }
        setShowPrompt(false);
    }, [currentPath, history, onCancel]);

    return showPrompt ? (
        <Snackbar
            open
            text='There are unsaved changes. Are you sure want to leave this page?'
            type='error'
            handleClose={handleCancel}
            handleButton
            closeButton
            handleClick={handleOK}
            onCancel={handleCancel}
            cancelText='Cancel'
            closable
            buttonText='Confirm'
        />

    ) : null;
};

export default RouterPrompt;

RouterPrompt.propTypes = {
    onCancel: PropTypes.function,
    onOK: PropTypes.function,
    when: PropTypes.bool
};
