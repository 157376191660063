import React from 'react';
import PropTypes from 'prop-types';
import FeaturedPosts from '../home/FeaturedPosts';
import { Row, Col } from '../modules/Grid';
import Paper from '../modules/Paper';

export const SpotlightContext = React.createContext();

const Posts = props => {
    const { posts, loading } = props;
    if (loading) {
        const cards = [];
        for (let i = 0; i < 16; i++) {
            cards.push(
                <Col sm={3} key={i} className='Spotlight__placeholder'>
                    <Paper className='Spotlight__placeholder__Card' />
                </Col>
            );
        }
        return (
            <Row>
                {cards}
            </Row>
        );
    }

    return (
        <FeaturedPosts posts={posts} />
    );
};

Posts.propTypes = {
    posts: PropTypes.array,
    loading: PropTypes.bool
};

export default Posts;
