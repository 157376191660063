import React, { useContext } from 'react';
import { ProductContext } from './ProductDetail';
import { Heading } from '../modules/Typography';
import Icon from '../modules/Icon';
import TextField from '../modules/TextField';
import { Container, Row, Col } from '../modules/Grid';
import Select from '../modules/Select';

const Contacts = props => {
    const {
        contacts,
        setContacts,
        error,
        errors
    } = useContext(ProductContext);

    const contactIcons = [
        { value: 'email', icon: 'email', tooltip: 'Email' },
        { value: 'phone', icon: 'desk-phone', tooltip: 'Phone' },
        { value: 'slack', icon: 'slack', tooltip: 'Slack' }
    ];

    const handleAddContact = () => {
        setContacts([...contacts, { name: '', type: 'email', info: '' }]);
    };

    const handleContact = (name, value, idx) => {
        const newContacts = [...contacts];
        newContacts[idx][name] = value;
        setContacts(newContacts);
    };

    const removeContact = (idx) => {
        setContacts(contacts.filter((contact, index) => (idx !== index)));
    };
    return (
        <div>
            <div className='VendorInformation__Section'>
                <Container>
                    <div className='flex'>
                        <Heading number={6}>Contacts</Heading>
                        <Icon className='pointer VendorInformation__icon' name='plus-circle' onClick={handleAddContact} />
                    </div>
                    {contacts.map((contact, idx) => (
                        <Row key={`contact-${idx}`} className='flex flex-start align-flex-end'>
                            <Col xs={4}>
                                <TextField
                                    className='VendorInformation__input'
                                    label='Name'
                                    value={contact.name}
                                    onChange={e => handleContact('name', e.target.value, idx)}
                                    error={error && errors.includes('contacts')}
                                    errorMessage='Please include a contact.'
                                />
                            </Col>
                            <Col xs={4}>
                                <TextField
                                    className='VendorInformation__input'
                                    label='Title'
                                    value={contact.title}
                                    onChange={e => handleContact('title', e.target.value, idx)}
                                    error={error && errors.includes('contacts')}
                                    errorMessage=''
                                />
                            </Col>
                            <Col xs={4}>
                                <Select
                                    className='VendorInformation__selectIcon'
                                    options={contactIcons.map(contact => ({ ...contact, text: '' }))}
                                    selectedValue={contact.type}
                                    icon={contact.type === 'phone' ? 'desk-phone' : contact.type}
                                    selectedTooltip={contactIcons.find(icon => icon.value === contact.type).tooltip}
                                    onChange={({ value }) => handleContact('type', value, idx)}
                                    tooltip
                                />
                                <div className='flex align-center'>
                                    <TextField
                                        className={`VendorInformation__input with-dropdown ${idx === 0 ? 'first-row' : 'not-first-row'}`}
                                        value={contact.info}
                                        onChange={e => handleContact('info', e.target.value, idx)}
                                    />
                                    {idx !== 0 && <Icon name='close' className='VendorInformation__icon close column-close' onClick={() => removeContact(idx)} />}
                                    {idx === 0 && <Icon className='VendorInformation__icon close' />}
                                </div>
                            </Col>
                        </Row>
                    ))}
                </Container>
            </div>
        </div>
    );
};

export default Contacts;
