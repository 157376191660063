import React, { useEffect, useRef, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useParams, useHistory, useLocation, Link } from 'react-router-dom';
import { Element, Link as ScrollLink } from 'react-scroll';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_PRODUCT_DETAIL, GET_CATEGORY_VENDOR_PRODUCTS } from '../../queries';
import Product from '../vendorDetails/Product';
import ProductCard from '../categories/ProductCard';
import { Heading, Overline, Subtitle, Body } from '../modules/Typography';
import { Container, Row, Col } from '../modules/Grid';
import Loading from '../modules/Loading';
import heroImage from '../../images/production-tech-portal_banner.jpg';
import Paper from '../modules/Paper';
import IconButton from '../modules/IconButton';
import Breadcrumbs from '../modules/Breadcrumbs';
import parseMarkdown from '../../utils/markdownParser';
import { UserContext } from '../../contexts/UserContextProvider';
import Icon from '../modules/Icon';
import Button from '../modules/Button';

export const VendorDetailContext = React.createContext();

const VendorProduct = props => {
    const { slug, productSlug } = useParams();
    const { pathname } = useLocation();
    const isPreview = pathname.includes('product-preview');
    const history = useHistory();
    const [copyMessage, setCopyMessage] = useState(false);
    const { publicationState, user } = useContext(UserContext);
    const imageRef = useRef(null);
    const { data } = useQuery(GET_PRODUCT_DETAIL, { variables: { publicationState: isPreview ? 'PREVIEW' : publicationState, slug: productSlug } });
    const product = data?.products[0];
    const [getRelated, { data: relatedData }] = useLazyQuery(GET_CATEGORY_VENDOR_PRODUCTS);
    const relatedProducts = relatedData?.category.products.filter(prod => prod.id !== product.id);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (data && (!data.products || !data.products.length)) {
            history.push('/');
        } else if (data) {
            const product = data.products[0];
            getRelated({ variables: { publicationState, id: product.categories[0]?.id } });
        }
    }, [data]);

    const copyToClipboard = (id) => {
        setCopyMessage(id);
        setTimeout(() => {
            setCopyMessage(false);
        }, 2000);
    };

    const handleImage = ({ target: img }) => {
        if (img.offsetHeight / img.offsetWidth >= 0.75) {
            imageRef.current.className = 'Vendor__logo tall';
        }
    };

    if (!data) return <Loading />;

    const { vendor } = product;

    const {
        image,
        darkImage,
        name,
        Contacts,
        locations,
        users = []
    } = vendor;

    const isUnpublished = !vendor.published_at;
    const productName = product.name;
    const contactsList = product.contacts.length ? product.contacts : Contacts;
    const pitch = parseMarkdown(product.elevatorPitch);
    const isOwner = users.find(owner => owner.id === user.id);
    return (
        <VendorDetailContext.Provider value={{
        }}
        >
            <>
                <div className='Categories flex column flex-start'>
                    <Helmet><title>{`PTP | ${name}`}</title></Helmet>
                    <div className='Categories__banner' style={{ backgroundImage: `url(${heroImage})` }}>
                        <Container className='Vendor__header'>
                            <div className='Vendor__logo__container flex column align-flex-start'>
                                <Breadcrumbs
                                    className='vendor-view' breadcrumbs={[
                                        { text: 'home', link: '/' },
                                        { text: 'Products', link: '/categories/products' },
                                        { text: ` ${name}`, link: `/vendors/${slug}` },
                                        { text: ` ${productName}` }
                                    ]}
                                />
                                <div>
                                    <Heading className={`Vendor__name__title ${product.elevatorPitch?.length > 2 ? 'right' : ''}`} number={3}>{productName?.replace('_PREVIEW', '')}</Heading>
                                    <Heading className='Vendor__name__title left' number={6}>{pitch || ''}
                                    </Heading>
                                </div><br />

                            </div>
                            {isOwner && !isPreview && <Button className='Vendor__EditButton' type='primary' link={`/${slug}/products/${productSlug}`}>Edit This Product</Button>}
                        </Container>

                    </div>
                    <Container className='Vendor__container flex column align-flex-start'>
                        <div className='Vendor__Products'>
                            <Element name={product.slug} key={product.slug} className='Vendor__Product__container'>
                                <Product {...product} isOwner={isOwner} isUnpublished={isUnpublished} vendorName={name} vendorLogo={image} vendorLogoDark={darkImage} />
                            </Element>
                        </div>
                    </Container>

                </div>
                {!isPreview && relatedProducts?.length && (
                    <div className='Vendor__RelatedProducts__Container'>
                        <div className='container'>
                            <Overline className='Vendor__RelatedProducts'>Related Products</Overline>
                            <div className='flex flex-start'>
                                {relatedProducts
                                    .slice(0, 4).map(product => (
                                        <>
                                            <Col className='Home__Industry__cards' xs={6} md={3} key={product.id}>
                                                <Link to={`/vendors/${product.vendor.slug}/product/${product.slug}`}>
                                                    <ProductCard product={product} />
                                                </Link>
                                            </Col>
                                        </>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
                <div id='footer-contact' className='Vendor__contact__footer'>
                    <div className='Vendor__contact__location'>
                        <div className='Vendor__logo__footer' style={{ backgroundImage: `url("${darkImage?.url || image?.url || '#'}")` }} />
                        <div className='Vendor__contactInfo__column'>
                            <Overline className='Vendor__contactInfo__header'>Locations</Overline>
                            {locations.map(location => (
                                <div key={location.city} className='Vendor__location'>
                                    <Body className='Vendor__contactInfo__city' number={1} fadedTwoThirds>{location.city}</Body>
                                    <Body className='Vendor__contactInfo__state' number={1} fadedTwoThirds>{location.state}</Body>
                                    <Body className='Vendor__contactInfo__header' number={1} fadedTwoThirds>{location.country}</Body>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='Vendor__contact__half'>
                        <Overline className='Vendor__contactInfo__header'>Contacts</Overline>
                        <div className='column wrap block'>
                            {contactsList.map(contact => (
                                <div className='Vendor__Product__info__contact' key={contact.info}>
                                    <Subtitle block number={1} fadedTwoThirds>{contact.name}</Subtitle>
                                    <div className='flex flex-start'>
                                        <Subtitle onClick={() => navigator.clipboard.writeText(contact.info)} block number={1} color='primary-light'>{contact.info}
                                            {copyMessage === contact.info ? <span className='Vendor__contactInfo__copied'>Copied! <Icon name='check' /></span> : <Icon className='pointer' onClick={() => copyToClipboard(contact.info)} name='copy' />}
                                        </Subtitle>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>

        </VendorDetailContext.Provider>
    );
};
export default VendorProduct;

VendorProduct.propTypes = {
    product: PropTypes.object,
    vendor: PropTypes.object,
    isPreview: PropTypes.bool
};
