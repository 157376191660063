import React, { useContext, useState, useEffect } from 'react';
import { Link, Prompt, useHistory } from 'react-router-dom';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { UPDATE_VENDOR, UPLOAD, SUBMIT_VENDOR, MOVE_VENDOR_TO_DRAFT } from '../../queries';
import { VendorContext } from './VendorLayout';
import Icon from '../modules/Icon';
import Button from '../modules/Button';
import TextField from '../modules/TextField';
import TextArea from '../modules/TextArea';
import Select from '../modules/Select';
import Dropzone from '../modules/Dropzone';
import { Subtitle, Heading, Caption, Overline, Body } from '../modules/Typography';
import { Container, Row, Col } from '../modules/Grid';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';

const VendorInformation = props => {
    const history = useHistory();
    const { vendor, vendorName, vendorSlug, setBreadcrumbs, setActiveTab } = useContext(VendorContext);
    const { isMobile } = useContext(WidthContext);
    const { successSnackbar, errorSnackbar } = useContext(SnackbarContext);
    const [updateVendor] = useMutation(UPDATE_VENDOR);
    const [submitForApproval] = useMutation(SUBMIT_VENDOR);
    const [moveToDraft] = useMutation(MOVE_VENDOR_TO_DRAFT);
    const [upload] = useMutation(UPLOAD);
    const [websiteUrl, setWebsite] = useState('');
    const [description, setDescription] = useState('');
    const [logo, setLogo] = useState(null);
    const [darkLogo, setDarkLogo] = useState(null);
    const [filename, setFilename] = useState('');
    const [darkFilename, setDarkFilename] = useState('');
    const [thumbnailSrc, setThumbnailSrc] = useState('');
    const [darkThumbnailSrc, setDarkThumbnailSrc] = useState('');
    const [locations, setLocations] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [socialAccounts, setSocialAccounts] = useState([]);
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState([]);
    const [approvalStatus, setApprovalStatus] = useState('draft');
    const [error, setError] = useState(false);
    const [isChanges, setIsChanges] = useState(false);
    const [updater, setUpdater] = useState('');
    const [updatedAt, setUpdatedAt] = useState(null);

    useEffect(() => {
        if (!isChanges) {
            setIsChanges(true);
        }
    }, [websiteUrl, description, logo, darkLogo, darkFilename, filename, thumbnailSrc, darkThumbnailSrc, locations, contacts, socialAccounts]);

    useEffect(() => {
        setWebsite(vendor.websiteUrl);
        setBreadcrumbs([{ text: 'Vendor Information' }]);
        setActiveTab('info');
        setDescription(vendor.description || '');
        if (vendor.image) {
            setFilename(vendor.image.name);
            setThumbnailSrc(vendor.image.url);
        }
        if (vendor.darkImage) {
            setDarkFilename(vendor.darkImage.name);
            setDarkThumbnailSrc(vendor.darkImage.url);
        }
        setLocations(vendor.locations.length ? vendor.locations : [{ city: '', state: '', country: '' }]);
        setContacts(vendor.Contacts.length ? vendor.Contacts : [{ name: '', type: 'email', info: '' }]);
        setApprovalStatus(vendor.approvalStatus || 'draft');
        if (vendor.updater) {
            setUpdater(vendor.updater.username);
        }
        setUpdatedAt(vendor.updated_at);
        const socials = [];
        if (vendor.facebookUrl) {
            socials.push({ type: 'facebook', value: vendor.facebookUrl });
        }
        if (vendor.twitterUrl) {
            socials.push({ type: 'twitter', value: vendor.twitterUrl });
        }
        if (vendor.instagramUrl) {
            socials.push({ type: 'instagram', value: vendor.instagramUrl });
        }
        if (vendor.linkedinUrl) {
            socials.push({ type: 'linkedin', value: vendor.linkedUrl });
        }
        if (vendor.otherUrl) {
            socials.push({ type: 'text-link', value: vendor.otherUrl });
        }
        if (!socials.length) {
            socials.push({ type: 'facebook', value: '' });
        }
        setSocialAccounts(socials);
        setTimeout(() => {
            setIsChanges(false);
        }, 1000);
    }, [vendor, vendorName]);

    useEffect(() => {
        const errs = [];
        if (!description) {
            errs.push('description');
        }
        if (!thumbnailSrc) {
            errs.push('light-thumbnail');
        }
        if (!darkThumbnailSrc) {
            errs.push('dark-thumbnail');
        }
        if (!contacts.length) {
            errs.push('contacts');
        }
        setErrors(errs);
    }, [description, thumbnailSrc, darkThumbnailSrc, contacts]);

    const approvals = {
        draft: { text: 'Draft', icon: 'note', color: 'secondary-dark' },
        pendingApproval: { text: 'Pending Approval', icon: 'info', color: 'info' },
        published: { text: 'Published', icon: 'check-circle', color: 'success' }
    };
    const approval = approvals[approvalStatus];

    const contactIcons = [
        { value: 'email', icon: 'email', tooltip: 'Email' },
        { value: 'phone', icon: 'desk-phone', tooltip: 'Phone' },
        { value: 'slack', icon: 'slack', tooltip: 'Slack' }
    ];

    const socialIcons = [
        { value: 'facebook', icon: 'facebook', tooltip: 'Facebook' },
        { value: 'instagram', icon: 'instagram', tooltip: 'Instagram' },
        { value: 'twitter', icon: 'twitter', tooltip: 'Twitter' },
        { value: 'linkedin', icon: 'linkedin', tooltip: 'LinkedIn' },
        { value: 'text-link', icon: 'text-link', tooltip: 'Other' }
    ];

    const handleSocial = (key, value, idx) => {
        const socials = [...socialAccounts];
        socials[idx][key] = value;
        setSocialAccounts(socials);
    };

    const handleLocation = (key, value, idx) => {
        const locs = [...locations];
        locs[idx][key] = value;
        setLocations(locs);
    };

    const handleContact = (key, value, idx) => {
        const newContacts = [...contacts];
        newContacts[idx][key] = value;
        setContacts(newContacts);
    };

    const handleFile = (file, type) => {
        if (type === 'light') {
            setLogo(file);
            const reader = new FileReader();
            reader.onload = (e) => setThumbnailSrc(e.target.result);
            reader.readAsDataURL(file);
            setFilename(file.name);
        } else if (type === 'dark') {
            setDarkLogo(file);
            const reader = new FileReader();
            reader.onload = (e) => setDarkThumbnailSrc(e.target.result);
            reader.readAsDataURL(file);
            setDarkFilename(file.name);
        }
    };

    const handleAddLocation = () => {
        setLocations([...locations, { city: '', state: '', country: '' }]);
    };

    const handleAddContact = () => {
        setContacts([...contacts, { name: '', type: 'email', info: '' }]);
    };
    const handleAddSocial = () => {
        setSocialAccounts([...socialAccounts, { type: 'facebook', value: '' }]);
    };

    const removeLocation = (idx) => {
        setLocations(locations.filter((location, index) => (idx !== index)));
    };

    const removeContact = (idx) => {
        setContacts(contacts.filter((contact, index) => (idx !== index)));
    };

    const removeSocial = (idx) => {
        setSocialAccounts(socialAccounts.filter((social, index) => (idx !== index)));
    };

    const handleDeletePhoto = (type) => {
        if (type === 'dark') {
            setDarkThumbnailSrc('');
            setDarkLogo(null);
            setDarkFilename('');
        } else if (type === 'light') {
            setThumbnailSrc('');
            setLogo(null);
            setFilename('');
        }
    };

    const handleSave = async (submit) => {
        if (description.length > 1000) {
            return;
        }
        setSaving(true);

        let imageId;
        let darkImageId;
        if (logo) {
            const response = await upload({ variables: { file: logo } });
            imageId = response.data.upload.id;
        }
        if (darkLogo) {
            const response = await upload({ variables: { file: darkLogo } });
            darkImageId = response.data.upload.id;
        }

        const data = {
            name: vendor.name,
            description,
            websiteUrl
        };

        if (imageId) {
            data.image = imageId;
        }
        if (darkImageId) {
            data.darkImage = darkImageId;
        }

        const facebook = socialAccounts.find(account => (account.type === 'facebook'));
        const twitter = socialAccounts.find(account => (account.type === 'twitter'));
        const instagram = socialAccounts.find(account => (account.type === 'instagram'));
        const linkedin = socialAccounts.find(account => (account.type === 'linkedin'));
        const other = socialAccounts.find(account => (account.type === 'text-link'));

        if (facebook) {
            data.facebookUrl = facebook.value;
        }
        if (twitter) {
            data.twitterUrl = twitter.value;
        }
        if (instagram) {
            data.instagramUrl = instagram.value;
        }
        if (linkedin) {
            data.linkedinUrl = linkedin.value;
        }
        if (other) {
            data.otherUrl = other.value;
        }

        const dataContacts = contacts.filter(contact => (contact.name && contact.info));
        const dataLocations = locations.filter(location => (location.city || location.country || location.state));

        if (dataContacts.length) {
            data.Contacts = dataContacts;
        }

        if (dataLocations.length) {
            data.locations = dataLocations;
        }

        const response = await updateVendor({
            variables: {
                id: vendor.id,
                data
            }
        });
        const { updater, updated_at: updatedAt } = response.data.updateVendor.vendor;
        if (updater) {
            setUpdater(updater.username);
        }
        setUpdatedAt(updatedAt);
        setSaving(false);
        setIsChanges(false);
        if (submit) {
            const response = await submitForApproval({ variables: { id: vendor.id } });
            const { updater, updated_at: updatedAt } = response.data.updateVendor.vendor;
            setApprovalStatus('pendingApproval');
            if (updater) {
                setUpdater(updater.username);
            }
            setUpdatedAt(updatedAt);
            successSnackbar({ text: 'You have successfully submitted and will hear from us soon' });
            setIsChanges(false);
            history.push(`/${vendor.original.slug}/products`);
        } else if (response.approvalStatus !== 'draft') {
            const response = await moveToDraft({ variables: { id: vendor.id } });
            const { updater, updated_at: updatedAt } = response.data.updateVendor.vendor;
            setApprovalStatus('draft');
            if (updater) {
                setUpdater(updater.username);
            }
            setUpdatedAt(updatedAt);
        }
    };

    const handleSubmit = async () => {
        if (errors.length) {
            setError(true);
        } else {
            setError(false);
            await handleSave(true);
        }
    };

    const handlePreviewOpen = () => {
        isChanges ? errorSnackbar({ text: 'You have unsaved changes. Please save before continuing.' }) : window.open(`/vendor-preview/${vendor.slug}`);
    };

    return (
        <div className='VendorInformation'>
            <Container className='VendorInformation__container'>
                <div className='flex'>
                    <div>
                        <Heading className='VendorInformation__title' block number={isMobile ? 5 : 3}>Vendor Information</Heading>
                        <Subtitle className='VendorInformation__subtitle' block number={2}>The information below will display on your vendor page.</Subtitle>
                    </div>
                    <div className='flex'>
                        <Button
                            type='tertiary'
                            className='VendorInformation__button'
                            size={isMobile ? 'small' : 'regular'}
                        >
                            <Link to={`/${vendorSlug}/vendor-home`}>
                                Cancel
                            </Link>
                        </Button>
                        <div className='VendorInformation__button VendorInformation__button--save'>
                            <Button
                                type='secondary'
                                size={isMobile ? 'small' : 'regular'}
                                className='VendorInformation__button'
                                onClick={() => handleSave(false)}
                                loading={saving}
                                color={description.length > 1000 ? 'error' : 'default'}
                            >Save Draft
                            </Button>
                            {description.length > 1000 && <Caption error className='VendorInformation__button--error'>Description must be less than 1000 characters.</Caption>}
                        </div>
                        <Button
                            loading={saving}
                            type='primary'
                            size={isMobile ? 'small' : 'regular'}
                            className='VendorInformation__button'
                            onClick={handleSubmit}
                        >Submit for Approval
                        </Button>
                    </div>
                </div>
                <Row>
                    <Col sm={10}>
                        <div>
                            <div className='VendorInformation__Section'>
                                <Container>
                                    <Heading number={6}>Basic Information</Heading>
                                    <Row className='spacing-md'>
                                        <Col md={7}>
                                            <TextField
                                                className='VendorInformation__input'
                                                value={vendorName}
                                                disabled
                                                label='Vendor Name'
                                                onChange={() => {}}
                                                rightLabel='Required'
                                            />
                                        </Col>
                                        <Col md={5}>
                                            <TextField
                                                className='VendorInformation__input'
                                                label='Website URL'
                                                placeholder='Website URL'
                                                value={websiteUrl}
                                                onChange={e => setWebsite(e.target.value)}
                                                error={error && errors.includes('websiteUrl')}
                                                errorMessage='Please include a website URL.'
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <div className='flex'>
                                                <Caption block className='spacing-md'>Description</Caption>
                                                <Caption block className='spacing-md TextField__rightLabel'>Required</Caption>
                                            </div>
                                            <TextArea
                                                className='VendorInformation__description'
                                                value={description}
                                                characterLimit={1000}
                                                onChange={e => setDescription(e.target.value)}
                                                error={error && errors.includes('description')}
                                                errorMessage='Please include a description.'
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <div className='flex'>
                                                <Caption block className='spacing-md'>Light Logo</Caption>
                                                <Caption block className='spacing-md TextField__rightLabel'>Dark Theme Compatible</Caption>
                                            </div>
                                            <Dropzone
                                                className='VendorInformation__logo__dropzone'
                                                handleFile={(file) => handleFile(file, 'dark')}
                                                handleDelete={() => handleDeletePhoto('dark')}
                                                filename={darkFilename}
                                                thumbnailSrc={darkThumbnailSrc}
                                                fileTypes={['.png', '.svg']}
                                                finePrint='Must be compatible with dark theme'
                                                error={error && errors.includes('dark-thumbnail')}
                                                errorMessage='Please include a dark theme compatible logo thumbnail.'
                                            />

                                        </Col>
                                        <Col md={6}>
                                            <div className='flex'>
                                                <Caption block className='spacing-md'>Dark Logo</Caption>
                                                <Caption block className='spacing-md TextField__rightLabel'>Light Theme Compatible</Caption>
                                            </div>
                                            <Dropzone
                                                className='VendorInformation__logo__dropzone'
                                                handleFile={(file) => handleFile(file, 'light')}
                                                handleDelete={() => handleDeletePhoto('light')}
                                                filename={filename}
                                                thumbnailSrc={thumbnailSrc}
                                                fileTypes={['.png', '.svg']}
                                                finePrint='Must be compatible with light theme'
                                                error={error && errors.includes('light-thumbnail')}
                                                errorMessage='Please include a light theme compatible logo thumbnail.'
                                            />

                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className='VendorInformation__Section'>
                                <Container>
                                    <div className='flex'>
                                        <Heading number={6}>Location</Heading>
                                        <div className='flex pointer' onClick={handleAddLocation}>
                                            <Icon className='VendorInformation__icon' name='plus-circle' />
                                            <Body className='VendorInformation__icon__description'>Add Location</Body>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col xs={12}>
                                            {locations.map((location, idx) => (
                                                <div key={`location-${idx}`} className={`VendorInformation__column flex flex-start ${idx === 0 ? 'column-first-row' : ''}`}>

                                                    <TextField
                                                        className='VendorInformation__input one-third'
                                                        label='Country'
                                                        onChange={e => handleLocation('country', e.target.value, idx)}
                                                        value={location.country}
                                                    />
                                                    <TextField
                                                        className='VendorInformation__input one-third'
                                                        label='State'
                                                        onChange={e => handleLocation('state', e.target.value, idx)}
                                                        value={location.state}
                                                    />
                                                    <TextField
                                                        className='VendorInformation__input one-third'
                                                        label='City'
                                                        onChange={e => handleLocation('city', e.target.value, idx)}
                                                        value={location.city}
                                                    />
                                                    {idx !== 0 && <Icon name='close' className='VendorInformation__icon close' onClick={() => removeLocation(idx)} />}
                                                    {idx === 0 && <Icon className='VendorInformation__icon close location-close' />}
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className='VendorInformation__Section'>
                                <Container>
                                    <div className='flex'>
                                        <Heading number={6}>Contact</Heading>
                                        <div className='flex pointer' onClick={handleAddContact}>
                                            <Icon className='VendorInformation__icon' name='plus-circle' />
                                            <Body className='VendorInformation__icon__description'>Add Contact</Body>
                                        </div>
                                    </div>
                                    {contacts.map((contact, idx) => (
                                        <Row key={`contact-${idx}`} className='flex flex-start align-flex-end'>
                                            <Col xs={4} className='VendorInformation__contact'>
                                                <TextField
                                                    className='VendorInformation__input'
                                                    label='Name'
                                                    value={contact.name}
                                                    onChange={e => handleContact('name', e.target.value, idx)}
                                                    error={error && errors.includes('contacts')}
                                                    errorMessage='Please include a contact.'
                                                />
                                            </Col>
                                            <Col xs={4} className='VendorInformation__contact'>
                                                <TextField
                                                    className='VendorInformation__input'
                                                    label='Title'
                                                    value={contact.title}
                                                    onChange={e => handleContact('title', e.target.value, idx)}
                                                    error={error && errors.includes('contacts')}
                                                    errorMessage=''
                                                />
                                            </Col>
                                            <Col xs={4}>
                                                <Select
                                                    className='VendorInformation__selectIcon'
                                                    options={contactIcons.map(contact => ({ ...contact, text: '' }))}
                                                    selectedTooltip={contactIcons.find(icon => icon.value === contact.type).tooltip}
                                                    selectedValue={contact.type}
                                                    icon={contact.type === 'phone' ? 'desk-phone' : contact.type}
                                                    onChange={({ value }) => handleContact('type', value, idx)}
                                                    tooltip
                                                />
                                                <div className='flex align-center VendorInformation__contactType'>
                                                    <TextField
                                                        className={`VendorInformation__input with-dropdown ${idx === 0 ? 'first-row' : 'not-first-row'}`}
                                                        value={contact.info}
                                                        onChange={e => handleContact('info', e.target.value, idx)}
                                                    />
                                                    {idx !== 0 && <Icon name='close' className='VendorInformation__icon close contact-close' onClick={() => removeContact(idx)} />}
                                                    {idx === 0 && <Icon className='VendorInformation__icon close' />}
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}
                                </Container>
                            </div>
                            <div className='VendorInformation__Section'>
                                <Container>
                                    <div className='flex'>
                                        <Heading number={6}>Social Accounts</Heading>
                                        <div className='flex pointer' onClick={handleAddSocial}>
                                            <Icon className='VendorInformation__icon' name='plus-circle' />
                                            <Body className='VendorInformation__icon__description'>Add Account</Body>
                                        </div>
                                    </div>
                                    {socialAccounts.map((social, idx) => (
                                        <Row key={`social-${idx}`}>
                                            <Col xs={6}>
                                                <Select
                                                    className='VendorInformation__selectIcon'
                                                    options={socialIcons.map(social => ({ icon: social.icon, value: social.value, text: '', tooltip: social.tooltip }))}
                                                    selectedTooltip={socialIcons.find(icon => icon.value === social.type).tooltip}
                                                    selectedValue={social.type}
                                                    icon={social.type}
                                                    onChange={({ value }) => handleSocial('type', value, idx)}
                                                    tooltip
                                                />
                                                <div className='flex align-center'>
                                                    <TextField
                                                        className={`VendorInformation__input with-dropdown ${idx === 0 ? 'first-row' : 'not-first-row'}`}
                                                        value={social.value}
                                                        onChange={e => handleSocial('value', e.target.value, idx)}
                                                        placeholder={`www.${social.type}.com/example`}
                                                    />
                                                    {idx !== 0 && <Icon name='close' className='VendorInformation__icon close column-close' onClick={() => removeSocial(idx)} />}
                                                    {idx === 0 && <Icon className='VendorInformation__icon close' />}
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}
                                </Container>
                            </div>
                        </div>
                    </Col>
                    <Col sm={2} className='VendorInformation__right'>
                        <div className='flex column align-flex-start'>
                            <div>
                                <Icon name={approval.icon} className={`${approval.color}-text VendorInformation__sideIcon`} />
                                <Subtitle number={2} color={approval.color}>{approval.text}</Subtitle>
                            </div>
                            <Button
                                block
                                loading={saving}
                                className='spacing-md VendorInformation__preview flex center'
                                type='secondary'
                                size={isMobile ? 'small' : 'regular'}
                                icon='visibility-on'
                                onClick={handlePreviewOpen}
                                color={error ? 'error' : 'default'}
                            >Preview
                            </Button>
                            <Overline className='spacing-md'>Last Updated</Overline>
                            <div className='spacing-xs'>
                                <Icon className='VendorInformation__sideIcon gray-text' name='calendar' />
                                <Body color='gray' number={2}>{updatedAt ? moment(updatedAt).format('LL') : ''}</Body>
                            </div>
                            <div className='spacing-xs'>
                                <Icon className='VendorInformation__sideIcon gray-text' name='clock' />
                                <Body color='gray' number={2}>{updatedAt ? moment(updatedAt).format('LT') : ''}</Body>
                            </div>
                            <div className='spacing-xs'>
                                <Icon className='VendorInformation__sideIcon gray-text' name='profile' />
                                <Body color='gray' number={2}>{updater}</Body>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Prompt
                when={isChanges}
                message={() => 'Are you sure you want to leave this page? You have unsaved changes that will be lost.'}
            />
        </div>

    );
};
export default VendorInformation;
