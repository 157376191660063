import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { CLEAR_NOTIFICATIONS, CLEAR_PRODUCT_NOTIFICATIONS } from '../../queries';
import { Subtitle, Overline, Caption } from '../modules/Typography';
import Icon from '../modules/Icon';
import IconButton from '../modules/IconButton';
import { VendorContext } from './VendorLayout';
import { UserContext } from '../../contexts/UserContextProvider';

const Notifications = props => {
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const { vendor } = useContext(VendorContext);
    const { user } = useContext(UserContext);
    const [clearNotifications] = useMutation(CLEAR_NOTIFICATIONS);
    const [clearProductNotification] = useMutation(CLEAR_PRODUCT_NOTIFICATIONS);
    const notifications = vendor.notifications.filter(note => !note.users_permissions_users.find(upUser => upUser.id === user.id));
    const productNotifications = vendor.original.products.reduce((prev, curr) => {
        return [...prev, ...curr.notifications.map(note => ({ ...note, productName: curr.name, productId: curr.id }))];
    }, [])
        .filter(note => !note.users_permissions_users.find(upUser => upUser.id === user.id));

    const hasNotifications = !!(notifications.length || productNotifications.length);

    const handleNotifications = async () => {
        if (hasNotifications) {
            setNotificationsOpen(!notificationsOpen);
            if (notifications.length) {
                const viewedNotifications = notifications.map(note => ({
                    ...note,
                    users_permissions_users: [...note.users_permissions_users.map(usr => usr.id), user.id]
                }));

                await clearNotifications({
                    variables: {
                        id: vendor.id,
                        notifications: viewedNotifications
                    }
                });
            }

            if (productNotifications.length) {
                for (let i = 0; i < productNotifications.length; i++) {
                    const notification = productNotifications[i];
                    const viewedNotification = {
                        id: notification.id,
                        users_permissions_users: [...notification.users_permissions_users.map(user => user.id), user.id]
                    };
                    const product = vendor.original.products.find(prod => prod.id === notification.productId);
                    const viewedNotifications = [
                        ...product.notifications
                            .filter(note => note.id !== viewedNotification.id)
                            .map(note => ({
                                ...note,
                                users_permissions_users: note.users_permissions_users.map(usr => usr.id)
                            })),
                        viewedNotification
                    ];
                    await clearProductNotification({
                        variables: {
                            id: notification.productId,
                            notifications: viewedNotifications
                        }
                    });
                }
            }
        }
    };

    return (
        <div className='relative'>
            <IconButton size='large' className='VendorTopNav__icon alert-mobile' name='alert' onClick={handleNotifications} />
            {hasNotifications && <div className='VendorTopNav__badge' />}
            {notificationsOpen && (
                <div className='VendorTopNav__Notifications__Container'>
                    <div className='VendorTopNav__Notifications'>
                        <div className='flex'>
                            <Overline>Notifications</Overline>
                            <Icon name='close' className='pointer' onClick={() => setNotificationsOpen(false)} />
                        </div>
                        <div className='spacing-sm'>
                            {notifications.map(notification => (
                                <div key={`notification-${notification.id}`} className='VendorTopNav__Notification'>
                                    <div className='flex align-flex-start flex-start'>
                                        <Icon className='success-text VendorTopNav__Notification__icon' name='check-circle' />
                                        <div>
                                            <Subtitle faded number={2} className='VendorTopNav__Notification__text'>Vendor</Subtitle>
                                            <Subtitle number={2} className='VendorTopNav__Notification__bold'>{vendor.name.replace('_PREVIEW', '')}</Subtitle>
                                            <Subtitle number={2} faded className='VendorTopNav__Notification__text'>has been approved and published</Subtitle>
                                            <Caption block number={2} faded className='spacing-sm'>{moment(notification.published).fromNow()}</Caption>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {productNotifications.map(notification => (
                                <div key={`notification-${notification.id}`} className='VendorTopNav__Notification'>
                                    <div className='flex align-flex-start flex-start'>
                                        <Icon className='success-text VendorTopNav__Notification__icon' name='check-circle' />
                                        <div>
                                            <Subtitle faded number={2} className='VendorTopNav__Notification__text'>Product</Subtitle>
                                            <Subtitle number={2} className='VendorTopNav__Notification__bold'>{notification.productName.replace('_PREVIEW', '')}</Subtitle>
                                            <Subtitle number={2} faded className='VendorTopNav__Notification__text'>has been approved and published</Subtitle>
                                            <Caption block number={2} faded className='spacing-sm'>{moment(notification.published).fromNow()}</Caption>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Notifications;
