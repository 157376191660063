import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Heading, Overline, Body } from '../modules/Typography';
import parseMarkdown from '../../utils/markdownParser';
import Icon from '../modules/Icon';
import { SearchContext } from '../pages/Search';
import { Link } from 'react-router-dom';

const SearchResults = props => {
    const { query } = useContext(SearchContext);
    const parseCategories = (categories, subcategories) => {
        return _.uniq([
            ...categories.map(category => category.name),
            ...subcategories.map(subcategory => subcategory.category?.name)
        ])
            .join(', ');
    };
    const parseQuery = (text, isDescription) => {
        if (!text) return '';
        const re = new RegExp(query, 'ig');
        const span = `<span class='Search__Result__SearchTerm'>${query}</span>`;
        let newText = text.replace(re, span);

        const idx = newText.indexOf(span);
        if (isDescription && idx > 22) {
            newText = '...' + newText.slice(idx - 22);
        }
        return newText;
    };
    return (
        <div className='Search__Results'>
            <Heading className='Search__Results__Title' number={5}>{props.title}</Heading>
            {props.results.map(result => (
                <Link to={result.link} key={result.id}>
                    <div className='Search__Result flex flex-start align-flex-start'>
                        <div style={{ backgroundImage: `url("${result.thumbnail}")` }} className='Search__Result__image' />
                        <div className='flex column align-flex-start'>
                            <div className='flex flex-start align-baseline'>
                                <Overline className={`Search__Result__type ${props.color}`}>{result.type}</Overline>
                                <Heading className='Search__Result__Title' number={6} dangerouslySetInnerHTML={{ __html: parseQuery(result.name) }} />
                            </div>
                            {(!!result.categories.length || !!result.subcategories.length) && (
                                <div className='Search__Result__Categories flex flex-start align-flex-end'>
                                    <Icon name='tag' className='Search__Result__Categories__Tag' />
                                    <Body number={2}>{parseCategories(result.categories, result.subcategories)}</Body>
                                </div>
                            )}
                            {/* //check if we need overview or description here */}
                            <Body className='Search__Result__Description'>{parseMarkdown(parseQuery(result.description, true))}</Body>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default SearchResults;

SearchResults.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    results: PropTypes.array
};
