import React, { useContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { GET_ALL_PRODUCTS } from '../../queries';
import { Row, Col } from '../modules/Grid';
import { Caption, Subtitle } from '../modules/Typography';
import ProductCard from './ProductCard';
import Loading from '../modules/Loading';
import IconButton from '../modules/IconButton';
import Select from '../modules/Select';
import { CategoryContext } from '../pages/Categories';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { DataContext } from '../../contexts/DataContextProvider';
import { UserContext } from '../../contexts/UserContextProvider';

const AllCategories = props => {
    const slug = useParams();
    const history = useHistory();
    const { pathname } = useLocation();
    const { listOpen, setOpen, isVendor } = useContext(CategoryContext);
    const { subcategory } = useContext(DataContext);
    const [selectedVendor, setVendor] = useState('all');
    const [selectedBanner, setBanner] = useState('all');
    const { isMobile } = useContext(WidthContext);
    const { publicationState } = useContext(UserContext);
    const { data, loading } = useQuery(GET_ALL_PRODUCTS, { variables: { publicationState } });
    useEffect(() => {
        if (pathname.includes('banner')) {
            setBanner(slug.banner);
        }
    }, []);

    if (loading) return <Loading />;

    const handleBannerChange = (item) => {
        setBanner(item.value);
        if (selectedBanner !== item.value) {
            history.push(`/categories/products/banner/${item.value}`);
            if (item.value === 'all') {
                history.push('/categories/products/');
            }
        }
    };

    const sortByName = (a, b) => {
        const productA = a.name;
        const productB = b.name;
        if (productA > productB) {
            return 1;
        }
        return -1;
    };
    const sortByText = (a, b) => {
        const productA = a.text;
        const productB = b.text;
        if (productA > productB) {
            return 1;
        }
        return -1;
    };

    let products = data.products.filter(product => ((product.vendor || product.team) && !product.original)).sort(sortByName);
    const subcategoryName = 'All';
    const categoryName = '';

    let owners = [];
    const banners = [
        { text: 'Marvel', value: 'marvel' },
        { text: 'Pixar', value: 'pixar' },
        { text: 'Searchlight', value: 'searchlight' },
        { text: 'Lucasfilm', value: 'lucasfilm' },
        { text: 'Walt Disney Animation', value: 'wdas' },
        { text: '20th Century', value: 'twentieth' },
        { text: 'Disney', value: 'disney' }
    ];

    products = products.filter(product => product.vendor);
    owners = _.uniqBy(products.map(prod => ({ text: prod.vendor.name, value: prod.vendor.id })), 'value');
    if (selectedVendor !== 'all') {
        products = products.filter(product => product.vendor.id === selectedVendor);
    }
    if (selectedBanner === 'marvel') {
        products = products.filter(product => product.marvel === true);
    }
    if (selectedBanner === 'pixar') {
        products = products.filter(product => product.pixar === true);
    }
    if (selectedBanner === 'searchlight') {
        products = products.filter(product => product.searchlight === true);
    }
    if (selectedBanner === 'lucasfilm') {
        products = products.filter(product => product.lucasfilm === true);
    }
    if (selectedBanner === 'disney') {
        products = products.filter(product => product.disney === true);
    }
    if (selectedBanner === 'twentieth') {
        products = products.filter(product => product.twentiethCentury === true);
    }
    if (selectedBanner === 'wdas') {
        products = products.filter(product => product.disneyAnimation === true);
    }

    return (
        <div className='relative'>
            {isMobile ? '' : (
                <IconButton
                    name={listOpen ? 'caret-left' : 'caret-right'}
                    size='small'
                    className='Categories__List__icon'
                    onClick={() => setOpen(!listOpen)}
                />
            )}
            <div className='Categories__Product__Grid'>
                <div className='flex align-center'>
                    {!isMobile ? '' : (
                        <IconButton
                            name='gallery-view'
                            className='Categories__button'
                            onClick={() => setOpen(!listOpen)}
                        />
                    )}
                    <div>
                        <Subtitle number={3} heavy>{subcategoryName}</Subtitle>
                    </div>
                </div>
                <div className='Categories__Product__content flex'>
                    <div className='flex'>
                        <Select
                            options={[{ text: 'All Vendors', value: 'all' }, ...owners.sort(sortByText)]}
                            selectedValue={selectedVendor}
                            onChange={({ value }) => setVendor(value)}
                            className='Categories__Product__select'
                        />
                    </div>
                    <div className='Categories__Product__content__right flex'>
                        <Select
                            options={[{ text: 'All Banners', value: 'all' }, ...banners.sort(sortByText)]}
                            selectedValue={selectedBanner}
                            onChange={(value) => handleBannerChange(value)}
                            className='Categories__Product__select'
                        />
                        <Caption className='gray-text' number={1}>{`${products && products.length} result${products && products.length === 1 ? '' : 's'}`}</Caption>

                    </div>
                </div>
                <Row>
                    {products.map(product => (
                        <Col xs={6} sm={listOpen ? 6 : 4} lg={listOpen ? 4 : 3} key={product.id}>
                            <Link to={`/vendors/${product.vendor.slug}/product/${product.slug}`}>
                                <ProductCard product={product} subcategoryId={subcategory} categoryName={categoryName} thumbnail={product.thumbnail} />
                            </Link>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
};

export default AllCategories;
