import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ProductContext } from './ProductDetail';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import moment from 'moment';
import Button from '../modules/Button';
import { Col } from '../modules/Grid';
import Icon from '../modules/Icon';
import { Overline, Body } from '../modules/Typography';
const VendorMenuRight = props => {
    const { isMobile } = useContext(WidthContext);
    const { errorSnackbar } = useContext(SnackbarContext);
    const [error, setError] = useState(false);

    const {
        approval,
        name,
        updatedAt,
        updater,
        isDirty
    } = useContext(ProductContext);

    useEffect(() => {
        if (!isDirty) {
            setError(false);
        }
    }, [isDirty]);

    const handlePreview = () => {
        if (isDirty) {
            errorSnackbar({ text: 'Please save your product before previewing.' });
            setError(true);
        } else {
            setError(false);
            window.open(`/vendors/${props.vendorSlug}/product-preview/${props.productSlug}`);
        }
    };

    return (
        <Col className='VendorInformation__right' sm={2}>
            <div className='flex column align-flex-start'>
                <div>
                    <Icon name={approval.icon} className={`${approval.color}-text VendorInformation__sideIcon`} />
                    <Body number={2} color={approval.color}>{approval.text}</Body>
                </div>
                <Button
                    block
                    className='spacing-md VendorInformation__preview flex center'
                    type='secondary'
                    size={isMobile ? 'small' : 'regular'}
                    icon='visibility-on'
                    onClick={handlePreview}
                    disabled={!name}
                    color={error ? 'error' : 'default'}
                    loading={props.loading}
                >Preview
                </Button>
                <Overline className='spacing-md VendorInformation__right__title'>Last Updated</Overline>
                <div className='spacing-xs'>
                    <Icon className='VendorInformation__sideIcon VendorInformation__right__text' name='calendar' />
                    <Body className='VendorInformation__right__text' number={2}>{updatedAt ? moment(updatedAt).format('LL') : ''}</Body>
                </div>
                <div className='spacing-xs'>
                    <Icon className='VendorInformation__sideIcon VendorInformation__right__text' name='clock' />
                    <Body className='VendorInformation__right__text' number={2}>{updatedAt ? moment(updatedAt).format('LT') : ''}</Body>
                </div>
                <div className='spacing-xs'>
                    <Icon className='VendorInformation__sideIcon VendorInformation__right__text' name='profile' />
                    <Body className='VendorInformation__right__text' number={2}>{updater}</Body>
                </div>
            </div>
        </Col>
    );
};

export default VendorMenuRight;

VendorMenuRight.propTypes = {
    vendorSlug: PropTypes.string,
    productSlug: PropTypes.string,
    loading: PropTypes.bool
};
