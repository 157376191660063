import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import '../styles/main.scss';

import WidthContextProvider from '../contexts/WidthContextProvider';
import UserContextProvider from '../contexts/UserContextProvider';
import DataContextProvider from '../contexts/DataContextProvider';
import SnackbarContextProvider from '../contexts/SnackbarContextProvider';

import GraphiqlEditor from './GraphiqlEditor';
import Home from './pages/Home';
import Categories from './pages/Categories';
import Spotlight from './pages/Spotlight';
import Search from './pages/Search';
import PostDetail from './pages/PostDetail';
import VendorDetail from './pages/VendorDetail';
import VendorProduct from './pages/VendorProduct';
import Vendors from './pages/Vendors';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';
import Layout from './layout/Layout';
import VendorLayout from './vendorPortal/VendorLayout';
import VendorInformation from './vendorPortal/VendorInformation';
import VendorProductDetail from './vendorPortal/ProductDetail';
import VendorProducts from './vendorPortal/Products';
import VendorHome from './vendorPortal/VendorHome';

const routes = [
    { path: '/', component: Home },
    { path: '/categories', component: Categories },
    { path: '/categories/products', component: Categories },
    { path: '/categories/:slug', component: Categories },
    { path: '/vendors/:slug/product/:productSlug', component: VendorProduct },
    { path: '/vendors/:slug/product-preview/:productSlug', component: VendorProduct },
    { path: '/vendors/:slug', component: VendorDetail },
    { path: '/vendor-preview/:slug', component: VendorDetail },
    { path: '/vendors', component: Vendors },
    { path: '/industry-spotlight', component: Spotlight },
    { path: '/industry-spotlight/:slug', component: PostDetail },
    { path: '/search', component: Search },
    { path: '/categories/products/banner/:banner', component: Categories }
];

const vendorRoutes = [
    { path: '/:vendorSlug/vendor-information', component: VendorInformation },
    { path: '/:vendorSlug/products/:productSlug', component: VendorProductDetail },
    { path: '/:vendorSlug/products', component: VendorProducts },
    { path: '/:vendorSlug/vendor-home', component: VendorHome }
];

function App () {
    return (
        <Router getUserConfirmation={() => { /* Empty callback to block the default browser prompt */ }}>
            <UserContextProvider>
                <WidthContextProvider>
                    <DataContextProvider>
                        <SnackbarContextProvider>
                            <Switch>
                                <Route path='/graphiql' component={GraphiqlEditor} />
                                <Route path='/forgot-password' component={ForgotPassword} />
                                <Route path='/reset-password' component={ResetPassword} />
                                {routes.map((route, key) => {
                                    const { path, component, ...props } = route;
                                    return (
                                        <Layout key={key} path={path} {...props} exact>
                                            <Route path={path} component={component} exact />
                                        </Layout>
                                    );
                                })}
                                {vendorRoutes.map((route, key) => {
                                    const { path, component, ...props } = route;

                                    return (
                                        <VendorLayout key={key} path={path} {...props} exact>
                                            <Route path={path} component={component} exact />
                                        </VendorLayout>
                                    );
                                })}
                                <Redirect to='/' />
                            </Switch>
                        </SnackbarContextProvider>
                    </DataContextProvider>
                </WidthContextProvider>
            </UserContextProvider>
        </Router>
    );
}

export default App;
