import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Subtitle } from '../modules/Typography';
import IconButton from '../modules/IconButton';
import SubcategoryList from './SubcategoryList';
import { DataContext } from '../../contexts/DataContextProvider';
import { UserContext } from '../../contexts/UserContextProvider';
import { WidthContext } from '../../contexts/WidthContextProvider';

const Category = props => {
    const { category } = props;
    const { selectedCategory, slug, setSubcategory, setCategory, isVendor } = useContext(DataContext);
    const { isMobile } = useContext(WidthContext);
    const { setUserTeamCategory, setUserTeamSubcategory, setUserSubcategory, setUserCategory } = useContext(UserContext);
    const [isOpen, setOpen] = useState(false);
    const isActive = category.id === selectedCategory;

    useEffect(() => {
        if (slug === category.slug) {
            setOpen(true);
        }
    }, [slug]);

    useEffect(() => {
        if (isActive) {
            setOpen(true);
        }
    }, []);

    const subcategories = category.subcategories.filter(subcat => subcat.products.length);
    const hasSubs = !!subcategories.length;
    const handleClick = () => {
        setSubcategory(`${category.id}-all`);
        setCategory(category.id);
        if (isVendor) {
            setUserSubcategory(`${category.id}-all`);
            setUserCategory(category.id);
        } else {
            setUserTeamSubcategory(`${category.id}-all`);
            setUserTeamCategory(category.id);
        }
        if (isMobile) {
            setOpen(false);
        }
    };

    return (
        <div key={category.id} className={`Categories__category ${hasSubs ? '' : 'no-subs'}`}>
            <div className='Categories__category__header flex align-baseline'>
                <Subtitle
                    className={`Categories__category__name ${isActive ? 'active' : ''} ${hasSubs ? '' : 'button'}`}
                    number={1}
                    onClick={hasSubs ? null : handleClick}
                >{category.name}
                </Subtitle>
                {hasSubs ? <IconButton name={isOpen ? 'caret-up' : 'caret-down'} className='Categories__category__caret' size='small' onClick={() => setOpen(!isOpen)} /> : ''}
            </div>
            <SubcategoryList subcategories={subcategories} open={isOpen} categoryId={category.id} />
        </div>
    );
};

Category.propTypes = {
    category: PropTypes.object
};

export default Category;
