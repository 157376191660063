import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GET_ALL_PRODUCTS, GET_ALL_CATEGORIES } from '../queries';
import { useQuery } from '@apollo/client';

export const DataContext = React.createContext();

const DataComponent = ({ children }) => {
    const { data: productData } = useQuery(GET_ALL_PRODUCTS);
    const { data: categoryData } = useQuery(GET_ALL_CATEGORIES);
    const [subcategory, setSubcategory] = useState(null);
    const [selectedCategory, setCategory] = useState(null);

    const allProducts = productData?.products || [];
    const allCategories = categoryData?.categories || [];

    return (
        <DataContext.Provider value={{
            allProducts,
            allCategories,
            subcategory,
            setSubcategory,
            selectedCategory,
            setCategory
        }}
        >
            {children}
        </DataContext.Provider>
    );
};

DataComponent.propTypes = {
    children: PropTypes.object
};

export default DataComponent;
