import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useMutation } from '@apollo/client';
import { Row, Col } from '../modules/Grid';
import logoImage from '../../images/studioTechLogin.png';
import { Heading, Caption } from '../modules/Typography';
import Button from '../modules/Button';
import TextField from '../modules/TextField';
import LoginMessageBody from '../login/LoginMessageBody';
import { RESET_PASSWORD } from '../../queries';
import { UserContext } from '../../contexts/UserContextProvider';

export const LoginContext = React.createContext();

const ResetPassword = () => {
    const cookies = new Cookies();
    const location = useLocation();
    const history = useHistory();
    const code = location.search.split('=')[1];
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [resetPassword] = useMutation(RESET_PASSWORD);
    const { setUser, user } = useContext(UserContext);

    useEffect(() => {
        if (user && user.id) {
            history.push('/');
        }
    }, [user]);

    const handleCreate = async (e) => {
        if (!error) {
            try {
                e.preventDefault();
                const { data } = await resetPassword({
                    variables: {
                        code,
                        password
                    }
                });
                const { user, jwt } = data.resetPassword;
                cookies.set('access_token', jwt);
                setUser(user);
            } catch (err) {
                console.log('err', err);
                setError('There was an error creating your password.');
            }
        }
    };

    const handleKeyPress = (e) => {
        if (e.charCode === 13) {
            handleCreate(e);
        }
    };

    const verifyPassword = () => {
        const pwParams = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        !password.match(pwParams) ? setError('Password does not meet the requirements.') : setError(null);
    };

    return (
        <div className='Login'>
            <Helmet><title>Welcome to Production Technology Portal</title></Helmet>
            <Row className='Login__Row no-gutters'>
                <Col sm={12} lg={4} className='Login__sidebar__container flex column'>
                    <div className='flex column'>
                        <img className='Login__logo' src={logoImage} alt='logo' />
                        <div className='Login__sidebar'>
                            <Heading number={5} block className='Login__title Login__title--Create'>Create a Password</Heading>
                            <TextField
                                icon='' // TO DO: can't find eye-icon, need to be added
                                type='password'
                                value={password}
                                placeholder='Enter Password'
                                onChange={e => setPassword(e.target.value)}
                                autofocus
                                onKeyPress={handleKeyPress}
                                onBlur={verifyPassword}
                                error={error}
                            />
                            {error && <Caption number={3} className='Login__error-message' block error topPadded>{error}</Caption>}
                            <Button
                                className='Login__Button' type='primary' onClick={handleCreate}
                            >Create Password
                            </Button>
                            <div className='Login__pw-details'>
                                <Caption className='Login__pw-text' number={1} block topPadded>Your password must contain:</Caption>
                                <Caption className='Login__pw-text' number={1} block>8 or more characters</Caption>
                                <Caption className='Login__pw-text' number={1} block>A mixture of letters and numbers</Caption>
                                <Caption className='Login__pw-text' number={1} block>A mixture of upper and lowercase letters</Caption>
                                <Caption className='Login__pw-text' number={1} block>Special characters: !@#$%^&*()</Caption>
                            </div>
                        </div>
                    </div>
                    <div className='Login__footer'>
                        <Caption className='Login__copyright flex center' number={2}>© Disney 2020. All Rights Reserved.</Caption>
                    </div>
                </Col>
                <Col sm={12} lg={8} className='Login__message-body'>
                    <LoginMessageBody authorizedUser />
                </Col>
            </Row>
        </div>
    );
};

export default ResetPassword;
