import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from '../modules/Grid';
import { Heading, Caption } from '../modules/Typography';
import Dropzone from '../modules/Dropzone';
import Icon from '../modules/Icon';
import TextArea from '../modules/TextArea';
import { ProductContext } from './ProductDetail';
import defaultVideoImage from '../../images/defaultVideoImage.png';

const Gallery = props => {
    const {
        gallery,
        handleFile,
        handleDelete,
        removeFile,
        handleGalleryDescription,
        errors,
        error
    } = useContext(ProductContext);

    const [descriptionList, setDescriptionList] = useState([]);

    useEffect(() => {
        const galleryTextMap = gallery.map((item) => item.text);
        setDescriptionList(galleryTextMap);
    }, [gallery]);

    const handleDescription = (text, idx) => {
        const newTextList = [...descriptionList];
        newTextList[idx] = text;
        setDescriptionList(newTextList);
        handleGalleryDescription(text, idx);
    };

    return (
        <div className='VendorInformation__Section VendorInformation__Gallery'>
            {errors.length && error ? (
                <div className='VendorInformation__ErrorIndicator flex flex-start'>
                    <Icon name='warning' className='VendorInformation__ErrorIndicator__Icon  error-text' />
                    <Caption error>Errors on page</Caption>
                </div>
            ) : ''}
            <Container>
                <Heading number={6}>Gallery</Heading>
                <Row className='flex align-flex-start'>
                    <Col md={4}>
                        <Caption block className='spacing-md'>Gallery</Caption>
                        <Dropzone
                            maxFiles={10}
                            className='VendorInformation__thumbnail__dropzone'
                            handleFile={(file) => handleFile(file, 'gallery')}
                            handleDelete={() => handleDelete('gallery')}
                            fileTypes={['.jpg', '.png', '.mp4', '.mov']}
                            finePrint='High Resolution Image, 16:9'
                            multiple
                            isGallery
                        />
                    </Col>
                    <Col md={8} className='VendorInformation__Gallery__Images flex flex-start wrap align-flex-start'>
                        {gallery.map((img, idx) => {
                            const isVideo = img?.file?.type.indexOf('video') > -1 || img?.file?.type.indexOf('mp4') > -1;
                            const image = img.image || img;
                            const backgroundImage = isVideo ? defaultVideoImage : (image.url || image.src);
                            return (
                                <div className='flex align-flex-start full-width' key={`${img.id || img.title}-${idx}`}>
                                    <div className='VendorInformation__Gallery__Image' style={{ backgroundImage: `url("${backgroundImage}")` }} alt={image.id}>
                                        <div className='VendorInformation__Gallery__Image__Overlay' onClick={() => removeFile(idx, 'gallery')}>
                                            <Icon name='trash-can' className='VendorInformation__Gallery__Image__Icon' />
                                        </div>
                                    </div>
                                    <TextArea
                                        className='gallery'
                                        styleType='gallery'
                                        value={descriptionList[idx]}
                                        label='Image Text'
                                        onChange={e => handleDescription(e.target.value, idx)}
                                    />
                                </div>
                            );
                        })}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

Gallery.propTypes = {

};

export default Gallery;
