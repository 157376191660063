import React, { useEffect, useRef, useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useHistory, useLocation, Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { GET_VENDOR_DETAIL } from '../../queries';
import { Heading, Overline, Subtitle, Body } from '../modules/Typography';
import { Container, Row, Col } from '../modules/Grid';
import Loading from '../modules/Loading';
import heroImage from '../../images/production-tech-portal_banner.jpg';
import Paper from '../modules/Paper';
import IconButton from '../modules/IconButton';
import Breadcrumbs from '../modules/Breadcrumbs';
import parseMarkdown from '../../utils/markdownParser';
import { UserContext } from '../../contexts/UserContextProvider';
import ProductCard from '../categories/ProductCard';
import Icon from '../modules/Icon';

export const VendorDetailContext = React.createContext();

const VendorDetail = props => {
    const { slug } = useParams();
    const history = useHistory();
    const [copyMessage, setCopyMessage] = useState(false);
    const { pathname } = useLocation();
    const isPreview = pathname.includes('vendor-preview');
    const { publicationState } = useContext(UserContext);
    const imageRef = useRef(null);
    const { data, loading } = useQuery(GET_VENDOR_DETAIL, { variables: { publicationState: isPreview ? 'PREVIEW' : publicationState, slug } });
    const [collection, setCollection] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (data && (!data.vendors || !data.vendors.length)) {
            history.push('/');
        }
        const vendor = data?.vendors?.[0];
        setCollection(vendor?.products || []);
    }, [data]);

    const copyToClipboard = (id) => {
        setCopyMessage(id);
        setTimeout(() => {
            setCopyMessage(false);
        }, 2000);
    };

    const handleImage = ({ target: img }) => {
        if (img.offsetHeight / img.offsetWidth >= 0.75) {
            imageRef.current.className = 'Vendor__logo tall';
        }
    };

    if (loading) return <Loading />;
    const vendorData = data.vendors[0];
    const {
        image,
        darkImage,
        name,
        description,
        products,
        Contacts,
        locations,
        websiteUrl,
        facebookUrl,
        instagramUrl,
        twitterUrl,
        linkedinUrl,
        published_at
    } = vendorData;
    const isUnpublished = !data.vendors[0].published_at;

    const filteredCollection = isPreview ? collection.filter(item => item.published_at) : collection;

    return (
        <VendorDetailContext.Provider value={{
        }}
        >
            <>
                <div className='Categories flex column flex-start'>
                    <Helmet><title>{`PTP | ${name}`}</title></Helmet>
                    <div className={`Categories__banner vendor ${isPreview ? 'preview' : ''}`} style={{ backgroundImage: `url(${heroImage})` }}>
                        <Container className='Vendor__header'>
                            <div className='Vendor__logo__container'>
                                <div>
                                    <Breadcrumbs
                                        className='vendor-view' breadcrumbs={[
                                            { text: 'home', link: '/' },
                                            { text: 'all vendors', link: '/vendors' }
                                        ]}
                                    />
                                    <Heading className='Vendor__name__title' number={3}>{name?.replace('_PREVIEW', '')}</Heading>
                                </div>
                                <div>
                                    <Body className='Vendor__header__medium vendorDetails'>Last Published:</Body>
                                    <Body>{moment(published_at).format('LL')}</Body>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <Container className='Vendor__container flex column align-flex-start'>
                        <div className='Vendor__contactInfo__row'>
                            <div className='flex align-flex-start'>
                                {(websiteUrl || facebookUrl || twitterUrl || instagramUrl) &&
                                <div className='Vendor__contactInfo__right details flex align-flex-start column'>
                                    <Subtitle number={2} className='Vendor__contactInfo__header'>LINKS</Subtitle>
                                    {websiteUrl &&
                                    <div className='Vendor__contactInfo__website flex align-baseline'>
                                        <Subtitle number={1} color='primary-light'>Visit Vendor Website</Subtitle>
                                        <a href={websiteUrl && websiteUrl.includes('http') ? websiteUrl : `http://${websiteUrl}`} rel='noreferrer' target='_blank'>
                                            <IconButton name='outward-link' className='Vendor__contactInfo__icon primary-light-text' />
                                        </a>
                                    </div>}
                                    <div className='Vendor__contactInfo__social flex'>
                                        {facebookUrl ? (
                                            <a href={facebookUrl} rel='noreferrer' target='_blank'>
                                                <IconButton name='facebook' className='primary-light-text' />
                                            </a>) : ''}
                                        {instagramUrl ? (
                                            <a href={instagramUrl} rel='noreferrer' target='_blank'>
                                                <IconButton name='instagram' className='primary-light-text' />
                                            </a>) : ''}
                                        {twitterUrl ? (
                                            <a href={twitterUrl} rel='noreferrer' target='_blank'>
                                                <IconButton name='twitter' className='primary-light-text' />
                                            </a>) : ''}
                                        {linkedinUrl ? (
                                            <a href={linkedinUrl} rel='noreferrer' target='_blank'>
                                                <IconButton name='linkedin' className='primary-light-text' />
                                            </a>) : ''}
                                    </div>
                                </div>}
                                {Contacts[0] &&
                                <div className='Vendor__contactInfo__left details flex column align-flex-start'>
                                    <Subtitle number={2} className='Vendor__contactInfo__header'>CONTACTS</Subtitle>
                                    {Contacts[0] &&
                                    <>
                                        <div className='Vendor__Product__info__contact' key={Contacts[0].info}>
                                            <Subtitle block number={1} fadedTwoThirds>{Contacts[0].name}</Subtitle>
                                            <div className='flex flex-start'>
                                                <Subtitle number={1} onClick={() => navigator.clipboard.writeText(Contacts[0].info)} block color='primary-light'>{Contacts[0].info}
                                                    {copyMessage === Contacts[0].info ? <span className='Vendor__contactInfo__copied'>Copied! <Icon name='check' /></span> : <Icon className='pointer' onClick={() => copyToClipboard(Contacts[0].info)} name='copy' />}
                                                </Subtitle>
                                            </div>
                                        </div>
                                        {/* <Body className='pointer' color='primary-light'><ScrollLink smooth duration={500} to='footer-contact'>See full contact list<Icon name='chevrons-down' /></ScrollLink></Body> */}
                                    </>}
                                </div>}
                            </div>
                            <img className='Vendor__logo details' src={darkImage?.url || image?.url || '#'} onLoad={handleImage} ref={imageRef} />

                        </div>

                        <Row className='Vendor__contactInfo__row'>
                            <Col xs={12} lg={12}>
                                {description &&
                                <Paper className='Vendor__detailCard flex column align-flex-start'>
                                    {isUnpublished && <Overline className='Team__detailCard__unpublished error-text'>Unpublished</Overline>}
                                    <Body className='Vendor__detailCard__description' number={5}>{parseMarkdown(description)}</Body>
                                </Paper>}
                            </Col>
                        </Row>
                        <div>
                            {products?.length ? <Heading className='Vendor__Products__tab' number={5}>Products</Heading> : ''}
                        </div>
                        <div className='Home__Industry__container'>
                            {filteredCollection.map(product => {
                                return (
                                    <Col className='Home__Industry__cards' xs={6} md={3} key={product.id}>
                                        <Link to={`/vendors/${slug}/product/${product.slug}`}>
                                            <ProductCard product={product} thumbnail={product.thumbnail} />
                                        </Link>
                                    </Col>
                                );
                            })}
                        </div>
                    </Container>
                </div>
                <div id='footer-contact' className='Vendor__contact__footer'>
                    <div className='Vendor__contact__location'>
                        <div className='Vendor__logo__footer' style={{ backgroundImage: `url("${darkImage?.url || image?.url || '#'}")` }} />
                        <div className='Vendor__contactInfo__column'>
                            <Overline className='Vendor__contactInfo__header'>Locations</Overline>
                            {locations.map(location => (
                                <div key={location.city}>
                                    <Body className='Vendor__contactInfo__city' number={1} fadedTwoThirds>{location.city}</Body>
                                    <Body className='Vendor__contactInfo__state' number={1} fadedTwoThirds>{location.state}</Body>
                                    <Body className='Vendor__contactInfo__header' number={1} fadedTwoThirds>{location.country}</Body>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='Vendor__contact__half'>
                        <Overline className='Vendor__contactInfo__header'>Contacts</Overline>
                        <div className='column wrap block'>
                            {Contacts.map((contact) => (
                                <div className='Vendor__Product__info__contact' key={contact.info}>
                                    <Subtitle block number={1} fadedTwoThirds>{contact.name}</Subtitle>
                                    <div className='flex flex-start'>
                                        <Subtitle onClick={() => navigator.clipboard.writeText(contact.info)} block number={1} color='primary-light'>{contact.info}
                                            {copyMessage === contact.info ? <span className='Vendor__contactInfo__copied'>Copied! <Icon name='check' /></span> : <Icon className='pointer' onClick={() => copyToClipboard(contact.info)} name='copy' />}
                                        </Subtitle>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </>
        </VendorDetailContext.Provider>
    );
};

export default VendorDetail;
