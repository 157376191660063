import React from 'react';
import PropTypes from 'prop-types';

const Paper = props => {
    return (
        <div className='Paper__bg'>
            <div className={`Paper ${props.className || ''} elevation-${props.elevation}`}>
                {props.children}
            </div>
        </div>
    );
};

Paper.propTypes = {
    className: PropTypes.string,
    elevation: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

Paper.defaultProps = {
    elevation: 1
};

export default Paper;
