import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Overline } from './Typography';
import Select from './Select';
import { VendorContext } from '../vendorPortal/VendorLayout';

const VendorBreadcrumbs = props => {
    const history = useHistory();
    const { vendors, teams, vendorSlug } = useContext(VendorContext);
    const handleVendor = ({ value }) => {
        history.push(`/${value}/vendor-home`);
    };

    return (
        <div className={`Breadcrumbs VendorBreadcrumbs ${props.className || ''}`}>
            <div className='flex flex-start align-baseline'>
                <Select
                    className='VendorTopNav__breadcrumbs__select'
                    options={[
                        ...vendors.map(vendor => ({ text: vendor.name, value: vendor.slug })),
                        ...teams.map(team => ({ text: team.name, value: team.slug }))
                    ]}
                    onChange={handleVendor}
                    selectedValue={vendorSlug}

                />
                {props.breadcrumbs.map((breadcrumb, idx) => {
                    if (breadcrumb.text.length) {
                        return (
                            <div key={breadcrumb.text} className='flex flex-start'>
                                <Overline number={2} className='Breadcrumbs__text gray-text'>/</Overline>
                                {breadcrumb.link ? (
                                    <Link to={breadcrumb.link}>
                                        <Overline number={2} className='Breadcrumbs__text gray-text'>{breadcrumb.text}</Overline>
                                    </Link>
                                ) : (
                                    <Overline number={2} className='Breadcrumbs__text gray-text'>{breadcrumb.text}</Overline>
                                )}
                            </div>
                        );
                    };
                })}
            </div>
        </div>
    );
};

VendorBreadcrumbs.propTypes = {
    breadcrumbs: PropTypes.array,
    className: PropTypes.string
};

VendorBreadcrumbs.defaultProps = {
    breadcrumbs: []
};

export default VendorBreadcrumbs;
