import { gql } from '@apollo/client';

export const GET_SPOTLIGHT_PAGE = gql`
  query getSpotlight($limit: Int) {
      spotlightPage {
        header
        headerDescription
        banner{
          url
        }
        featuredPost {
          id
          title
          content {
            ...on ComponentPostsPdf {
              file {
                url
              }
            }
            ...on ComponentPostsTextBox {
              body
            }
          }
          date
          slug
          categories {
            id
            name
          }
          subcategories {
            id
            name
            category {
              id
              name
            }
          }
          media {
            url
            mime
          }
        }
      }
      posts (sort: "date:desc", limit: $limit){
        id
        title
        date
        slug
        categories {
          id
          name
        }
        subcategories {
          id
          name
          category {
            id
            name
          }
        }
        media {
          url
          mime
        }
      }
  }
`;

export const GET_POST_DETAIL = gql`
  query getPosts($slug: String!) {
    posts (where: {slug: $slug
    }) {
      id
      title
      slug
      media {
        url
        mime
      }
      date
      content {
        ...on ComponentPostsPdf {
          file {
            url
          }
        }
        ...on ComponentPostsTextBox {
          body
        }
      }
      categories {
        id
        name
      }
      subcategories {
        id
        name
        category {
          id
          name
        }
      }
    }
  }
`;

export const GET_RELATED_NEWS = gql`
  query getRelatedNews {
    posts (limit: 4 sort: "date") {
      id
      title
      slug
      date
      media {
        url
        mime
      }
      categories {
        id
        name
      }
      subcategories {
        id
        name
        category {
          id
          name
        }
      }
    }
  }
`;
