import React, { useContext, useState } from 'react';
import Dropzone from '../modules/Dropzone';
import { Heading, Caption } from '../modules/Typography';
import { Container, Row, Col } from '../modules/Grid';
import RelatedFile from './RelatedFile';
import { ProductContext } from './ProductDetail';

const RelatedFiles = props => {
    const {
        handleDelete,
        removeFile,
        attachments,
        setAttachments
    } = useContext(ProductContext);

    const [activeIdx, setActiveIdx] = useState(null);
    const [fileLoading, setFileLoading] = useState('');

    const handleEdit = (idx) => {
        setActiveIdx(idx);
        setFileLoading('');
    };

    const handleValue = (e, idx, field) => {
        const newAttachments = [...attachments];
        var attachment = field === 'restricted' ? { ...newAttachments[idx], [field]: e.target.checked } : { ...newAttachments[idx], [field]: e.target.value };
        newAttachments[idx] = attachment;
        setAttachments(newAttachments);
    };

    const handleFile = (file, idx) => {
        const reader = new FileReader();
        setFileLoading(file.name);
        reader.onload = (e) => {
            const src = e.target.result;
            const name = file.name;
            const document = { src, file, title: name, description: '' };
            setAttachments(attachments => [...attachments, document]);
            setTimeout(() => setFileLoading(''), 3000);
        };
        reader.readAsDataURL(file);
    };

    return (
        <Row>
            <Col md={12}>
                <div className='VendorInformation__Section'>
                    <Container>
                        <Row>
                            <Col md={5}>
                                <Heading number={6}>Related Files ({attachments.length}) </Heading>
                                <Caption block className='spacing-md'>Additional Attachments</Caption>
                                <Dropzone
                                    className='VendorInformation__file__dropzone'
                                    handleFile={handleFile}
                                    removeFile={removeFile}
                                    handleEdit={handleEdit}
                                    isDocument
                                    multiple
                                    handleDelete={() => handleDelete('attachments')}
                                    fileTypes={['.pdf', '.doc', '.key', '.ppt', '.jpg', '.xlsx']}
                                    attached={attachments}
                                    finePrint='Please upload one of the accepted file types.'
                                />
                            </Col>
                            <Col md={7}>
                                {attachments &&
                                    attachments.map((item, idx) => (
                                        <RelatedFile
                                            key={`document-${idx}`}
                                            item={item}
                                            isActive={idx === activeIdx}
                                            idx={idx}
                                            handleValue={handleValue}
                                            handleEdit={handleEdit}
                                            setActiveIdx={setActiveIdx}
                                            fileLoading={item.file ? fileLoading === item.file.name : false}
                                        />
                                    ))}
                            </Col>

                        </Row>
                    </Container>
                </div>
            </Col>
        </Row>
    );
};

export default RelatedFiles;
