import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Icon from './Icon';
import PropTypes from 'prop-types';
import { Caption } from './Typography';

const FormWrapper = ({ onSubmit, children }) => onSubmit ? (
    <form
        className='TextArea__form'
        onSubmit={(e) => {
            e.preventDefault();
            onSubmit(e.target.querySelector('input').value);
        }}
        children={children}
    />
) : (
    children
);

const TextArea = props => {
    const charCount = props.characterLimit - (props.value ? props.value.length : 0);
    const charError = charCount < 0;

    return (
        <div className={`TextArea ${props.className} ${props.padded ? 'TextArea--padded' : ''} ${charError ? 'TextArea--error' : ''}`}>
            <div className='flex space-between full-width'>
                {props.label && (
                    <label
                        className={`TextArea__label ${props.className ? `${props.className}__label` : ''}`}
                        htmlFor={props.id || props.label}
                    >
                        <Caption className='TextArea__label__text' faded={props.disabled}>{props.label}</Caption>
                    </label>
                )}

                {props.rightLabel && (
                    <label
                        className={`TextArea__label TextField__rightLabel ${props.className ? `${props.className}__label` : ''}`}
                        htmlFor={props.id || props.rightLabel}
                    >
                        <Caption className='TextArea__label__text' faded={props.disabled}>{props.rightLabel}</Caption>
                    </label>
                )}
            </div>
            <div className={[
                'TextArea__style-wrapper',
                `TextArea__style-wrapper--${props.styleType}`,
                props.disabled ? 'TextArea__style-wrapper--disabled' : null,
                props.error || charError ? 'TextArea__style-wrapper--error' : null
            ].filter(i => i).join(' ')}
            >
                {props.icon && <Icon className='TextArea__icon' name={props.icon} />}

                <FormWrapper onSubmit={props.onSubmit}>
                    {props.wysiwyg ? (
                        <ReactQuill
                            className='QuillEditor__input'
                            theme='snow'
                            value={props.value}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                        />
                    ) : (
                        <textarea
                            type={props.type}
                            id={props.id}
                            defaultValue={props.defaultValue}
                            className={`TextArea__input ${props.size}`}
                            placeholder={props.placeholder}
                            onChange={props.onChange}
                            onKeyPress={props.onKeyPress}
                            value={props.value}
                            disabled={props.disabled}
                            autoFocus={props.autofocus}
                            onClick={props.onClick}
                            onBlur={props.onBlur}
                        />
                    )}
                    {props.characterLimit && <Caption className={`TextArea__wordCount ${charError ? 'error-text' : ''}`}>{`${charCount}`}</Caption>}
                </FormWrapper>

            </div>
            {props.error && props.errorMessage && <Caption number={2} error className='TextArea__error'>{props.errorMessage}</Caption>}
        </div>
    );
};

export default TextArea;

TextArea.propTypes = {
    // tag id to attach to the form field
    id: PropTypes.string,

    // icon name to display inside the form field
    icon: PropTypes.string,

    // className to pass to the tags
    className: PropTypes.string,

    // placeholder text to place in the form
    placeholder: PropTypes.string,

    // whether or not the field is invalid
    error: PropTypes.bool,

    // error message to display
    errorMessage: PropTypes.string,

    // function to submit single TextArea form
    onSubmit: PropTypes.func,

    // function to handl the form field value changeg
    onChange: PropTypes.func.isRequired,

    // function to handle a keypress on the input field
    onKeyPress: PropTypes.func,

    // function to handle a click on the input field
    onClick: PropTypes.func,
    onBlur: PropTypes.func,

    // value of the text field
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // type of the input field
    type: PropTypes.string,

    // label to add to the TextArea
    label: PropTypes.string,
    rightLabel: PropTypes.string,

    // whether or not the TextArea is disabled
    disabled: PropTypes.bool,
    wysiwyg: PropTypes.bool,

    // whether or not the field should autofocus
    autofocus: PropTypes.bool,

    // style of the TextArea
    styleType: PropTypes.oneOf(['default', 'dark', 'underline']),

    // whether or not the TextArea has padding below
    padded: PropTypes.bool,
    characterLimit: PropTypes.number,

    // default value to pass to input
    defaultValue: PropTypes.string,

    size: PropTypes.oneOf(['small', 'medium'])
};

FormWrapper.propTypes = {
    onSubmit: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

TextArea.defaultProps = {
    className: '',
    error: false,
    type: 'text',
    disabled: false,
    wysiwyg: false,
    autofocus: false,
    styleType: 'default',
    size: 'medium',
    padded: true
};
