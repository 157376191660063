import React, { useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Icon from './Icon';
import Button from './Button';
import { Body, Caption } from './Typography';
import Loading from './Loading';
import { WidthContext } from '../../contexts/WidthContextProvider';

const Dropzone = props => {
    const { isMobile } = useContext(WidthContext);
    const onDrop = useCallback(acceptedFiles => {
    }, []);

    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: props.isVideoDoc ? 'video/*, .pdf' : (props.isVideo ? 'video/*' : (props.isDocument ? '.pdf, .doc, .key, .ppt, .jpg, .jpeg, .xlsx' : `image/png, image/svg, image/jpg, image/jpeg, ${props.isGallery && 'video/*'}`)),
        maxFiles: props.maxFiles || (props.isDocument ? 15 : 1),
        multiple: props.multiple || false
    });

    useEffect(() => {
        if (acceptedFiles.length) {
            acceptedFiles.forEach((element, index) => {
                const file = acceptedFiles[index];
                props.handleFile(file);
            });
        }
    }, [acceptedFiles]);

    const handleDelete = (e) => {
        e.stopPropagation();
        props.handleDelete();
    };

    return (
        <>

            {props.isDocument && props.attached.length ? (
                <div className='Dropzone flex center column'>
                    <Button
                        type='secondary'
                        className='full-width Dropzone__button'
                        size={isMobile ? 'small' : 'regular'}
                        label='hello'
                    >
                        <input
                            accept='.pdf, .doc, .key, .ppt, .jpg, .jpeg, .xlsx' multiple type='file' id='file' className='Dropzone__input pointer' {...getInputProps()}
                        />
                        <label className='pointer Dropzone__label' htmlFor='file'>Add Another File</label>
                    </Button>
                    <Caption className='Dropzone__smallText spacing-sm' center>File Type: {props.fileTypes.join(', ')}</Caption>
                    <Caption className='Dropzone__smallText' center>{props.finePrint}</Caption>

                </div>

            ) : (
                <>
                    <div {...getRootProps()} className={`Dropzone ${props.className || ''} ${props.error ? 'Dropzone--error' : ''} flex center column pointer`}>
                        {props.disabled && <div className='Dropzone__overlay' />}
                        <input {...getInputProps()} />
                        {props.thumbnailSrc && !props.isVideoDoc ? (
                            <img className='Dropzone__thumbnail' src={props.thumbnailSrc} />
                        ) : (props.loading ? (
                            <Loading />
                        ) : (
                            <Icon name={props.icon || 'cloud-upload'} className='Dropzone__icon' />
                        ))}
                        {props.filename ? (
                            <>
                                <Body number={2} className='Dropzone__text spacing-sm'>{props.title ? props.title : props.filename}</Body>
                                <div className='flex Dropzone__buttons spacing-sm'>
                                    <Button
                                        type='secondary'
                                        onClick={handleDelete}
                                        size={isMobile ? 'small' : 'regular'}
                                    >Delete
                                    </Button>
                                    <Button
                                        type='primary'
                                        size={isMobile ? 'small' : 'regular'}
                                    >Replace
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                {
                                    isDragActive
                                        ? <Body number={2} className='Dropzone__text spacing-sm'>Drop here</Body>
                                        : (
                                            <Body number={2} className='Dropzone__text spacing-sm'>Drag & drop to upload or <span className='Dropzone__link button'>click to browse</span></Body>
                                        )
                                }
                                <Caption className='Dropzone__smallText spacing-sm' center>File Type: {props.fileTypes.join(', ')}</Caption>
                                <Caption className='Dropzone__smallText' center>{props.finePrint}</Caption>
                            </>
                        )}
                    </div>

                    {props.error && props.errorMessage && <Caption className='Dropzone__error error-text' block>{props.errorMessage}</Caption>}

                </>
            )}

        </>
    );
};

Dropzone.propTypes = {
    icon: PropTypes.string,
    className: PropTypes.string,
    handleFile: PropTypes.func,
    handleDelete: PropTypes.func,
    thumbnailSrc: PropTypes.string,
    fileTypes: PropTypes.array,
    isVideo: PropTypes.bool,
    isDocument: PropTypes.bool,
    isVideoDoc: PropTypes.bool,
    finePrint: PropTypes.string,
    errorMessage: PropTypes.string,
    error: PropTypes.bool,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    title: PropTypes.string,
    filename: PropTypes.string,
    attached: PropTypes.array,
    maxFiles: PropTypes.number,
    removeFile: PropTypes.func,
    isGallery: PropTypes.bool
};

Dropzone.defaultProps = {
    fileTypes: [],
    attached: []
};

export default Dropzone;
