import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Heading, Subtitle } from '../modules/Typography';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { Row, Col } from '../modules/Grid';
import Button from '../modules/Button';
import heroImage from '../../images/mpi-homepage-header.png';

const VendorHome = props => {
    const { isMobile, size } = useContext(WidthContext);
    const { vendorSlug } = useParams();
    let headerNumber = 4;
    if (size === 'xxl') {
        headerNumber = 2;
    } else if (size === 'xl') {
        headerNumber = 3;
    }
    return (
        <div className='Home VendorHome'>
            <div>
                <Helmet><title>Production Technology Portal</title></Helmet>
                <div className='Home__hero__vendor' style={{ backgroundImage: `url(${heroImage})` }}>
                    <div className='VendorHome__hero__text'>
                        <Col xs={12} md={8} className='flex column align-flex-start'>
                            <Heading number={headerNumber} className='VendorHome__hero__text__heading'>Production Technology Portal</Heading>
                            {isMobile || size === 'md' ? (
                                <Subtitle number={1} className='Home__hero__text subtext'>Welcome to the PTP portal where we connect Filmmakers to Innovative Technology! Our goal is to facilitate the adoption of new production-ready technologies into our business processes across all studios to service great storytelling and creativity.</Subtitle>
                            ) : (
                                <Heading number={size === 'xxl' ? 5 : 6} className='Home__hero__text subtext'>Welcome to the PTP portal where we connect Filmmakers to Innovative Technology! Our goal is to facilitate the adoption of new production-ready technologies into our business processes across all studios to service great storytelling and creativity.</Heading>
                            )}
                        </Col>
                    </div>
                </div>
                <div className='VendorHome__content'>
                    <Row>
                        <Col md={8}>
                            <Heading block number={6}>Welcome to The Walt Disney Studios Production Technology Portal!</Heading>
                            <Subtitle block faded className='VendorHome__content__subtitle spacing-sm'>You've been invited to showcase your unique products and services to filmmakers and production staff across TWDC's banners! To get started, please validate and provide as much information about your company, contact, featured product/service(s) and be sure changes are submitted for approval.</Subtitle>
                            <Subtitle block faded className='VendorHome__content__subtitle spacing-sm'>If you have any additional questions or concerns please email <a className='VendorHome__link' href={`mailto:${'STUDIO.DL-PTP@disney.com'}`} target='_blank' rel='noopener noreferrer'>STUDIO.DL-PTP@disney.com.</a>
                            </Subtitle>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default VendorHome;
