import React, { Fragment, useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import Cookies from 'universal-cookie';
import { UserContext } from '../../contexts/UserContextProvider';
import { LOGIN } from '../../queries';
import Button from '../modules/Button';
import TextField from '../modules/TextField';
import { Heading, Caption, Subtitle } from '../modules/Typography';
import { Link } from 'react-router-dom';

const LoginVendor = (props) => {
    const cookies = new Cookies();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [login] = useMutation(LOGIN);
    const { setUser } = useContext(UserContext);
    const handleEmail = e => setEmail(e.target.value);

    const handleLogin = async (e) => {
        try {
            e.preventDefault();
            const { data } = await login({
                variables: {
                    input: {
                        identifier: email,
                        password,
                        provider: 'local'
                    }
                }
            });
            const { user, jwt } = data.login;
            cookies.set('access_token', jwt);
            setUser(user);
        } catch (err) {
            setError('The password does not match the email provided. Please re-enter the correct password or reset');
        }
    };

    const handleKeyPress = (e) => {
        if (e.charCode === 13) {
            handleLogin(e);
        }
    };
    return (
        <Fragment>
            <Heading number={5} block className='Login__title Login__title--Vendor'>Welcome to the Production  <br />Tech Vendor Portal</Heading>
            <Subtitle className='Login__subtitle' number={2}>The place to manage your content</Subtitle>
            <TextField
                type='email'
                value={email}
                placeholder='Enter Email'
                onChange={handleEmail}
                autofocus
            />
            <TextField
                icon='' // TO DO: can't find eye-icon, need to be added
                type='password'
                value={password}
                placeholder='Enter Password'
                onChange={(e) => { setPassword(e.target.value); }}
                onKeyPress={handleKeyPress}
                error={error}
            />
            {error && <Caption number={3} className='Login__error-message' block error topPadded>The password does not match the email provided. Please re-enter the correct password or reset.</Caption>}
            <Button
                className='Login__signin' type='primary' onClick={handleLogin}
            >Sign In
            </Button>
            <Link to='/forgot-password' className='Login__forgot-password flex center'>
                <Caption capitalize={false}>Forgot Password?</Caption>
            </Link>
        </Fragment>
    );
};

export default LoginVendor;
