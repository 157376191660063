import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Subtitle } from '../modules/Typography';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { UserContext } from '../../contexts/UserContextProvider';
import { DataContext } from '../../contexts/DataContextProvider';

const SubcategoryList = props => {
    const { pathname } = useLocation();
    const isVendor = pathname === '/categories';
    const { setSubcategory, subcategory, setCategory, setOpen } = useContext(DataContext);
    const { isMobile } = useContext(WidthContext);
    const { setUserTeamCategory, setUserTeamSubcategory, setUserCategory, setUserSubcategory } = useContext(UserContext);

    const handleClick = (id) => {
        setSubcategory(id);
        setCategory(props.categoryId);
        if (isVendor) {
            setUserSubcategory(id);
            setUserCategory(props.categoryId);
        } else {
            setUserTeamSubcategory(id);
            setUserTeamCategory(props.categoryId);
        }
        if (isMobile) {
            setOpen(false);
        }
    };

    const handleAll = () => {
        setSubcategory(`${props.categoryId}-all`);
        setCategory(props.categoryId);
        if (isVendor) {
            setUserSubcategory(`${props.categoryId}-all`);
            setUserCategory(props.categoryId);
        } else {
            setUserTeamSubcategory(`${props.categoryId}-all`);
            setUserTeamCategory(props.categoryId);
        }
    };

    return (
        <div className={`Categories__category__subcategories flex column align-flex-start ${!props.open ? 'visually-hidden' : ''}`}>
            <Subtitle
                className={`Categories__category__name button ${`${props.categoryId}-all` === subcategory ? 'active' : ''}`}
                number={1}
                onClick={handleAll}
            >All
            </Subtitle>
            {props.subcategories.map(subcat => (
                <Subtitle
                    className={`Categories__category__name button ${subcat.id === subcategory ? 'active' : ''}`}
                    number={1}
                    key={subcat.id}
                    onClick={() => handleClick(subcat.id)}
                >{subcat.name}
                </Subtitle>
            ))}
        </div>
    );
};

SubcategoryList.propTypes = {
    categoryId: PropTypes.string,
    open: PropTypes.bool,
    subcategories: PropTypes.array
};

export default SubcategoryList;
