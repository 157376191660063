import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from '../modules/Grid';
import logoImage from '../../images/studioTechLogin.png';
import { Subtitle, Caption } from '../modules/Typography';
import Button from '../modules/Button';
import LoginMessageBody from '../login/LoginMessageBody';
import LoginAdmin from '../login/LoginAdmin';
import LoginVendor from '../login/LoginVendor';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

export const LoginContext = React.createContext();

const Login = () => {
    const [vendorLogin, setVendorLogin] = useState(false);
    const cookies = new Cookies();
    const location = useLocation();

    useEffect(() => {
        const accessToken = cookies.get('access_token');
        if (accessToken && isTokenExpired(accessToken)) {
            cookies.remove('access_token', { path: '/' });
        }
        cookies.set('last_location', location.pathname, { path: '/' });
    }, []);

    function isTokenExpired (token) {
        if (!token) {
            return true;
        }

        const decodedToken = parseJwt(token);
        return (
            decodedToken && decodedToken.exp && decodedToken.exp * 1000 < Date.now()
        );
    }

    function parseJwt (token) {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const decodedToken = JSON.parse(Buffer.from(base64, 'base64').toString('utf-8'));

            return decodedToken;
        } catch (e) {
            return null;
        }
    }

    return (
        <LoginContext.Provider value={{}}>
            <div className='Login'>
                <Helmet><title>Production Technology Portal Login Page</title></Helmet>
                <Row className='Login__Row no-gutters'>
                    <Col sm={12} lg={4} className='Login__sidebar__container flex column space-around'>
                        <div className='flex column'>
                            <img className='Login__logo' src={logoImage} alt='logo' />
                            <div className='Login__sidebar'>
                                {vendorLogin ? <LoginVendor /> : <LoginAdmin />}
                            </div>
                        </div>
                        <div className='Login__footer'>
                            {!vendorLogin && (
                                <div className='Login__swap'>
                                    <Subtitle number={1} block className='Login__non-disney'>Are you submitting a product?</Subtitle>
                                    <div className='Login__swap__text flex center'>
                                        <Button
                                            type='secondary'
                                            className='Login__swap__button flex center'
                                            onClick={() => setVendorLogin(vendorLogin => !vendorLogin)}
                                        >
                                            Portal Login Here
                                        </Button>
                                    </div>
                                </div>
                            )}
                            <Caption className='Login__copyright flex center' number={2}>© Disney 2020. All Rights Reserved.</Caption>
                        </div>
                    </Col>
                    <Col sm={12} lg={8} className='Login__message-body'>
                        <LoginMessageBody authorizedUser />
                    </Col>
                </Row>
            </div>
        </LoginContext.Provider>
    );
};

export default Login;
