import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { VendorContext } from './VendorLayout';
import { REQUEST_DELETE } from '../../queries';
import Paper from '../modules/Paper';

import Button from '../modules/Button';
import Icon from '../modules/Icon';
import TextField from '../modules/TextField';
import DialogWrapper from '../modules/DialogWrapper';
import Table from '../modules/Table';
import { Subtitle, Heading, Overline } from '../modules/Typography';
import { Container, Row, Col } from '../modules/Grid';
import { WidthContext } from '../../contexts/WidthContextProvider';

const Products = props => {
    const { setBreadcrumbs, setActiveTab, vendor, vendorName, vendorSlug, refetch } = useContext(VendorContext);
    const { isMobile } = useContext(WidthContext);
    const history = useHistory();
    const [searchTerm, setSearchTerm] = useState('');
    const [products, setProducts] = useState([]);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteProduct, setDeleteProduct] = useState({});
    const [requestDelete] = useMutation(REQUEST_DELETE);
    const approvals = {
        draft: { text: 'Draft', icon: 'note', color: 'secondary-dark' },
        pendingApproval: { text: 'Pending Approval', icon: 'info', color: 'info' },
        published: { text: 'Approved', icon: 'check-circle', color: 'success' },
        deleteRequested: { text: 'Delete Requested', icon: 'trash-can', color: 'error' }
    };

    useEffect(() => {
        setBreadcrumbs([{ text: 'Products' }]);
        setActiveTab('products');
    }, [vendorName]);

    useEffect(() => {
        const products = (vendor.original.products || [])
            .filter(product => product.original);
        if (searchTerm) {
            setProducts(products.filter(product => (product.name.toLowerCase().includes(searchTerm.toLowerCase()))));
        } else {
            setProducts(products);
        }
    }, [searchTerm, vendor.original.products]);

    const handleSearchSubmit = () => {

    };

    const handleConfirmDelete = async () => {
        setDeleteLoading(true);
        await requestDelete({ variables: { id: deleteProduct.id } });
        requestDelete({ variables: { id: deleteProduct.originalId } });
        setDeleteOpen(false);
        refetch();
        setDeleteLoading(false);
    };

    const handleAdd = () => {
        history.push(`/${vendorSlug}/products/create`);
    };
    const handleRowClick = (row) => {
        const { slug } = row.original;
        history.push(`/${vendorSlug}/products/${slug}`);
    };

    const getState = ({ value: isPublished }) => {
        return (
            <div className={`VendorInformation__State flex center ${isPublished ? 'published' : 'unpublished'}`}>
                <Overline color={isPublished ? 'success' : null} error={!isPublished}>{isPublished ? 'published' : 'unpublished'}</Overline>
            </div>

        );
    };

    const getStatus = ({ value: status }) => {
        const approvalStatus = status || 'draft';
        const approval = approvals[approvalStatus];
        return (

            <div className='VendorInformation__Status flex flex-start align-center'>
                <Icon name={approval.icon} className={`${approval.color}-text VendorInformation__sideIcon`} />
                <Subtitle number={isMobile ? 4 : 2} color={approval.color}>{approval.text}</Subtitle>
            </div>
        );
    };

    const getActionCell = ({ row }) => {
        const handleDelete = async (e) => {
            e.stopPropagation();
            setDeleteOpen(true);
            const { id, name, originalId } = row.original;
            setDeleteProduct({ id, name, originalId });
        };
        return (
            <div className='VendorInformation__ActionCell flex flex-end align-center'>
                <Icon name='edit' className='VendorInformation__ActionIcon' />
                <Icon name='trash-can' className='VendorInformation__ActionIcon' onClick={handleDelete} />
            </div>
        );
    };

    const columns = [
        {
            Header: 'Name',
            accessor: 'name',
            width: 4,
            sortType: 'basic'
        },
        {
            Header: 'Approval status',
            accessor: 'approvalStatus',
            Cell: getStatus,
            width: 3,
            sortType: 'basic'
        },
        {
            Header: 'State',
            accessor: 'isPublished',
            Cell: getState,
            width: 3,
            sortType: 'basic'
        },
        {
            Header: '',
            accessor: 'actions',
            width: 2,
            Cell: getActionCell

        }

    ];

    const data = products.map(product => (
        {
            name: product.name.replace('_PREVIEW', ''),
            id: product.id,
            slug: product.slug.replace('_preview', ''),
            approvalStatus: product.approvalStatus,
            isPublished: !!product.original.published_at,
            originalId: product.original.id
        }
    ));
    return (
        <div className='VendorInformation'>
            <Container className='VendorInformation__container'>
                <Heading className='VendorInformation__title' block number={isMobile ? 5 : 3}>Products</Heading>
                <Subtitle className='VendorInformation__subtitle' block number={2}>All products associated with your vendor page.</Subtitle>
                <Row>
                    <Col sm={12}>
                        <Paper className='ProductList__paper'>
                            <div className='flex padded'>
                                <TextField
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                    onSubmit={handleSearchSubmit}
                                    icon='search'
                                    padded={false}
                                    placeholder='Search Products'
                                    className='VendorInformation__Search VendorInformation__input'
                                />
                                <Button type='primary' onClick={handleAdd}>New Product</Button>
                            </div>
                            {products.length ? (
                                <Table
                                    className='VendorInformation__table spacing-md'
                                    columns={columns}
                                    data={data}
                                    useBlockLayout
                                    handleRowClick={handleRowClick}
                                />) : ''}
                        </Paper>

                    </Col>
                </Row>
            </Container>
            <DialogWrapper
                className='VendorInformation__Dialog'
                title='Request to delete product'
                subtitle='You are about to send a request to delete this product. The product will be deleted on approval.'
                open={deleteOpen}
                handleConfirm={handleConfirmDelete}
                confirmText='Request to Delete'
                handleCancel={() => setDeleteOpen(false)}
                size='medium'
                loading={deleteLoading}
            />
        </div>
    );
};

export default Products;
