import React, { useContext } from 'react';
import { ProductContext } from './ProductDetail';
import { Heading } from '../modules/Typography';
import Button from '../modules/Button';
import { Container, Row, Col } from '../modules/Grid';
import ProjectApplication from './ProjectApplication';

const Applications = props => {
    const {
        applications,
        setApplications
    } = useContext(ProductContext);

    const handleAdd = () => {
        const newApplication = { title: '' };
        setApplications([...applications, newApplication]);
    };

    const handleApplication = (e, field, idx) => {
        const newApplications = [...applications];
        const application = { ...newApplications[idx], [field]: e.target.value };
        newApplications[idx] = application;
        setApplications(newApplications);
    };

    return (
        <Row className='flex '>
            <Col md={12}>
                <div className='VendorInformation__Section credit'>
                    <Container>
                        <Heading className='' number={6}>Credits</Heading><br /><br />
                        {applications.map((application, idx) =>
                            <ProjectApplication
                                application={application}
                                idx={idx}
                                key={`application-${idx}`}
                                handleApplication={handleApplication}

                            />
                        )}
                        <div className='flex flex-end full-width'>
                            <Button type='primary' onClick={handleAdd}>Add a project</Button>
                        </div>
                    </Container>
                </div>
            </Col>
        </Row>

    );
};

export default Applications;
