import React from 'react';
import GeneralProductInfo from './GeneralProductInfo';
import Contacts from './Contacts';
import ProductDescription from './ProductDescription';
import Constraints from './Constraints';
import RelatedFiles from './RelatedFiles';
import Applications from './Applications';
import Gallery from './Gallery';

const ProductInformation = props => {
    return (
        <>
            <GeneralProductInfo />
            <ProductDescription />
            <Constraints />
            <Contacts />
            <Gallery />
            <RelatedFiles />
            <Applications />
        </>
    );
};

export default ProductInformation;
