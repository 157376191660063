import React, { useContext, useState } from 'react';
import Cookies from 'universal-cookie';
import { useHistory, Link } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContextProvider';
import IconButton from '../modules/IconButton';
import Icon from '../modules/Icon';
import { Subtitle } from '../modules/Typography';
import Notifications from './Notifications';
import logo from '../../images/ptp-logo.svg';

const VendorTopNav = props => {
    const cookies = new Cookies();
    const history = useHistory();
    const { user, setUser } = useContext(UserContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const handleSignOut = () => {
        cookies.remove('access_token');
        cookies.remove('refresh_token');
        setUser({
            id: '',
            email: '',
            role: { name: '' },
            vendors: [],
            teams: []
        });
        history.push('/');
    };

    return (
        <div className='VendorTopNav flex'>
            <Link to='/' className='TopNav__left flex column'>
                <img src={logo} alt='Production Technology Portal' />
            </Link>
            <div className='VendorTopNav__right'>
                <div className='flex align-center'>
                    <Notifications />
                    <div className='flex align-center' onClick={() => setMenuOpen(!menuOpen)}>
                        <IconButton size='large' name='profile' className='VendorTopNav__icon profile' />
                        <Subtitle className='pointer'>{user.email}</Subtitle>
                        <Icon name='caret-down' className='VendorTopNav__menuIcon pointer' />
                        {menuOpen && (

                            <div className='VendorTopNav__signout'>
                                <div className='VendorTopNav__signout__option pointer' onClick={handleSignOut}>
                                    <Subtitle number={2}>Sign Out</Subtitle>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </div>

    );
};

export default VendorTopNav;
