import React from 'react';
import PropTypes from 'prop-types';
import { Body } from './Typography';

const Tooltip = props => {
    return (
        <div className='Tooltip__wrapper'>
            <Body className='Tooltip' number={2}>{props.children}</Body>
        </div>
    );
};
Tooltip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
export default Tooltip;
