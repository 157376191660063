import React from 'react';
import { useQuery } from '@apollo/client';
import { Overline } from '../modules/Typography';
import { GET_RELATED_NEWS } from '../../queries';
import PostCell from './PostCell';
import Loading from '../modules/Loading';

const PostTable = props => {
    const { loading, data } = useQuery(GET_RELATED_NEWS);

    if (loading) return <Loading />;

    const { posts } = data;

    return (
        <div className='PostTable'>
            <Overline>Related News</Overline>
            {posts.map(post => (
                <PostCell post={post} key={post.id} />
            ))}
        </div>
    );
};

export default PostTable;
