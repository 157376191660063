import React, { useContext, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import moment from 'moment';
import Paper from '../modules/Paper';
import { Overline, Heading, Body, Caption, Subtitle } from '../modules/Typography';
import IconButton from '../modules/IconButton';
import rightArrow from '../../images/scroll.svg';
import leftArrow from '../../images/scroll-1.svg';
import marvelLogo from '../../images/marvel.png';
import searchlightLogo from '../../images/searchlight.png';
import pixarLogo from '../../images/pixar.png';
import disneyLogo from '../../images/disney.png';
import lucasfilmLogo from '../../images/lucasfilm.png';
import animationLogo from '../../images/wdas.png';
import twentiethLogo from '../../images/20thcentury.png';
import defaultVideoImage from '../../images/defaultVideoImage.png';
import { Container, Row, Col } from '../modules/Grid';
import Icon from '../modules/Icon';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { UserContext } from '../../contexts/UserContextProvider';
import parseMarkdown from '../../utils/markdownParser';
import PdfViewer from '../modules/PdfViewer';
import Button from '../modules/Button';

const Product = ({
    name,
    vendorName,
    description,
    subcategories,
    categories,
    marvel,
    pixar,
    disneyAnimation,
    lucasfilm,
    searchlight,
    twentiethCentury,
    disney,
    constraints,
    details,
    gallery,
    comments,
    qa,
    videoThumbnail,
    vendor,
    videoUrl,
    isUnpublished,
    published_at,
    media,
    applications = {},
    relatedFiles,
    contacts,
    thumbnail,
    vendorLogo,
    vendorLogoDark
}) => {
    const { isMobile } = useContext(WidthContext);
    const { user } = useContext(UserContext);
    const isVendor = user.role.name === 'Vendor';
    const isMedia = videoUrl || media;
    const [openIndex, setIndex] = useState(-1);
    const [galleryModalImg, setGalleryModalImg] = useState({});
    const [copyMessage, setCopyMessage] = useState(false);
    const tags = [...categories, ...subcategories];
    const { websiteUrl, facebookUrl, instagramUrl, twitterUrl, linkedinUrl, Contacts } = vendor;
    const contactsList = contacts.length ? contacts : Contacts;
    const imageRef = useRef(null);

    useEffect(() => {
        const videoContainer = document.getElementById('video-container');
        const prevent = e => e.preventDefault();
        if (videoContainer) {
            videoContainer.addEventListener('contextmenu', prevent);
            return () => {
                videoContainer.removeEventListener('contextmenu', prevent);
            };
        }
    }, []);

    const galleryRef = useRef(null);
    const commentRef = useRef(null);

    const settings = {
        dots: false,
        arrows: false,
        centerMode: false,
        infinite: true,
        slidesToScroll: 1,
        speed: 250,
        variableWidth: true

    };

    const copyToClipboard = () => {
        setCopyMessage(true);
        setTimeout(() => {
            setCopyMessage(false);
        }, 2000);
    };

    const gallerySettings = {
        ...settings,
        slidesToShow: gallery && gallery.length < 4 ? gallery.length : 1
    };

    const creditSettings = {
        ...settings,
        slidesToShow: 1
    };
    const handlePrevious = (e, ref) => {
        e.stopPropagation();
        ref.current.slickPrev();
    };

    const handleNext = (e, ref) => {
        e.stopPropagation();
        ref.current.slickNext();
    };

    const handleDownload = () => {
        relatedFiles.map(el => {
            window.open(el.document.url, '_blank');
        });
    };

    const handleQuestion = () => {
        const email = 'kimberley.belenzon@disney.com';
        const subject = `Question regarding ${name} by ${vendorName}`;
        const emailBody = `Hi ${contacts.length ? contacts[0].name : ''},`;
        window.location = 'mailto:' + email + '?subject=' + subject + '&body=' + emailBody;
    };

    const handleComment = () => {
        const email = 'kimberley.belenzon@disney.com';
        const subject = `Comment regarding ${name} by ${vendorName}`;
        const emailBody = `Hi ${contacts.length ? contacts[0].name : ''},`;
        window.location = 'mailto:' + email + '?subject=' + subject + '&body=' + emailBody;
    };

    const handleOpen = (e, idx) => {
        e.stopPropagation();
        setIndex(openIndex === idx ? -1 : idx);
    };

    const showImage = (galleryImage, idx) => {
        const imageUrl = galleryImage.image.url || galleryImage.image.src;
        setGalleryModalImg({ imageUrl, text: galleryImage.text, idx });
        document.body.classList.add('no-scroll');
    };

    const handleClose = () => {
        setGalleryModalImg({});
        document.body.classList.remove('no-scroll');
    };

    const handleGalleryModal = (idx) => {
        if (idx < 0) { idx = gallery.length - 1; };
        if (idx >= gallery.length) { idx = 0; };
        const imageUrl = gallery[idx].image.url || gallery[idx].image.src;
        const imageText = gallery[idx].text;
        setGalleryModalImg({ imageUrl, text: imageText, idx });
    };

    const videoThumb = videoThumbnail?.url || gallery?.[0]?.url || thumbnail?.url || defaultVideoImage;

    const heroVideoImage = (
        <div className='Vendor__Product__heroImage' style={{ backgroundImage: `url(${videoThumb})` }}>
            <IconButton name='play' size='x-large' className='Product__videoIcon' />
        </div>
    );

    return (
        <div className='Vendor__Product flex column'>
            {isUnpublished && <Overline className='Vendor__Product__unpublished error-text'>Unpublished</Overline>}
            {media || videoUrl ? (
                media && media.ext === '.pdf' ? (
                    <div className='Vendor__Product__pdf'>
                        <PdfViewer src={media.url} />
                    </div>

                ) : (
                    <div className='Vendor__Product__video__container flex center-align-center ' id='video-container'>
                        {media && media.url ? (
                            <video
                                className='Vendor__Product__video'
                                controls
                                controlslist='nodownload'
                                poster={videoThumb}
                            >
                                <source src={media.url} type='video/mp4' />
                            </video>
                        ) : (
                            videoUrl ? (
                                <iframe
                                    className='Vendor__Product__video'
                                    width={isMobile ? '768' : '1440'}
                                    height={isMobile ? '432' : '810'}
                                    src={`${videoUrl}?autoplay=1`}
                                    frameBorder='0'
                                    allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                    allowFullScreen
                                />
                            ) : heroVideoImage
                        )}
                        <Col className='Vendor__contactInfo'>
                            <div className='flex align-flex-start'>
                                <div className='Vendor__contactInfo__left flex column align-flex-start'>
                                    <Overline className='Vendor__header__medium'>Vendor</Overline>
                                    <img className='Vendor__logo' src={vendorLogoDark?.url || vendorLogo?.url} />
                                    {websiteUrl &&
                                    <div className='Vendor__contactInfo__website flex align-baseline'>
                                        <Subtitle number={1} color='primary-light'>Visit Vendor Website</Subtitle>
                                        <a href={websiteUrl} rel='noreferrer' target='_blank'>
                                            <IconButton name='outward-link' className='Vendor__contactInfo__icon primary-light-text' />
                                        </a>
                                    </div>}
                                    <div className='Vendor__contactInfo__social'>
                                        {facebookUrl ? (
                                            <a href={facebookUrl} rel='noreferrer' target='_blank'>
                                                <IconButton name='facebook' className='primary-light-text' />
                                            </a>) : ''}
                                        {instagramUrl ? (
                                            <a href={instagramUrl} rel='noreferrer' target='_blank'>
                                                <IconButton name='instagram' className='primary-light-text' />
                                            </a>) : ''}
                                        {twitterUrl ? (
                                            <a href={twitterUrl} rel='noreferrer' target='_blank'>
                                                <IconButton name='twitter' className='primary-light-text' />
                                            </a>) : ''}
                                        {linkedinUrl ? (
                                            <a href={linkedinUrl} rel='noreferrer' target='_blank'>
                                                <IconButton name='linkedin' className='primary-light-text' />
                                            </a>) : ''}
                                    </div>
                                    <Overline className='Vendor__header__medium'>Contacts</Overline>
                                    <div className='Vendor__Product__info__contact'>
                                        <Subtitle block number={1}>{contactsList[0]?.name || ''}</Subtitle>
                                        <div className='flex flex-start'>
                                            <Subtitle onClick={() => navigator.clipboard.writeText(contactsList[0]?.info)} block number={1} color='primary-light'>{contactsList[0]?.info || ''}
                                                {copyMessage ? <span className='Vendor__contactInfo__copied'>Copied! <Icon name='check' /></span> : <Icon className='pointer' onClick={() => copyToClipboard()} name='copy' />}
                                            </Subtitle>

                                        </div>
                                    </div>
                                    {applications.length ? (
                                        <div className='Vendor__Product__Credits'>
                                            <Overline className='Vendor__header__medium'>Credits</Overline>

                                            {applications.map((item, idx) => (
                                                <div className='flex flex-start' key={`applications-${idx}`}>
                                                    {/* <div className='Vendor__Product__Credit__top'> */}
                                                    <Body className='title flex align-flex-start'>{item.title}</Body>
                                                    {/* </div> */}
                                                </div>))}

                                        </div>
                                    ) : ''}
                                    <Overline className='Vendor__header__medium'>Last Published</Overline>
                                    <Body>{moment(published_at).format('LL')}</Body>

                                </div>

                            </div>
                        </Col>
                    </div>

                )

            ) : (
                videoThumbnail ? (
                    <div className='Vendor__Product__video thumbnail' style={{ backgroundImage: `url("${videoThumbnail.url}")` }} />

                ) : ''
            )}
            {!isMedia &&
            <div className='flex-start-self'>
                <div className='Vendor__contactInfo__row product flex align-flex-start'>
                    <div className='flex column align-flex-start'>
                        <Overline className='Vendor__header__medium'>Vendor</Overline>
                        <img className='Vendor__logo' src={vendorLogo.url} />
                        {websiteUrl &&
                        <div className='Vendor__contactInfo__website flex align-baseline'>
                            <Subtitle number={1} color='primary-light'>Visit Vendor Website</Subtitle>
                            <a href={websiteUrl} rel='noreferrer' target='_blank'>
                                <IconButton name='outward-link' className='Vendor__contactInfo__icon primary-light-text' />
                            </a>
                        </div>}
                        <div className='Vendor__contactInfo__social'>
                            {facebookUrl ? (
                                <a href={facebookUrl} rel='noreferrer' target='_blank'>
                                    <IconButton name='facebook' className='primary-light-text' />
                                </a>) : ''}
                            {instagramUrl ? (
                                <a href={instagramUrl} rel='noreferrer' target='_blank'>
                                    <IconButton name='instagram' className='primary-light-text' />
                                </a>) : ''}
                            {twitterUrl ? (
                                <a href={twitterUrl} rel='noreferrer' target='_blank'>
                                    <IconButton name='twitter' className='primary-light-text' />
                                </a>) : ''}
                            {linkedinUrl ? (
                                <a href={linkedinUrl} rel='noreferrer' target='_blank'>
                                    <IconButton name='linkedin' className='primary-light-text' />
                                </a>) : ''}
                        </div>
                    </div>
                    <div className='flex column align-flex-start'>
                        <Overline className='Vendor__header__medium'>Contacts</Overline>
                        <div className='Vendor__Product__info__contact'>
                            <Subtitle block number={1}>{contactsList[0]?.name || ''}</Subtitle>
                            <Subtitle onClick={() => navigator.clipboard.writeText(contactsList[0]?.info)} block number={1} color='primary-light'>{contactsList[0]?.info || ''}
                                {copyMessage ? <span className='Vendor__contactInfo__copied'>Copied! <Icon name='check' /></span> : <Icon className='pointer' onClick={() => copyToClipboard()} name='copy' />}
                            </Subtitle>
                        </div>
                    </div>
                    <div className='flex column align-flex-start'>
                        {applications.length ? (
                            <div className='Vendor__Product__Credits'>
                                <Overline className='Vendor__header__medium'>Credits</Overline>

                                {applications.map((item, idx) => (
                                    <div className='flex flex-start' key={`applications-${idx}`}>
                                        {/* <div className='Vendor__Product__Credit__top'> */}
                                        <Body className='title flex align-flex-start'>{item.title}</Body>
                                        {/* </div> */}
                                    </div>))}

                            </div>
                        ) : ''}
                    </div>
                    <div className='flex column align align-flex-start'>
                        <Overline className='Vendor__header__medium'>Last Published</Overline>
                        <Body>{moment(published_at).format('LL')}</Body>
                    </div>

                </div>

            </div>}
            {(marvel || pixar || searchlight || lucasfilm || twentiethCentury || disney || disneyAnimation) && (
                <div className='Vendor__Product__approved flex column '>
                    <Overline className='Vendor__header__medium'>Approved Use For</Overline>
                    <div>
                        <Link to='/categories/products/banner/marvel'> {marvel && <img src={marvelLogo} />}</Link>
                        <Link to='/categories/products/banner/pixar'>{pixar && <img src={pixarLogo} />}</Link>
                        <Link to='/categories/products/banner/searchlight'>{searchlight && <img src={searchlightLogo} />}</Link>
                        <Link to='/categories/products/banner/lucasfilm'>{lucasfilm && <img src={lucasfilmLogo} />}</Link>
                        <Link to='/categories/products/banner/twentieth'>{twentiethCentury && <img src={twentiethLogo} />}</Link>
                        <Link to='/categories/products/banner/wdas'>{disneyAnimation && <img src={animationLogo} />}</Link>
                        <Link to='/categories/products/banner/disney'>{disney && <img src={disneyLogo} />}</Link>
                    </div>

                </div>
            )}
            <Row className='Vendor__overview'>
                <Col xs={12} lg={11}>
                    <Paper className='Vendor__detailCard flex column align-flex-start'>
                        {isUnpublished && <Overline className='Team__detailCard__unpublished error-text'>Unpublished</Overline>}

                        {description && description.length > 15 && (
                            <div className='Vendor__detailCard__overview'>
                                <Overline className='Vendor__header__medium__bold'>Overview</Overline>
                                <Body className='Vendor__detailCard__description' number={5}>{parseMarkdown(description)}</Body>
                                {tags.length > 1 &&
                                <div className='flex flex-start align-baseline'>
                                    <Icon name='tag' className='Vendor__detailCard__tag' />
                                    {tags.map((cat, i) => (
                                        <Caption key={cat.name}>
                                            {cat.name}{i !== tags.length - 1 ? ',' : ''}&nbsp;
                                        </Caption>
                                    ))}
                                </div>}
                            </div>
                        )}

                        <div className='Vendor__Product__Row__container'>
                            {details && details.length > 15 && (
                                <div lg={12} className={`Vendor__Product__Row flex column  flex-start align-baseline stretch-width ${constraints?.length > 15 ? 'line' : ''}`}>
                                    <Overline className='Vendor__header__medium__bold'>Details</Overline>
                                    <Body>{parseMarkdown(details)}</Body>
                                </div>
                            )}
                            {constraints && constraints.length > 15 && (
                                <div lg={12} className='Vendor__Product__Row flex column flex-start align-flex-start'>
                                    <Overline className='Vendor__header__medium__bold'>Constraints</Overline>
                                    <Body number={1}>{parseMarkdown(constraints)}</Body>
                                </div>
                            )}
                        </div>
                    </Paper>
                </Col>
            </Row>
            {gallery?.length ? (
                <div className='Vendor__Product__Gallery__Container'>
                    <Heading number={5} className='Vendor__Product__info__header'>Gallery</Heading>
                    <div className='Vendor__Product__Gallery'>
                        <img className='Vendor__Product__Gallery__arrow pointer left' src={leftArrow} onClick={e => handlePrevious(e, galleryRef)} />
                        <Slider {...gallerySettings} className='Vendor__Product__Gallery__Slider' ref={galleryRef}>
                            {gallery.map((item, idx) => {
                                if (item?.image?.url.indexOf('.mp4') > -1 || item?.image?.url.indexOf('.mov') > -1) {
                                    return (
                                        <div className='Vendor__Product__Gallery__Image pointer' key={`gallery-${idx}`} onClick={() => showImage(item, idx)}>
                                            <img className='Vendor__videoLogo video' src={vendorLogo ? vendorLogo.url : '#'} />
                                            <IconButton name='play' size='large' className='Product__videoIcon' />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={`gallery-${idx}`} onClick={() => showImage(item, idx)}>
                                            <div className='Vendor__Product__Gallery__Image pointer' style={{ backgroundImage: `url("${item?.image?.url || item?.image?.src}")` }} />
                                        </div>
                                    );
                                }
                            })}
                        </Slider>
                        <img className='Vendor__Product__Gallery__arrow pointer right' src={rightArrow} onClick={e => handleNext(e, galleryRef)} />
                    </div>
                </div>
            ) : ''}

            <Row className='Vendor__Product__Row'>
                {relatedFiles?.length ? (
                    <Col md={6}>
                        <div className='Vendor__Product__Related__Section'>
                            <div className='flex align-flex-start'>
                                <Heading number={5} className='Vendor__Product__Row__header'>Related Files ({relatedFiles.length})</Heading>
                                <Button type='tertiary' className='Vendor__Product__Button primary-light-text' onClick={handleDownload} trailingIcon='download'>Download All</Button>
                            </div>
                            <div className='Vendor__Product__Related flex column flex-start align-flex-start'>
                                {relatedFiles.map(item => (
                                    <div key={item.id} className='Vendor__Product__Related__File flex flex-start align-flex-start'>
                                        <a
                                            href={item.document ? item.document.url || item.document.src : '#'}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='Vendor__Product__Related__Icon'
                                        >
                                            <Icon className='Vendor__Product__icon' name='file-pdf' />
                                        </a>
                                        <div className='Vendor__Product__Related__File__text flex column align-flex-start'>
                                            <Body className='Vendor__Product__Related__File__name primary-light-text'>{item.title}</Body>
                                            <Body className='Vendor__Product__itemDescription'>{item.description}</Body>
                                        </div>
                                    </div>))}
                            </div>
                        </div>
                    </Col>
                ) : ''}
            </Row>
            {qa?.length ? (
                <div className='Vendor__Questions Vendor__Product__Row'>
                    <div className='flex align-flex-baseline'>
                        <Heading number={5} className='Vendor__Product__Row__header'>Q&A</Heading>
                        <Button type='tertiary' className='Vendor__Product__Button Vendor__Product__Row__header Vendor__Product__Button__Question  primary-light-text' onClick={handleQuestion}>Ask a Question</Button>
                    </div>
                    {qa.map((item, idx) => (
                        <div className='question-box padded' key={`qa-${idx}`}>
                            <div className='flex'>
                                <Body className='question-text'>{item.question}</Body><br />
                                <IconButton
                                    className={openIndex === idx ? 'icon-open' : ''}
                                    name={openIndex === idx ? 'minus' : 'plus'}
                                    size='small'
                                    onClick={e => handleOpen(e, idx)}
                                />
                            </div>
                            {openIndex === idx && <Body>A: {item.answer}</Body>}
                        </div>))}
                </div>
            ) : ''}

            {!isVendor && comments?.length ? (
                <div className='Vendor__Product__Comments__Container'>
                    <Heading number={5} className='Vendor__Product__info__header'>Comments</Heading>
                    <div className='flex'>
                        <Body className='Vendor__Product__info__subtitle'>*Comments are only visible for Disney Employees. These are for internal use only.</Body>
                        <Body className='Vendor__Product__document primary-light-text float-right'>
                            <a className='pointer' onClick={handleComment}>Submit a Comment</a>
                        </Body>
                    </div>
                    <div className='Vendor__Product__Comments'>
                        {comments.length > 2 && <img className='Vendor__Product__Gallery__arrow pointer left' src={leftArrow} onClick={e => handlePrevious(e, commentRef)} />}
                        <Slider {...creditSettings} className='Vendor__Product__Comments__Slider' ref={commentRef}>
                            {comments.map((item, idx) => (
                                <div className='Vendor__Product__Comment carousel-slide' key={`comments-${idx}`}>
                                    <p className='Vendor__Product__Comment__title'>{item.title}</p><br />
                                    <p className='Vendor__Product__Comment__text'>{item.text}</p>
                                    <p className='Vendor__Product__Comment__author'>{item.author}</p>

                                </div>))}
                        </Slider>
                        {comments.length > 2 && <img className='Vendor__Product__Gallery__arrow pointer right' src={rightArrow} onClick={e => handleNext(e, commentRef)} />}
                    </div>
                </div>) : ''}
            {galleryModalImg.imageUrl ? (
                <div className='modal-bg Product__galleryBg'>
                    <Container>
                        <Col md={10} mdOffset={1}>
                            <div className='Product__galleryModal'>
                                <IconButton className='Vendor__Product__Button Product__galleryClose primary-light-text' size='large' name='close' onClick={handleClose} />
                                {galleryModalImg.imageUrl.indexOf('.mp4') > -1 || galleryModalImg.imageUrl.indexOf('.mov') > -1 ? (
                                    <div className='Product__galleryImg' id='video-container'>
                                        <video
                                            className='Vendor__Product__video Product__galleryVideo'
                                            controls
                                            controlslist='nodownload'
                                            poster={videoThumbnail?.url || defaultVideoImage}
                                        >
                                            <source src={galleryModalImg.imageUrl} type='video/mp4' />
                                        </video>
                                    </div>
                                ) : <img className='Product__galleryImg' src={galleryModalImg.imageUrl} />}
                                <div className='flex Product__galleryImgText'>
                                    <Body className=''>{galleryModalImg.text}</Body>
                                    <Body className=''>Media {galleryModalImg.idx + 1}/{gallery.length}</Body>
                                </div>
                                <div className='flex space-between Product__galleryImgArrows'>
                                    <Button type='tertiary' className='Vendor__Product__Button primary-light-text' onClick={() => handleGalleryModal(galleryModalImg.idx - 1)}>&#60;&#60; Previous</Button>
                                    <Button type='tertiary' className='Vendor__Product__Button primary-light-text' onClick={() => handleGalleryModal(galleryModalImg.idx + 1)}>Next >></Button>
                                </div>
                            </div>
                        </Col>
                    </Container>
                </div>) : ''}
        </div>
    );
};

Product.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    subcategories: PropTypes.array,
    isUnpublished: PropTypes.bool,
    isOwner: PropTypes.bool,
    marvel: PropTypes.bool,
    pixar: PropTypes.bool,
    disneyAnimation: PropTypes.bool,
    disney: PropTypes.bool,
    lucasfilm: PropTypes.bool,
    searchlight: PropTypes.bool,
    twentiethCentury: PropTypes.bool,
    constraints: PropTypes.string,
    videoThumbnail: PropTypes.object,
    media: PropTypes.object,
    videoUrl: PropTypes.string,
    applications: PropTypes.object,
    thumbnail: PropTypes.object,
    relatedFiles: PropTypes.array,
    details: PropTypes.string,
    gallery: PropTypes.array,
    comments: PropTypes.array,
    qa: PropTypes.array,
    contacts: PropTypes.array,
    vendorName: PropTypes.string,
    vendorLogo: PropTypes.string,
    vendorLogoDark: PropTypes.string

};

export default Product;
