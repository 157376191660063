import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_SPOTLIGHT_PAGE } from '../../queries';
import { Container } from '../modules/Grid';
import Loading from '../modules/Loading';
import Button from '../modules/Button';
import ChipSet from '../modules/ChipSet';
import HomeSectionHeader from '../home/HomeSectionHeader';
import FeaturedPost from '../spotlight/FeaturedPost';
import Posts from '../spotlight/Posts';
import { WidthContext } from '../../contexts/WidthContextProvider';

export const SpotlightContext = React.createContext();

const PAGE_SIZE = 16;

const Spotlight = props => {
    const { isMobile } = useContext(WidthContext);
    const [category, setCategory] = useState(null);
    const [limit, setLimit] = useState(PAGE_SIZE);

    const { data, loading } = useQuery(GET_SPOTLIGHT_PAGE, { variables: { limit } });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (loading) return <Loading />;
    const { spotlightPage, posts } = data;
    const { featuredPost, header, banner } = spotlightPage;

    const categories = _.uniqBy(posts.reduce((prev, curr) => ([...prev, ...curr.subcategories, ...curr.categories]), []), 'name');

    const handleChip = (text) => {
        if (text === 'All') {
            setCategory(null);
        } else {
            const category = categories.find(cat => cat.name === text);
            setCategory(category);
        }
    };

    const filteredPosts = category ? posts.filter(post => (
        post.subcategories.find(subcat => (subcat.id === category.id && subcat.name === category.name)) ||
        post.categories.find(cat => (cat.id === category.id && cat.name === category.name))
    )) : posts;

    return (
        <SpotlightContext.Provider value={{
        }}
        >
            <div className='Spotlight flex column flex-start'>
                <Helmet><title>PTP | Industry Spotlight</title></Helmet>

                <div className='Spotlight__banner' style={{ backgroundImage: `url(${banner.url})` }}>
                    <Container className='Vendor__header'>
                        <div className='Spotlight__banner__Header__container'>
                            <HomeSectionHeader name={header} />
                        </div>
                    </Container>
                </div>
                <Container className='Spotlight__container '>
                    <FeaturedPost post={featuredPost} />
                    <div className={`Spotlight__filters ${isMobile ? '' : 'flex'}`}>
                        <ChipSet
                            className='Spotlight__Chips'
                            small
                            onClick={handleChip}
                            chips={[{
                                text: 'All',
                                color: category ? 'transparent' : 'primary'
                            }, ...categories.map(cat => ({
                                text: cat.name,
                                color: category && category.name === cat.name ? 'primary' : 'transparent'
                            }))]}
                        />
                    </div>
                    <Posts posts={filteredPosts} loading={loading} />
                    <div className='flex center Spotlight__loadMore'>
                        {(posts.length >= limit) && <Button type='secondary' onClick={() => setLimit(limit + PAGE_SIZE)}>Load More</Button>}
                    </div>
                </Container>
            </div>
        </SpotlightContext.Provider>
    );
};

export default Spotlight;
