import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container, Row, Col } from '../modules/Grid';
import parseMarkdown from '../../utils/markdownParser';
import SearchBar from '../search/SearchBar';

const HomeHero = props => {
    const afterChange = (idx) => {
        const slide = props.slides[idx];
        if (slide && slide.image && slide.image.mime.includes('video')) {
            const videos = document.getElementsByTagName('video');
            for (const video of videos) {
                video.play();
            };
        }
    };

    const beforeChange = (idx) => {
        const slide = props.slides[idx];
        if (slide && slide.image && slide.image.mime.includes('video')) {
            const videos = document.getElementsByTagName('video');
            for (const video of videos) {
                video.pause();
            };
        }
    };

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 4500,
        autoplay: true,
        beforeChange,
        afterChange
    };

    return (
        <div className='Home__hero__Slider__Container'>
            <Slider {...settings} className='Slider'>
                {props.slides.filter(slide => slide.image).map((slide, idx) => (
                    <div className='Slider__Slide' key={slide.image.url} id={`slide-${idx}`}>
                        <div className='Home__hero__background'>

                            <div className='Home__hero__container' style={{ backgroundImage: `url("${slide.image.url}")` }}>
                                {slide.image.mime.includes('video') && (
                                    <video className='Home__hero__Slider__video'>
                                        <source src={slide.image.url} type='video/mp4' />
                                    </video>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
            <div className='Home__hero__overlay' />
            <div className='Home__hero__text__container '>
                <Container>
                    <Row>
                        <Col xs={12} md={8} className='flex column align-flex-start'>
                            <div className='Home__hero__text__padding'>
                                <div className='Home__hero__text subtext'>{parseMarkdown(props.headerDescription)}</div>
                            </div>
                            <SearchBar />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

HomeHero.propTypes = {
    slides: PropTypes.array,
    header: PropTypes.string,
    headerDescription: PropTypes.string
};

export default HomeHero;
