import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../modules/Button';
import TextField from '../modules/TextField';
import TextArea from '../modules/TextArea';
import Checkbox from '../modules/Checkbox';
import { Caption } from '../modules/Typography';
import Icon from '../modules/Icon';
import { ProductContext } from './ProductDetail';

const RelatedFile = props => {
    const { removeFile, error, errors, saving } = useContext(ProductContext);
    const { item, idx, isActive, setActiveIdx, handleValue, handleEdit } = props;
    const { title, document, name, description } = item;
    const url = document ? document.url : '#';
    const [isChecked, setIsChecked] = useState(false);

    const handleRestricted = ({ value, checked, e }) => {
        setIsChecked(checked);
        handleValue(e, idx, 'restricted');
    };

    return (
        <div className='VendorInformation__RelatedFile'>
            {!isActive ? (
                <div className='flex'>
                    <Caption>{title}
                        <Icon className='pointer padded__left' name='edit' size='small' onClick={() => handleEdit(idx)} />
                    </Caption>
                    <div>
                        <a href={url} rel='noreferrer' target='_blank'><Icon name='download' size='small' className={`Dropzone__list__icon padded__right ${document ? '' : 'disabled'}`} /></a>
                        <Icon className='pointer' name='trash-can' onClick={() => removeFile(idx, 'attachment')} />
                    </div>
                </div>
            ) : (
                <>
                    <TextField
                        className='VendorInformation__input__document'
                        value={title || name}
                        label='File Name'
                        padded={false}
                        onChange={(e) => handleValue(e, idx, 'title')}
                        rightLabel='Required'
                        error={error && errors.includes(`docTitle-${idx}`)}
                        errorMessage='Please include a file name.'
                    />
                    <Button
                        onClick={() => setActiveIdx(null)}
                        loading={saving}
                        type='primary'
                        color='default'
                        size='small'
                        className='VendorInformation__button__input'
                    >
                        Save
                    </Button>
                </>)}

            <TextArea
                className='VendorInformation__RelatedFile__description'
                value={description}
                onChange={(e) => handleValue(e, idx, 'description')}
                label='Please add a description'
                padded={false}
                size='small'
                rightLabel='Required'
                error={error && errors.includes(`docDescription-${idx}`)}
                errorMessage='Please include a description.'
            />
            <Checkbox label='Restricted Access' className='VendorInformation__checkbox' handleCheck={handleRestricted} checked={isChecked} />

        </div>

    );
};

export default RelatedFile;

RelatedFile.propTypes = {
    item: PropTypes.object,
    idx: PropTypes.number,
    handleValue: PropTypes.func,
    handleEdit: PropTypes.func,
    setActiveIdx: PropTypes.func,
    isActive: PropTypes.bool

};
