import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_POST_DETAIL } from '../../queries';
import HomeSectionHeader from '../home/HomeSectionHeader';
import { Container, Row, Col } from '../modules/Grid';
import { Overline, Heading, Subtitle, Body } from '../modules/Typography';
import Loading from '../modules/Loading';
import Paper from '../modules/Paper';
import IconButton from '../modules/IconButton';
import ChipSet from '../modules/ChipSet';
import PdfViewer from '../modules/PdfViewer';
import PostTable from '../postDetail/PostTable';
import parseMarkdown from '../../utils/markdownParser';
import getCategoryName from '../../utils/getCategoryName';
import banner from '../../images/production-tech-portal_banner.jpg';

export const PostDetailContext = React.createContext();

const PostDetail = props => {
    const { slug } = useParams();
    const { data, loading } = useQuery(GET_POST_DETAIL, { variables: { slug } });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (loading) return <Loading />;
    const post = data.posts[0];

    const { title, content, date, media = {}, categories = [], subcategories = [] } = post;

    const categoryName = getCategoryName(post);
    const catNames = [...categories.map(cat => (cat.name)), ...subcategories.map(subcat => (subcat.name))];
    return (
        <PostDetailContext.Provider value={{
        }}
        >
            <div className='Spotlight flex column flex-start'>
                <Helmet><title>{`PTP | ${post.title}`}</title></Helmet>

                <div className='Spotlight__banner' style={{ backgroundImage: `url(${banner})` }}>
                    <Container>
                        <div className='Post__header__container'>
                            <HomeSectionHeader name='Industry Spotlight' />
                        </div>
                    </Container>
                </div>
                <Container className='Post__container'>
                    <Paper elevation={4} className='Product__paper'>
                        <Row>
                            <Col sm={7} className='Post__card__content flex column align-flex-start'>
                                <div className='flex align-center Product__team'>
                                    <IconButton
                                        name='arrow-std-left'
                                        className='primary-dark-text Post__card__content__icon'
                                        link='/Industry-spotlight'
                                    />
                                    <Overline color='primary-dark'>All news</Overline>
                                </div>
                                <Overline className='Product__category' color='secondary-dark'>{categoryName}</Overline>
                                <Heading className='Product__name' number={4} heavy>{title}</Heading>
                                <Subtitle className='Product__date' number={2} fadedTwoThirds>{moment(date).format('LL')}</Subtitle>
                                {media ? (media.mime.includes('image') ? (
                                    <img className='Post__card__content__image' src={media.url} />
                                ) : (
                                    <video className='Post__card__content__image' autoPlay>
                                        <source src={media.url} type='video/mp4' />
                                    </video>
                                )) : ''}
                                {content.map(component => (
                                    component.body ? (
                                        <Body className='Post__card__content__body' number={1}>{parseMarkdown(component.body)}</Body>
                                    ) : (
                                        <PdfViewer src={component.file.url} />

                                    )
                                ))}

                            </Col>
                            <Col smOffset={1} sm={4} className='Post__card__related'>
                                <PostTable />
                                <Overline className='Post__Tags__header'>Tags</Overline>
                                <div className='Post__Tags'>
                                    <ChipSet
                                        small
                                        chips={catNames.map(cat => ({ text: cat }))}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Paper>
                </Container>
            </div>
        </PostDetailContext.Provider>
    );
};

export default PostDetail;
