import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        email
        role {
          name
        }
      }
    }
  }
`;

export const ME = gql`
  {
    user: me {
      id
      email
      role {
        name
      }
    }
  }
`;

export const GET_USER_VENDOR = gql`
  query getUserVendor($id: ID!) {
    user(id: $id, publicationState: PREVIEW) {
      vendors {
        id
        slug
        name
        products {
          name
          id
          slug
        }
      }
    }
  }
`;

export const REQUEST_INFO = gql`
  mutation sendRequest($category: String, $product: String, $userEmail: String, $sendCopy: Boolean, $details: String, $requestType: String) {
    sendRequest(
      category: $category, 
      product: $product, 
      userEmail: $userEmail, 
      sendCopy: $sendCopy, 
      details: $details, 
      requestType: $requestType
    ){
      sent
    }
  }
  `;

export const RESET_PASSWORD = gql`
  mutation resetPassword($code: String! $password: String!) {
    resetPassword(code: $code, password: $password, passwordConfirmation: $password) {
      jwt
      user {
        id
        email
        role {
          name
        }
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`;
