import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TextField from '../modules/TextField';
import { Row, Col } from '../modules/Grid';
import { ProductContext } from './ProductDetail';
import Icon from '../modules/Icon';

const ProjectApplication = props => {
    const {
        handleFile,
        handleDelete,
        error,
        errors,
        removeFile
    } = useContext(ProductContext);

    const { application, idx, handleApplication } = props;
    const { title } = application;

    return (

        <Row key={idx} className='VendorInformation__Project flex align-baseline'>
            <Col md={7}>
                <Icon className='float-right padded pointer' name='trash-can' onClick={() => removeFile(idx, 'application')} />
                <TextField
                    className='VendorInformation__input'
                    value={title}
                    label='Project Title'
                    placeholder='Name of the title or show this product was used in'
                    onChange={(e) => handleApplication(e, 'title', idx)}
                    rightLabel='Required'
                    error={error && errors.includes(`keyTitle-${idx}`)}
                    errorMessage='Please include a title.'
                />
            </Col>
        </Row>

    );
};

export default ProjectApplication;

ProjectApplication.propTypes = {
    application: PropTypes.object,
    idx: PropTypes.number,
    handleApplication: PropTypes.func
};
