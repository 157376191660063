import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { ME, GET_USER_VENDOR } from '../queries';
import { useQuery, useLazyQuery } from '@apollo/client';
import Login from '../components/pages/Login';
import Loading from '../components/modules/Loading';

export const UserContext = React.createContext();

const UserComponent = ({ children }) => {
    const [user, setUser] = useState({
        id: '',
        email: '',
        role: { name: '' },
        vendors: [],
        teams: []
    });
    const location = useLocation();
    const { data } = useQuery(ME, { errorPolicy: 'all' });
    const [getUserVendor, { data: vendorData }] = useLazyQuery(GET_USER_VENDOR);

    const me = data ? data.user : {};
    const [userCategory, setUserCategory] = useState(null);
    const [userSubcategory, setUserSubcategory] = useState(null);
    const [userTeamCategory, setUserTeamCategory] = useState(null);
    const [userTeamSubcategory, setUserTeamSubcategory] = useState(null);
    const [publicationState, setPublicationState] = useState('LIVE');

    useEffect(() => {
        if (user.id) {
            getUserVendor({ variables: { id: user.id } });
        }
    }, [user.id]);

    useEffect(() => {
        if (me?.id) {
            setUser({ ...me, vendors: [], teams: [] });
        }
    }, [me]);
    let content = <Loading />;
    if (data) {
        content = <Login />;
        if (user.id || location.pathname === '/reset-password' || location.pathname === '/forgot-password') {
            content = children;
        }
    }
    return (
        <UserContext.Provider value={{
            user,
            setUser,
            vendorData,
            userCategory,
            setUserCategory,
            userSubcategory,
            setUserSubcategory,
            userTeamCategory,
            setUserTeamCategory,
            userTeamSubcategory,
            setUserTeamSubcategory,
            publicationState,
            setPublicationState
        }}
        >
            {content}
        </UserContext.Provider>
    );
};

UserComponent.propTypes = {
    children: PropTypes.object
};

export default UserComponent;
