import React, { useState, useEffect, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useQuery } from '@apollo/client';
import { GET_NAV } from '../../queries';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { DataContext } from '../../contexts/DataContextProvider';
import { UserContext } from '../../contexts/UserContextProvider';
import { Subtitle, Heading, Body } from '../modules/Typography';
import IconButton from '../modules/IconButton';
import Switch from '../modules/Switch';
import logo from '../../images/ptp-logo.svg';
import Icon from '../modules/Icon';
import SearchBar from '../search/SearchBar';

const TopNav = props => {
    const { isMobile, size } = useContext(WidthContext);
    const { data = {} } = useQuery(GET_NAV);
    const { pathname } = useLocation();
    const isPreview = pathname.includes('product-preview') || pathname.includes('vendor-preview');
    const { setSubcategory, setCategory } = useContext(DataContext);
    const { publicationState, setPublicationState, setUser, vendorData, user } = useContext(UserContext);
    const cookies = new Cookies();
    const history = useHistory();
    const [menuOpen, setMenuOpen] = useState(false);
    const [isStuck, setStuck] = useState(isMobile);
    const links = data ? data.nav?.navLink || [] : [];
    const [isOpen, setOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const scrollHandler = () => {
        if (window.pageYOffset >= 1) {
            setStuck(true);
        } else if (!isMobile) {
            setStuck(false);
        }
    };

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler);
        if (isMobile) {
            setStuck(true);
        }
        return () => {
            document.removeEventListener('scroll', scrollHandler);
            document.body.classList.remove('no-scroll');
        };
    }, [isMobile]);

    const handleOpen = () => {
        const open = !isOpen;
        if (open) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
        setOpen(open);
    };

    let headingNumber = 4;
    if (size === 'xl' || size === 'xxl') {
        headingNumber = 2;
    } else if (size === 'lg' || size === 'md') {
        headingNumber = 3;
    } else if (size === 'xs') {
        headingNumber = 5;
    }

    const handleSignOut = () => {
        cookies.remove('access_token');
        cookies.remove('refresh_token');
        setUser({
            id: '',
            email: '',
            role: { name: '' },
            vendors: [],
            teams: []
        });
        history.push('/');
    };

    const handleProductsClick = (url, text) => {
        setCategory(null);
        setSubcategory(null);
    };

    return (
        <div className={`TopNav flex column ${isOpen ? 'open' : ''}`}>
            <div className={`TopNav__header flex ${isStuck ? 'flex-end' : ''}`}>
                {!isStuck && (
                    <Link to='/' className='TopNav__left flex column'>
                        <img src={logo} alt='Production Technology Portal' />
                    </Link>
                )}
                <div className={`TopNav__right flex ${searchOpen ? 'TopNav__right--search-open' : ''}`}>
                    {props.role === 'Admin' && (
                        <div className='flex'>
                            <Body number={2}>Show Unpublished?</Body>
                            <Switch
                                className='TopNav__Switch'
                                checked={publicationState === 'PREVIEW'}
                                onChange={(e) => setPublicationState(e.target.checked ? 'PREVIEW' : 'LIVE')}
                            />
                        </div>
                    )}
                    {!isPreview && (
                        <>
                            {isStuck ? (
                                <IconButton className='TopNav__link__icon' name={isOpen ? 'close' : 'density-comfy'} onClick={handleOpen} />
                            ) : (
                                <Fragment>
                                    {links.map(link => (link.url?.includes('http') ? (
                                        <a key={link.text} href={link.url || '/'} target='_blank' rel='noreferrer' className='TopNav__link'>
                                            <Subtitle number={2} className='TopNav__link__text'>{link.text}</Subtitle>
                                        </a>
                                    ) : (
                                        <Link key={link.text} to={link.url} onClick={() => handleProductsClick(link.url, link.text)} className='TopNav__link'>
                                            <Subtitle number={2} className='TopNav__link__text'>{link.text}</Subtitle>
                                        </Link>
                                    )))}
                                    {searchOpen ? (
                                        <SearchBar nav />
                                    ) : (
                                        <Icon name='search' className='TopNav__link TopNav__link__icon pointer' onClick={() => setSearchOpen(true)} />
                                    )}
                                    <div className='flex align-center relative'>
                                        <IconButton size='large' name='profile' className='VendorTopNav__icon client' />
                                        <Icon name='caret-down' className='VendorTopNav__menuIcon TopNav__link__icon pointer' onClick={() => setMenuOpen(!menuOpen)} />
                                        {menuOpen && (
                                            <div className='VendorTopNav__signout client pointer'>
                                                {user.role.name === 'Team' && (
                                                    <Link className='VendorTopNav__signout__option' to={`/${vendorData.user.vendors[0].slug}/vendor-home`}>
                                                        <Subtitle block className='VendorTopNav__signout__text' number={2}>Edit My Products</Subtitle>
                                                    </Link>
                                                )}
                                                <div className='VendorTopNav__signout__option flex align-baseline' onClick={handleSignOut}>
                                                    <Icon name='logout' />
                                                    <Subtitle block className='VendorTopNav__signout__text' number={2}>Logout</Subtitle>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Fragment>
                            )}
                        </>
                    )}
                </div>
            </div>
            {isOpen && (
                <div className='TopNav__openContent flex column' onClick={handleOpen}>
                    {links.map(link => (link.url?.includes('http') ? (
                        <a key={link.text} href={link.url} target='_blank' rel='noreferrer'>
                            <Heading className='TopNav__openContent__text' number={headingNumber}>{link.text}</Heading>
                        </a>
                    ) : (
                        <Fragment key={link.text}>
                            <Link key={link.text} to={link.url}>
                                <Heading className='TopNav__openContent__text' number={headingNumber}>{link.text}</Heading>
                            </Link>
                        </Fragment>

                    )))}
                    {user.role.name === 'Team' && (
                        <Link to={`/${vendorData.user.vendors[0]?.slug}/vendor-home`}>
                            <Heading className='TopNav__openContent__text' number={headingNumber}>Edit My Products</Heading>
                        </Link>
                    )}
                    <Link onClick={handleSignOut}>
                        <Heading className='TopNav__openContent__text' number={headingNumber}>Sign Out</Heading>
                    </Link>
                </div>
            )}
        </div>
    );
};

TopNav.propTypes = {
    role: PropTypes.string
};

export default TopNav;
