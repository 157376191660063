import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { SEARCH } from '../../queries';
import Loading from '../modules/Loading';
import { Heading, Body } from '../modules/Typography';
import { Container, Row, Col } from '../modules/Grid';
import SearchCheckbox from '../search/SearchCheckbox';
import SearchResults from '../search/SearchResults';
import heroImage from '../../images/vendor-showcase-banner.png';
import SearchBar from '../search/SearchBar';

export const SearchContext = React.createContext();

const typeConfig = [
    {
        value: 'all',
        text: 'All'
    },
    {
        value: 'products',
        text: 'Products'
    },
    {
        value: 'posts',
        text: 'Spotlight'
    },
    {
        value: 'vendors',
        text: 'Vendors/Teams'
    },
    {
        value: 'categories',
        text: 'Categories'
    }
];

const Search = props => {
    const location = useLocation();
    const query = location.search.split('q=')[1];
    const { data, loading } = useQuery(SEARCH, { variables: { query, types: [] } });
    const [searchOptions, setSearchOptions] = useState(typeConfig.map(type => type.value));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (loading) return <Loading />;
    const { categories, posts, products, vendors } = data.search;
    const totalResults = categories.length + posts.length + products.length + vendors.length;
    const types = typeConfig.map(type => (
        {
            ...type,
            total: type.value === 'all' ? totalResults : data.search[type.value].length
        }
    ));

    const handleCheck = ({ value, checked }) => {
        let options = [...searchOptions];
        if (value === 'all') {
            if (checked) {
                options = typeConfig.map(type => type.value);
            } else {
                options = [];
            }
        } else {
            if (checked) {
                options = [...options, value];
            } else {
                options = options.filter(option => option !== value);
            }
            options = options.filter(option => option !== 'all');
        }
        setSearchOptions(options);
    };

    return (
        <SearchContext.Provider value={{ query }}>
            <div className='Search'>
                <Helmet><title>PTP | Search</title></Helmet>
                <div className='Categories__banner Search__banner' style={{ backgroundImage: `url(${heroImage})` }}>
                    <Container>
                        <Row>
                            <Col xs={12} md={8}>
                                <SearchBar />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container>
                    <div className='Search__Content'>
                        <div className='flex'>
                            <Heading className='Search__Header' number={4}>Search Results for '{query}'</Heading>
                            <Body className='Search__total'>{totalResults} results</Body>
                        </div>
                        <div className='flex flex-start'>
                            {types.map(type => (
                                <SearchCheckbox
                                    key={type.text}
                                    checked={searchOptions.includes(type.value)}
                                    value={type.value}
                                    text={type.text}
                                    total={type.total}
                                    handleCheck={handleCheck}
                                />
                            ))}
                        </div>
                        <div>
                            {searchOptions.includes('products') && products.length ? (
                                <SearchResults
                                    title='Products'
                                    color='secondary-dark'
                                    results={products.map(product => (
                                        {
                                            thumbnail: product.thumbnail.url,
                                            type: 'PRODUCT',
                                            id: product.id,
                                            name: product.name,
                                            description: product.description,
                                            categories: product.categories,
                                            subcategories: product.subcategories,
                                            slug: product.slug,
                                            link: `vendors/${product.vendor.slug}/product/${product.slug}`
                                        }
                                    ))}
                                />
                            ) : ''}
                            {searchOptions.includes('posts') && posts.length ? (
                                <SearchResults
                                    title='Spotlight Posts'
                                    color='on-surface'
                                    results={posts.map(post => (
                                        {
                                            thumbnail: post.media ? post.media.url : '#',
                                            type: 'SPOTLIGHT POST',
                                            id: post.id,
                                            name: post.title,
                                            description: post.content.body,
                                            categories: post.categories,
                                            subcategories: post.subcategories,
                                            slug: post.slug,
                                            link: `/industry-spotlight/${post.slug}`
                                        }
                                    ))}
                                />
                            ) : ''}
                            {searchOptions.includes('vendors') && vendors.length ? (
                                <SearchResults
                                    title='Vendors/Teams'
                                    color='transparent'
                                    results={vendors.map(vendor => (
                                        {
                                            thumbnail: vendor.image.url,
                                            type: 'VENDOR',
                                            id: vendor.id,
                                            name: vendor.name,
                                            description: vendor.description,
                                            categories: [],
                                            subcategories: [],
                                            slug: vendor.slug,
                                            link: `/vendors/${vendor.slug}`
                                        }
                                    ))}
                                />
                            ) : ''}
                            {searchOptions.includes('categories') && categories.length ? (
                                <div className='Search__Results'>
                                    <Heading className='Search__Results__Title' number={5}>Categories</Heading>
                                    <div className='flex column align-flex-start'>
                                        {categories.map(category => (
                                            <Link to={`/categories/${category.slug}`} key={category.slug}>
                                                <Heading number={6} className='Search__Category' color='primary-light'>{category.name}</Heading>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            ) : ''}
                        </div>
                    </div>
                </Container>
            </div>
        </SearchContext.Provider>
    );
};

export default Search;
