import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Checkbox = props => {
    const [id] = useState(props.id || `${(props.value || 'checkbox').replace(/ /g, '-')}-${Math.floor(Math.random() * 1000)}`);

    return (
        <div className={`Checkbox ${props.inline ? 'Checkbox--inline' : ''} ${props.className || ''}`}>
            <input
                id={id}
                type='checkbox'
                className='Checkbox__input'
                checked={props.checked}
                onChange={props.handleCheck ? (e) => props.handleCheck({ value: props.value, checked: e.target.checked, e }) : null}
            />
            <label htmlFor={id} className='Checkbox__label Typography--body2'>
                <div className={`Checkbox__box ${props.checked ? 'Checkbox__box--checked' : ''}`} />
                {props.label}
            </label>
        </div>
    );
};

export default Checkbox;

Checkbox.propTypes = {

    // className
    className: PropTypes.string,
    // function to handle the checkbox change
    handleCheck: PropTypes.func,

    // whether or not the checkbox is checked
    checked: PropTypes.bool,

    // id for the checkbox to be associated to
    id: PropTypes.string,

    // label to be associated with the checkbox
    label: PropTypes.string,

    // value of the checkbox
    value: PropTypes.string,

    // whether or not the checkbox should be inline
    inline: PropTypes.bool
};

Checkbox.defaultProps = {
    checked: false,
    inline: false
};
