import React, { useContext, useEffect, useRef, useState } from 'react';
import { ProductContext } from './ProductDetail';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { Heading, Caption } from '../modules/Typography';
import TextField from '../modules/TextField';
import Dropzone from '../modules/Dropzone';
import { Container, Row, Col } from '../modules/Grid';
import Select from '../modules/Select';
import Radio from '../modules/Radio';
import ChipSet from '../modules/ChipSet';
import Icon from '../modules/Icon';

const GeneralProductInfo = props => {
    const { isMobile } = useContext(WidthContext);
    const chipRef = useRef(null);
    const [chipHeight, setChipHeight] = useState(0);
    const {
        name,
        setName,
        statuses,
        status,
        setStatus,
        error,
        errors,
        videoOption,
        media,
        categories,
        category,
        subcategories,
        selectedSubcategories,
        setSelectedSubcategories,
        thumbnail,
        videoThumbnail,
        videoUrl,
        setCategory,
        handleFile,
        setVideoUrl,
        handleDelete,
        handleRadio,
        mediaLoading
    } = useContext(ProductContext);
    useEffect(() => {
        if (chipRef.current) {
            const { height } = chipRef.current.getBoundingClientRect();
            setChipHeight(height);
        }
    }, [chipRef.current, selectedSubcategories]);

    const handleStatus = ({ value }) => {
        setStatus(value);
    };

    const handleCategory = ({ value }) => {
        setCategory(categories.find(cat => cat.id === value));
    };

    const handleDeleteCategory = () => {
        setCategory(null);
        setSelectedSubcategories([]);
    };

    const handleSubcategory = ({ value }) => {
        if (selectedSubcategories.length < 3) {
            setSelectedSubcategories([...selectedSubcategories, subcategories.find(cat => cat.id === value)]);
        }
    };

    const handleDeleteSubcategory = (chip) => {
        setSelectedSubcategories(selectedSubcategories.filter(cat => cat.name !== chip.text));
    };

    const handleURL = (value) => {
        let temp = value;
        if (value.includes('youtu')) {
            if (value.includes('=')) {
                temp = value.replace('watch?v=', 'embed/');
            }
            if (value.includes('.be')) {
                temp = value.replace('youtu.be', 'www.youtube.com/embed');
            }
        } else if (value.includes('vimeo') && !value.includes('player')) {
            temp = value.replace('vimeo.com', 'player.vimeo.com/video');
        }
        if (!temp.includes('http')) {
            temp = `https://${temp}`;
        }
        return temp;
    };

    return (
        <div>
            <div className='VendorInformation__General VendorInformation__Section'>
                {errors.length && error ? (
                    <div className='VendorInformation__ErrorIndicator flex flex-start'>
                        <Icon name='warning' className='VendorInformation__ErrorIndicator__Icon  error-text' />
                        <Caption error>Errors on page</Caption>
                    </div>
                ) : ''}
                <Container>
                    <Heading number={6}>General</Heading>
                    <Row className='spacing-md'>
                        <Col md={9}>
                            <TextField
                                className='VendorInformation__input'
                                value={name.replace('_PREVIEW', '')}
                                label='Product Name'
                                onChange={e => setName(`${e.target.value}_PREVIEW`)}
                                error={error && errors.includes('name')}
                                errorMessage='Please include a name.'
                                rightLabel='Required'
                            />
                        </Col>
                        <Col md={3}>
                            <Select
                                options={statuses}
                                selectedValue={status}
                                onChange={handleStatus}
                                label='Product Status'
                                rightLabel='Required'
                            />
                        </Col>
                    </Row>
                    <Row className='spacing-md'>
                        <Col md={isMobile ? 10 : 6} className='relative'>
                            <Select
                                padded={false}
                                listIcon='tag'
                                options={categories.map(cat => ({ text: cat.name, value: cat.id }))}
                                selectedValue={category ? category.id : ''}
                                onChange={handleCategory}
                                label='Category'
                                placeholder={category ? '' : 'Search Categories'}
                                hideText
                                rightLabel='Select One (Required)'
                                error={error && errors.includes('category')}
                                errorMessage='Please include a category.'
                            />

                            {category && (
                                <ChipSet
                                    chips={[{ text: category.name }]}
                                    small
                                    editing
                                    handleDelete={handleDeleteCategory}
                                    className='VendorInformation__chip'
                                />
                            )}
                            <p className='VendorInformation__selectText'>If desired category or subcategory doesn't exist, please select the closest match and <a className='Typography--color-info' href='mailto:kimberley.belenzon@disney.com'>contact PTP</a> to request a new category.</p>
                        </Col>
                        <Col md={isMobile ? 10 : 6}>
                            <Select
                                disabled={!category}
                                padded={false}
                                options={subcategories.map(cat => ({ text: cat.name, value: cat.id }))}
                                listIcon='tag'
                                selectedValue={selectedSubcategories.length && subcategories.length ? subcategories[0].id : ''}
                                onChange={handleSubcategory}
                                label='Subcategory'
                                placeholder={
                                    selectedSubcategories.length ? ''
                                        : (category ? 'Search Subcategories' : 'Please select a category first.')
                                }
                                hideText
                                rightLabel='Select Up to Three'
                                error={error && errors.includes('subcategories')}
                                errorMessage='Please include a subcategory.'
                                className={chipHeight > 31 ? 'Select--twoLine' : ''}
                                tooltip='If subcategory doesn"t exist, please select a category and contact MPI to submit a new subcategory'

                            />
                            <div ref={chipRef} className='VendorInformation__chip'>
                                <ChipSet
                                    chips={selectedSubcategories.map(cat => ({ text: cat.name }))}
                                    small
                                    editing
                                    handleDelete={handleDeleteSubcategory}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div className='flex'>
                                <Caption className='spacing-md'>Thumbnail for Product Preview</Caption>
                                <Caption className='spacing-md TextField__rightLabel'>Required</Caption>
                            </div>
                            <Dropzone
                                thumbnailSrc={thumbnail.src}
                                className='VendorInformation__thumbnail__dropzone'
                                handleFile={(file) => handleFile(file, 'thumbnail')}
                                filename={thumbnail.name}
                                handleDelete={() => handleDelete('thumbnail')}
                                fileTypes={['.jpg', '.png']}
                                finePrint='High Resolution Image'
                                error={error && errors.includes('thumbnail')}
                                errorMessage='Please include a thumbnail.'
                                maxFiles={1}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5}>
                            <Caption block className='spacing-md'>Hero Video (Required)</Caption>

                        </Col>
                    </Row>
                    <Row className='spacing-md'>
                        <Col md={8}>
                            <div className='flex flex-start'>
                                <Radio
                                    className='VendorInformation__url-radio'
                                    value='url'
                                    inline
                                    handleChange={value => handleRadio(value)}
                                    selected={videoOption === 'url'}
                                />
                                <TextField
                                    className='VendorInformation__input__video'
                                    value={videoUrl}
                                    label='Video URL'
                                    onChange={e => setVideoUrl(handleURL(e.target.value))}
                                    disabled={videoOption === 'upload'}
                                    error={error && errors.includes('video')}
                                    errorMessage='Please include a thumbnail or video.'
                                />
                            </div>
                            <Caption block>or</Caption>
                            <div className='flex flex-start align-flex-start'>
                                <Radio
                                    inline
                                    className='VendorInformation__upload-radio'
                                    value='upload'
                                    handleChange={value => handleRadio(value)}
                                    selected={videoOption === 'upload'}
                                />
                                <div className='VendorInformation__media'>
                                    <Caption className='spacing-sm'>Upload Video</Caption>
                                    <Dropzone
                                        thumbnailSrc={media.src}
                                        icon='film'
                                        className='VendorInformation__attachments__dropzone'
                                        handleFile={(file) => handleFile(file, 'video')}
                                        filename={media.name}
                                        handleDelete={() => handleDelete('video')}
                                        isVideoDoc
                                        fileTypes={['.mpeg', '.mp4', '.mov', '.pdf']}
                                        finePrint='Maximum Size: 200MB'
                                        disabled={videoOption === 'url'}
                                        error={error && errors.includes('video')}
                                        maxFiles={1}
                                        errorMessage='Please include a video or PDF.'
                                        loading={mediaLoading}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <Caption block>Video Thumbnail</Caption>
                            <Dropzone
                                thumbnailSrc={videoThumbnail.src}
                                className='VendorInformation__video-thumbnail__dropzone'
                                handleFile={(file) => handleFile(file, 'videoThumbnail')}
                                filename={videoThumbnail.name}
                                handleDelete={() => handleDelete('videoThumbnail')}
                                maxFiles={1}
                                fileTypes={['.jpg', '.png']}
                                finePrint='High Resolution Image, 16:9'
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

GeneralProductInfo.propTypes = {

};

export default GeneralProductInfo;
