import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '../modules/Paper';
import Icon from '../modules/Icon';
import _ from 'lodash';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { Overline, Heading, Subtitle, Body, Caption } from '../modules/Typography';

const ProductCard = props => {
    const { size } = useContext(WidthContext);
    const { pathname } = useLocation();
    const { thumbnail, name, categories, vendor, subcategories } = props.product;
    const isUnpublished = !props.product.published_at;
    const tags = categories && categories.length ? categories : _.uniqBy(subcategories.map((item) => {
        return item;
    }), 'name');
    return (
        <Paper className='Categories__ProductCard'>
            {isUnpublished && <Overline number={2} className='Categories__ProductCard__unpublished error-text'>Unpublished</Overline>}
            <div className='Categories__ProductCard__image' style={{ backgroundImage: `url(${thumbnail ? thumbnail.url : ''})` }} />
            <div className='Categories__ProductCard__details flex column align-flex-start'>
                <div className='flex column align-flex-start'>
                    {/* <Overline className='Categories__ProductCard__details__category' color='secondary-light'>PRODUCT</Overline> */}
                    {size !== 'xl' && size !== 'xxl' ? (
                        <Subtitle className='Categories__ProductCard__details__name' number={1}>{name}</Subtitle>
                    ) : (
                        <Heading className='Categories__ProductCard__details__name' number={6}>{name}</Heading>
                    )}
                    {!pathname.includes('vendor') &&
                    <>
                        {size !== 'xl' && size !== 'xxl' ? (
                            <Subtitle className='Categories__ProductCard__details__name' number={1}>From {vendor.name}</Subtitle>
                        ) : (
                            <Body className='Categories__ProductCard__details__name' number={2}>From {vendor.name}</Body>
                        )}
                    </>}
                </div>
                {tags && tags.length > 0 &&
                <>
                    <span><Icon size='small' name='tags' />
                        {tags.map((tag, i) => (
                            <Caption number={2} key={i}>{tags.length > 1 ? i === 0 ? ' ' : ', ' : ' '}
                                {tag.name}
                            </Caption>
                        ))}
                    </span>

                </>}
            </div>
        </Paper>
    );
};

ProductCard.propTypes = {
    product: PropTypes.object
};

export default ProductCard;
