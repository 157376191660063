import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import {
    GET_SUBCATEGORY_VENDOR_PRODUCTS,
    GET_CATEGORY_VENDOR_PRODUCTS
} from '../../queries';
import { Row, Col } from '../modules/Grid';
import { Caption, Subtitle } from '../modules/Typography';
import ProductCard from './ProductCard';
import Loading from '../modules/Loading';
import IconButton from '../modules/IconButton';
import Select from '../modules/Select';
import { CategoryContext } from '../pages/Categories';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { UserContext } from '../../contexts/UserContextProvider';
import { DataContext } from '../../contexts/DataContextProvider';

const CategoryProducts = props => {
    const [selectedVendor, setVendor] = useState('all');
    const { selectedCategory, subcategory } = useContext(DataContext);
    const { listOpen, setOpen } = useContext(CategoryContext);
    const { isMobile } = useContext(WidthContext);
    const { publicationState } = useContext(UserContext);
    const query = subcategory.includes('all') ? GET_CATEGORY_VENDOR_PRODUCTS : GET_SUBCATEGORY_VENDOR_PRODUCTS;
    const id = subcategory.includes('all') ? selectedCategory : subcategory;
    const { data, loading } = useQuery(query, { variables: { id, publicationState } });

    useEffect(() => {
        setVendor('all');
    }, [selectedCategory, subcategory]);

    if (loading) return <Loading />;

    let products = [];
    let subcategoryName = 'All';
    let categoryName = '';
    if (data.subcategory) {
        products = data.subcategory.products;
        subcategoryName = data.subcategory.name;
        categoryName = data.subcategory.category.name;
    } else if (data.category.subcategories.length) {
        products = data.category.subcategories.reduce((prev, curr) => {
            return _.uniqBy([...prev, ...curr.products], 'id');
        }, products);
        categoryName = data.category.name;
    } else {
        products = data.category.products;
        categoryName = data.category.name;
    }
    let owners = [];
    products = products.filter(product => product.vendor);
    owners = _.uniqBy(products.map(prod => ({ text: prod.vendor.name, value: prod.vendor.id })), 'value');
    if (selectedVendor !== 'all') {
        products = products.filter(product => product.vendor.id === selectedVendor);
    }

    const sortByName = (a, b) => {
        const productA = a.name;
        const productB = b.name;
        if (productA > productB) {
            return 1;
        }
        return -1;
    };
    const sortByText = (a, b) => {
        const productA = a.text;
        const productB = b.text;
        if (productA > productB) {
            return 1;
        }
        return -1;
    };
    products = products.filter(product => !product.original).sort(sortByName);

    return (
        <div className='relative'>
            {isMobile ? '' : (
                <IconButton
                    name={listOpen ? 'caret-left' : 'caret-right'}
                    size='small'
                    className='Categories__List__icon'
                    onClick={() => setOpen(!listOpen)}
                />
            )}
            <div className='Categories__Product__Grid'>
                <div className='flex align-center'>
                    {!isMobile ? '' : (
                        <IconButton
                            name='gallery-view'
                            className='Categories__button'
                            onClick={() => setOpen(!listOpen)}
                        />
                    )}
                    <div>
                        <Subtitle className='Categories__Breadcrumbs' number={3}>{`${categoryName} / `}</Subtitle>
                        <Subtitle number={3} heavy>{subcategoryName}</Subtitle>
                    </div>
                </div>
                <div className='Categories__Product__content flex'>
                    <div className='flex'>
                        <Select
                            options={[{ text: 'All Vendors', value: 'all' }, ...owners.sort(sortByText)]}
                            selectedValue={selectedVendor}
                            onChange={({ value }) => setVendor(value)}
                            className='Categories__Product__select'
                        />
                    </div>
                    <Caption className='gray-text' number={1}>{`${products && products.length} result${products && products.length === 1 ? '' : 's'}`}</Caption>
                </div>
                <Row>
                    {products.map(product => (
                        <Col xs={6} sm={listOpen ? 6 : 4} lg={listOpen ? 4 : 3} key={product.id}>
                            <Link to={`/vendors/${product.vendor.slug}/product/${product.slug}`}>
                                <ProductCard product={product} subcategoryId={subcategory} categoryName={categoryName} />
                            </Link>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
};

export default CategoryProducts;
