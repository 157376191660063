import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import TextField from '../modules/TextField';

const SearchBar = props => {
    const history = useHistory();
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (location.pathname === '/search') {
            setSearchTerm(location.search.slice(3));
        }
    }, [location.pathname, location.search]);

    const handleSubmit = () => {
        history.push(`/search?q=${searchTerm}`);
    };

    return (
        <TextField
            className={props.nav ? 'TopNav__search' : 'Home__hero__search'}
            placeholder='Search'
            icon={!props.nav && 'search'}
            leadingIcon={props.nav && 'search'}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            onSubmit={handleSubmit}
        >
            <div className='Home__hero__search__overlay' />
        </TextField>
    );
};

export default SearchBar;

SearchBar.propTypes = {
    nav: PropTypes.bool
};
