import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Overline, Subtitle, Caption } from '../modules/Typography';
import getCategoryName from '../../utils/getCategoryName';

const PostCell = props => {
    const { title, date, slug } = props.post;
    const categoryName = getCategoryName(props.post);
    const media = props.post.media || {};

    return (
        <Link to={`/industry-spotlight/${slug}`}>
            <div className='PostCell flex flex-start align-flex-start'>
                {!media.mime || media.mime.includes('image') ? (
                    <img className='PostCell__image' src={media.url || '#'} />
                ) : (
                    <video className='PostCell__image' autoPlay>
                        <source src={media.url} type='video/mp4' />
                    </video>
                )}
                <div className='PostCell__details flex column align-flex-start space-between'>
                    <Overline color='secondary-light'>{categoryName}</Overline>
                    <Subtitle number={1} className='PostCell__title'>{title}</Subtitle>
                    <Caption number={1} fadedTwoThirds className='PostCell__date'>{moment(date).format('LL')}</Caption>
                </div>
            </div>
        </Link>
    );
};

PostCell.propTypes = {
    post: PropTypes.object
};

export default PostCell;
