import React, { useRef, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { Heading, Body, Caption } from '../modules/Typography';
import rightArrow from '../../images/scroll.svg';
import leftArrow from '../../images/scroll-1.svg';
import parseMarkdown from '../../utils/markdownParser';
import Icon from '../modules/Icon';

const ProductSlider = props => {
    const { isMobile } = useContext(WidthContext);

    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        arrows: false,
        centerPadding: '16px',
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        speed: 250
    };

    const handlePrevious = () => {
        sliderRef.current.slickPrev();
    };

    const handleNext = () => {
        sliderRef.current.slickNext();
    };

    return (
        <>
            <div className='Home__ProductSlider'>
                <img className='Home__ProductSlider__arrow big button left' src={leftArrow} onClick={handlePrevious} />
                <Slider {...settings} className='Home__ProductSlider__Slider' ref={sliderRef}>
                    {props.slides?.slice(0, 12).map((item, idx) => (

                        <div className='Home__ProductSlider__Slide Featured' key={`${item.id}-${idx}`}>
                            <Link to={`/vendors/${item.vendor.slug}/product/${item.slug}`}>

                                <div className='Home__ProductSlider__ProductCard flex column align-flex-start'>
                                    <div className='Home__ProductSlider__ProductCard__image' style={{ backgroundImage: `url("${item.thumbnail ? item.thumbnail.url : '#'}` }} />
                                    <div className='center-line' />
                                    <div className={`Home__ProductSlider__ProductCard__text ${isMobile ? 'flex column align-flex-start' : ''}`}>
                                        <div>
                                            <Heading block className='Home__ProductSlider__ProductCard__name' number={4} heavy>{item.name}</Heading>
                                            <Caption block className='Home__ProductSlider__ProductCard__vendor' number={1}>From {item.vendor.name}</Caption>
                                            <Body className='Home__ProductSlider__ProductCard__description' number={1}>{parseMarkdown(item.description && item.description.length > 150 ? item.description.slice(0, 150).concat('...') : item.description)}</Body>
                                        </div>
                                        <div>
                                            <div className='Home__ProductSlider__ProductCard__title'>
                                                {item.categories.length !== 0 &&
                                                <>
                                                    <Caption><Icon name='tag' /></Caption> {item.categories.map((cat, i) => (
                                                        <Fragment key={cat.name}>
                                                            <Caption>{item.categories.length > 1 ? i === 0 ? '' : ', ' : ''}
                                                                {cat.name}
                                                            </Caption>
                                                        </Fragment>))}<br />
                                                </>}
                                            </div>
                                            <div className='Home__ProductSlider__ProductCard__link flex flex-start' to={`/vendors/${item?.vendor?.slug}/product/${item?.slug}`}>View Product<Icon className='Home__ProductSlider__ProductCard__link__icon' name='arrow-long-right' /></div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </Slider>
                <img className='Home__ProductSlider__arrow big button right' src={rightArrow} onClick={handleNext} />
            </div>

        </>
    );
};

ProductSlider.propTypes = {
    slides: PropTypes.object,
    side: PropTypes.string
};

export default ProductSlider;
