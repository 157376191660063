import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../modules/Checkbox';
import { Heading } from '../modules/Typography';

const SearchCheckbox = props => {
    return (
        <div className='Search__Checkbox flex align-baseline'>
            <Checkbox checked={props.checked} handleCheck={props.handleCheck} value={props.value} />
            <Heading number={5} className='Search__Checkbox__text'>{props.text} ({props.total})</Heading>
        </div>
    );
};

export default SearchCheckbox;

SearchCheckbox.propTypes = {
    checked: PropTypes.bool,
    handleCheck: PropTypes.func,
    text: PropTypes.string,
    value: PropTypes.string,
    total: PropTypes.number
};
