import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { GET_FOOTER, REQUEST_INFO } from '../../queries';
import { UserContext } from '../../contexts/UserContextProvider';
import logo from '../../images/ptp-logo-white.svg';
import { Caption, Body } from '../modules/Typography';
import Button from '../modules/Button';
import DialogWrapper from '../modules/DialogWrapper';
import Select from '../modules/Select';
import TextArea from '../modules/TextArea';
import Checkbox from '../modules/Checkbox';

const Footer = props => {
    const { data } = useQuery(GET_FOOTER);
    const [sendRequest] = useMutation(REQUEST_INFO);
    const links = data && data.footer ? data.footer.footerLink : [];
    const { user } = useContext(UserContext);

    const requestOptions =
        [
            {
                text: 'Request an additional vendor/product',
                value: 'product',
                selectedText: 'Request an additional vendor/product'
            },
            {
                text: 'Didn’t find what I was looking for',
                value: 'search',
                selectedText: 'Didn’t find what I was looking for'
            },
            {
                text: 'Experiencing issues with the site',
                value: 'bug',
                selectedText: 'Experiencing issues with the site'
            },
            {
                text: 'Other',
                value: 'other',
                selectedText: 'Other'
            }
        ];

    const [isModalOpen, openModal] = useState(false);
    const [requestType, setRequestType] = useState('');
    const [details, setDetails] = useState('');
    const [sendCopy, setSendCopy] = useState(false);

    const handleRequestType = e => {
        setRequestType(e.value);
    };

    const handleSend = async () => {
        const requestText = requestOptions.find(opt => requestType === opt.value).selectedText;

        const variables = {
            requestType: requestText,
            sendCopy,
            details,
            userEmail: user.email
        };
        await sendRequest({
            variables
        });
        openModal(false);
        setDetails('');
    };

    return (
        <div className='Footer'>
            <div className='Footer__content flex align-center'>
                <div className='Footer__left'>
                    <Link className='Footer__logo__link' to='/'>
                        <img className='Footer__logo' src={logo} alt='Production Technology Portal' />
                    </Link>
                </div>
                {links.map(link => (link.url.includes('http') ? (
                    <a key={link.text} href={link.url} target='_blank' rel='noreferrer' className='Footer__link'>
                        <Body block number={2}>{link.text}</Body>
                    </a>
                ) : (
                    <Link key={link.text} to={link.url} className='Footer__link'>
                        <Body block className='Footer__link__text' number={2}>{link.text}</Body>
                    </Link>

                )))}
                <div className='Footer__right'>
                    <Body><em>Didn't find what you're looking for?</em></Body>
                    <Button
                        type='primary'
                        className='Footer__button pointer'
                        onClick={() => openModal(true)}
                    >
                        Contact Us
                    </Button>
                </div>

            </div>
            <DialogWrapper
                open={isModalOpen}
                title='Request Information'
                handleCancel={() => openModal(false)}
                handleConfirm={handleSend}
                confirmText='Send'
                disabled={!requestType || !details}
                className='Footer__Dialog'

            >
                <div className='spacing-sm'>
                    <div className='DialogWrapper__fullWidth'>
                        <Select
                            options={requestOptions}
                            selectedValue={requestType}
                            onChange={handleRequestType}
                            placeholder='Please select one'
                            label='Request Type'
                            rightLabel='Required'
                            fullWidth
                        />
                    </div>
                    <div className='DialogWrapper__fullWidth'>
                        <TextArea
                            className='flex align-center'
                            onChange={e => setDetails(e.target.value)}
                            label='Details'
                            rightLabel='Required'
                            value={details}
                        />
                    </div>
                </div>
                <Checkbox label='Send me a copy' className='TextArea__modalMargin' checked={sendCopy} handleCheck={({ checked }) => setSendCopy(checked)} />
            </DialogWrapper>
            <div className='Footer__divider' />
            <div className='Footer__bottom'>
                <Caption className='Footer__finePrint'>© Copyright - The Walt Disney Studios - Studio Technology - Production Technology Portal</Caption>
            </div>
        </div>
    );
};

export default Footer;
