import { gql } from '@apollo/client';

export const GET_HOME_PAGE = gql`
	query home {
		homePage {
			slides {
				image {
					url
					mime
				}
			}
			headerDescription
			productContainer {
				product {
					id
					name
					slug
					vendor{
						id
						name
						slug
					}
					description
					thumbnail {
						id
						url
					}
					categories {
						id
						name
					}
				}
			}
		}
		posts (sort: "date:desc"){
        id
        title
        date
        slug
        categories {
          id
          name
        }
        subcategories {
          id
          name
          category {
            id
            name
          }
        }
        media {
          url
          mime
        }
      }
	}
`;

export const UPDATE_HOME = gql`
	mutation updateHome($response: String) {
		updateHome(response: $response) {
			response
		}
	}
`;
