import React from 'react';
import Icon from './Icon';
import { Body } from './Typography';
import PropTypes from 'prop-types';

const ChipSet = props => {
    const handleClick = (text) => {
        if (props.onClick) {
            props.onClick(text);
        }
    };
    return (
        <div className={`ChipSet ${props.vertical ? 'vertical' : ''}${props.className || ''} `}>
            {props.chips.map((chip, index) => (
                <div
                    className={`
                ChipSet__chip 
                ${props.editing ? 'ChipSet__chip--editing' : ''} 
                ${props.small ? 'ChipSet__chip--small' : ''} 
                ChipSet__chip--${chip.color || 'transparent'}
                ${props.onClick ? 'button' : ''} 
                
            `} key={`${chip.text}-${index}`}
                    onClick={() => handleClick(chip.text)}
                >
                    <Body number={2} className='ChipSet__chip__text'>{chip.text}</Body>
                    {props.editing && props.handleDelete && (
                        <button className='ChipSet__chip__delete' onClick={() => props.handleDelete(chip)}>
                            <Icon name='close-round' className='ChipSet__chip__delete__icon' />
                        </button>
                    )}
                </div>
            ))}

        </div>
    );
};

export default ChipSet;

ChipSet.propTypes = {
    // chips to iterate over
    chips: PropTypes.arrayOf(PropTypes.object),

    // whether or not the chips are in editing mode
    editing: PropTypes.bool,

    // small version
    small: PropTypes.bool,

    // vertical version
    vertical: PropTypes.bool,

    // function to handle the deletion of a chip
    handleDelete: PropTypes.func,
    className: PropTypes.string,

    onClick: PropTypes.func
};

ChipSet.defaultProps = {
    chips: [],
    editing: false,
    vertical: false,
    small: false
};
