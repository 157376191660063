import React, { useContext } from 'react';
import { ProductContext } from './ProductDetail';
import { Heading, Caption, Body } from '../modules/Typography';
import TextArea from '../modules/TextArea';
import { Container, Row, Col } from '../modules/Grid';
import Icon from '../modules/Icon';

const ProductInfo = props => {
    const {
        details,
        setDetails,
        description,
        setDescription,
        elevatorPitch,
        setElevatorPitch,
        error,
        errors
    } = useContext(ProductContext);
    return (
        <div>
            <div className='VendorInformation__Section'>
                <Container>
                    <Heading number={6}>Product Description</Heading>
                    <Row>
                        <Col md={8}>
                            <div className='flex'>
                                <Caption block className='spacing-md'>Elevator Pitch</Caption>
                                <Caption block className='spacing-md TextField__rightLabel'>Required</Caption>
                            </div>
                            <TextArea
                                wysiwyg
                                className='VendorInformation__description'
                                value={elevatorPitch || ''}
                                characterLimit={57}
                                onChange={setElevatorPitch}
                                error={error && errors.includes('pitch')}
                                errorMessage='Please include an elevator pitch.'
                            />
                        </Col>
                        <Col md={4} className='spacing-md'>
                            <div className='flex align-flex-start'>
                                <Icon name='info' className='VendorInformation__InfoIcon' />
                                <div>
                                    <Body number={2} block>Elevator Pitch</Body>
                                    <Body color='gray' number={2}>This is the first line users will see about your product, describe what your product does/is in a concise way. Character limit is 50 characters.</Body>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <div className='flex'>
                                <Caption block className='spacing-md'>Overview</Caption>
                                <Caption block className='spacing-md TextField__rightLabel'>Required</Caption>
                            </div>
                            <TextArea
                                wysiwyg
                                className='VendorInformation__description'
                                value={description}
                                characterLimit={2000}
                                onChange={setDescription}
                                error={error && errors.includes('description')}
                                errorMessage='Please include an overview of your product.'
                            />
                        </Col>
                        <Col md={4} className='spacing-md'>
                            <div className='flex align-flex-start'>
                                <Icon name='info' className='VendorInformation__InfoIcon' />
                                <div>
                                    <Body number={2} block>Product Overview</Body>
                                    <Body color='gray' number={2}>This is the first paragraph users will see about your product, describe what your product does/is in a concise way. Think of this section as your sales pitch. Character limit is 2000 characters.</Body>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Caption block className='spacing-md'>Detailed Description</Caption>

                            <TextArea
                                wysiwyg
                                className='VendorInformation__description'
                                value={details}
                                onChange={setDetails}
                                error={error && errors.includes('details')}
                                errorMessage='Please include a description.'
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default ProductInfo;
