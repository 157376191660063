import React, { useContext } from 'react';
import { ProductContext } from './ProductDetail';
import { Heading, Caption } from '../modules/Typography';
import TextArea from '../modules/TextArea';
import { Container, Row, Col } from '../modules/Grid';

const Constraints = props => {
    const {
        constraints,
        setConstraints
    } = useContext(ProductContext);

    return (
        <div>
            <div className='VendorInformation__Section'>
                <Container>
                    <Heading number={6}>Guidelines</Heading>

                    <Row>
                        <Col md={12}>
                            <Caption block className='spacing-md'>Product Requirements and Constraints</Caption>
                            <TextArea
                                wysiwyg
                                className='VendorInformation__description'
                                value={constraints}
                                characterLimit={500}
                                onChange={setConstraints}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Constraints;

// onChange={e => setConstraints(e.target.value)}
