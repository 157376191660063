import React, { useEffect, useCallback, useState } from 'react';
import { Body } from './Typography';
import PropTypes from 'prop-types';
import Icon from './Icon';
import IconButton from './IconButton';
import Button from './Button';

const Snackbar = props => {
    const { open, handleClose, closeDelay } = props;
    const [closeTimeout, setCloseTimeout] = useState();

    const handleTimeout = useCallback(() => {
        if (!closeDelay) {
            if (closeTimeout) clearInterval(closeTimeout);
            return;
        };

        if (open) {
            if (closeTimeout) clearTimeout(closeTimeout);
            if (!props.closable) {
                const newTimeout = setTimeout(() => {
                    handleClose();
                }, closeDelay);
                setCloseTimeout(newTimeout);
            }
        } else if (!open && closeTimeout) {
            clearTimeout(closeTimeout);
            setCloseTimeout(null);
        }
    }, [open, handleClose, closeDelay, closeTimeout]);
    useEffect(handleTimeout, [open]);

    return (
        <div className={`Snackbar__wrapper Snackbar__wrapper--${props.verticalAlign} Snackbar__wrapper--${props.horizontalAlign} ${props.open ? 'Snackbar__wrapper--open' : ''} `}>
            <div className={`Snackbar  ${props.fullScreen ? 'Snackbar__wrapper--full-screen' : ''}`}>
                <div className={`Snackbar__color Snackbar__color--${props.type}`} />
                {props.type === 'success' && <Icon name='check-circle' className='Snackbar__icon Snackbar__icon--success' />}
                {props.type === 'error' && <Icon name='warning-round' className='Snackbar__icon Snackbar__icon--error' />}
                {props.type === 'info' && <Icon name='info' className='Snackbar__icon Snackbar__icon--info' />}
                {props.type === 'warning' && <Icon name='warning' className='Snackbar__icon Snackbar__icon--warning' />}
                <Body number={2} className='Snackbar__text'>{props.text}</Body>
                <div className='Snackbar__close__wrapper'>
                    {!props.closeButton && <IconButton onClick={props.handleClose} name='close' className='Snackbar__close' type='light' size='small' />}
                </div>
                {props.handleButton && props.buttonText && <Button text={props.buttonText} onClick={props.handleClick} className='Snackbar__button Snackbar__Button--confirm' color='primary-light' />}
                {props.closeButton && props.cancelText && <Button text={props.cancelText} onClick={props.handleClose} className='Snackbar__button' color='primary-light' />}
            </div>
        </div>
    );
};

export default Snackbar;

Snackbar.propTypes = {
    // text to display in the snackbar
    text: PropTypes.string,

    // whether or not the snackbar is open
    open: PropTypes.bool.isRequired,
    fullScreen: PropTypes.bool.isRequired,

    // positioning of the snackbar
    verticalAlign: PropTypes.oneOf(['top', 'bottom']),
    horizontalAlign: PropTypes.oneOf(['left', 'center', 'right']),

    // type/color of the snackbar
    type: PropTypes.oneOf(['default', 'error', 'success', 'info', 'warning']),

    // function to handle closing the snackbar
    handleClose: PropTypes.func,

    // function to handle the button click
    handleButton: PropTypes.func,

    // text for the button
    buttonText: PropTypes.string,

    // how long to wait before snackbar automatically closes
    closeDelay: PropTypes.number,

    closable: PropTypes.bool,

    closeButton: PropTypes.bool,

    handleClick: PropTypes.function,

    cancelText: PropTypes.string

};

Snackbar.defaultProps = {
    open: false,
    fullScreen: false,
    verticalAlign: 'bottom',
    horizontalAlign: 'center',
    type: 'default',
    closeDelay: 3000
};
