import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import VendorCards from '../vendors/VendorCards';
import { Container } from '../modules/Grid';
import { Caption } from '../modules/Typography';
import Select from '../modules/Select';
import Breadcrumbs from '../modules/Breadcrumbs';
import heroImage from '../../images/production-tech-portal_banner.jpg';
import HomeSectionHeader from '../home/HomeSectionHeader';

export const VendorsContext = React.createContext();

const Vendors = props => {
    const { pathname } = useLocation();
    const isVendor = pathname.includes('vendors');
    const [sortBy, setSortBy] = useState('mostRecent');
    const [sort, setSort] = useState('created_at:desc');
    const [vendorCount, setVendorCount] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSelect = ({ value }) => {
        if (value === 'a-z') {
            setSort('name:asc');
        } else if (value === 'z-a') {
            setSort('name:desc');
        } else if (value === 'mostRecent') {
            setSort('created_at:desc');
        }
        setSortBy(value);
    };

    return (
        <VendorsContext.Provider value={{
            setVendorCount,
            isVendor
        }}
        >
            <div className='Categories flex column flex-start'>
                <Helmet><title>{`PTP | All ${isVendor ? 'Vendors' : 'Teams'}`}</title></Helmet>
                <div className='Categories__banner' style={{ backgroundImage: `url(${heroImage})` }}>
                    <Container className='Vendor__header'>
                        <div className='Vendor__logo__container flex column align-flex-start'>
                            <Breadcrumbs className='vendor-view' breadcrumbs={[{ text: 'Home', link: '/' }]} />
                            <HomeSectionHeader name='All Vendors' className='' />
                        </div>
                    </Container>
                </div>
                <Container className='Categories__container '>
                    <div className='flex space-between'>
                        <Select
                            label='Sort By'
                            options={[
                                { text: 'Most Recent', value: 'mostRecent' },
                                { text: 'A-Z', value: 'a-z' },
                                { text: 'Z-A', value: 'z-a' }
                            ]}
                            selectedValue={sortBy}
                            onChange={handleSelect}
                            className='Vendors__sort__select flex align-center'
                        />
                        <Caption className='gray-text' number={1}>{`${vendorCount} result${vendorCount === 1 ? '' : 's'}`}</Caption>

                    </div>
                    <VendorCards sort={sort} />
                </Container>

            </div>
        </VendorsContext.Provider>
    );
};

export default Vendors;
