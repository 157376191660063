import { gql } from '@apollo/client';

export const GET_NAV = gql`
  query getNav {
  nav {
    navLink {
      text
      url
    }
  }
}
`;

export const GET_FOOTER = gql`
  query getFooter {
  footer {
    footerLink {
      text
      url
    }
  }
}
`;
