import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Overline, Caption, Body } from '../modules/Typography';
import getCategoryName from '../../utils/getCategoryName';
import Icon from '../modules/Icon';

const FeaturedPosts = props => {
    return (
        <div className='flex  flex-wrap'>
            {props.posts.map(post => {
                const catNames = [...post.categories.map(cat => (cat.name)), ...post.subcategories.map(subcat => (subcat.name))].join(', ');
                const categoryName = getCategoryName(post);
                const media = post.media || {};

                return (
                    <Link to={`/industry-spotlight/${post.slug}`} key={post.id} className='Home__FeaturedPost__Link'>
                        <div className='Home__FeaturedPost'>
                            {!media.mime || media.mime.includes('image') ? (
                                <div className='Home__FeaturedPost__image' style={{ backgroundImage: `url("${media.url || '#'}")` }} alt={post.title} />
                            ) : (
                                <video className='Home__FeaturedPost__image' autoPlay>
                                    <source src={media.url} type='video/mp4' />
                                </video>
                            )}

                            <div className='Home__FeaturedPost__copy flex column align-flex-start'>
                                <div>
                                    <Overline className='Home__FeaturedPost__category' color='primary-light'>{categoryName}</Overline>
                                    <Body number={1} className='Home__FeaturedPost__title'>{post.title}</Body>
                                    <Caption className='Home__FeaturedPost__date' number={1} fadedTwoThirds>{moment(post.date).format('LL')}</Caption>
                                </div>
                                {catNames.length ? (
                                    <div className='Home__FeaturedPost__tags flex'>
                                        <Caption><Icon name='tag' className='Home__FeaturedPost__tag' /></Caption>
                                        <Caption>{catNames}</Caption>
                                    </div>
                                ) : ''}

                            </div>
                        </div>
                    </Link>
                );
            })}
        </div>
    );
};

FeaturedPosts.propTypes = {
    posts: PropTypes.array
};

export default FeaturedPosts;
