import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useMutation } from '@apollo/client';
import { Row, Col } from '../modules/Grid';
import logoImage from '../../images/studioTechLogin.png';
import { Heading, Caption, Body } from '../modules/Typography';
import Button from '../modules/Button';
import LoginMessageBody from '../login/LoginMessageBody';
import TextField from '../modules/TextField';
import { FORGOT_PASSWORD } from '../../queries';

export const LoginContext = React.createContext();

const ForgotPassword = () => {
    const [error, setError] = useState(false);
    const [forgotPassword] = useMutation(FORGOT_PASSWORD);
    const [submit, setSubmit] = useState(false);
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            await forgotPassword({
                variables: {
                    email
                }
            });
            setSubmit(true);
        } catch (err) {
            setError(true);
        }
    };

    const handleKeyPress = (e) => {
        if (e.charCode === 13) {
            handleSubmit(e);
        }
    };

    return (
        <div className='Login'>
            <Helmet><title>Production Technology Portal Login Page</title></Helmet>
            <Row className='Login__Row no-gutters'>
                <Col sm={12} lg={4} className='Login__sidebar__container flex column'>
                    <div className='flex column'>
                        <img className='Login__logo' src={logoImage} alt='logo' />
                        <div className='Login__sidebar'>
                            <Heading number={5} block className='Login__title Login__title--Admin'>Forgot Password?</Heading>
                            {submit && <Body>Check your email for a link to reset your password</Body>}
                            {error && <Body className='Login__forgot-error'>We couldn't find your email in the system.</Body>}
                            <TextField
                                type='email'
                                value={email}
                                placeholder='Enter Your Email'
                                onChange={(e) => setEmail(e.target.value)}
                                autofocus
                                onKeyPress={handleKeyPress}
                            />
                            <Button
                                className='Login__signin' type='primary' onClick={handleSubmit}
                            >Submit Email
                            </Button>
                        </div>
                    </div>
                    <div className='Login__footer'>
                        <Caption className='Login__copyright flex center' number={2}>© Disney 2020. All Rights Reserved.</Caption>
                    </div>
                </Col>
                <Col sm={12} lg={8} className='Login__message-body'>
                    <LoginMessageBody authorizedUser />
                </Col>
            </Row>
        </div>
    );
};

export default ForgotPassword;
