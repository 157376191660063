import React, { Fragment } from 'react';
import Button from '../modules/Button';
import { Body, Heading } from '../modules/Typography';

const LoginAdmin = (props) => {
    return (
        <Fragment>
            <Heading number={5} block className='Login__title Login__title--Admin'>Welcome to the <br />Production Tech Portal</Heading>
            <a href='/auth/login'>
                <Button type='primary' className='Login__myid flex center'>Sign in using MyID</Button>
            </a>
            <Body className='Login__helpText' number={2}>Click above to log in with your Disney MyID,<br />@mppstudios.com, or @mcustudios.com email</Body>

        </Fragment>
    );
};

export default LoginAdmin;
