import { gql } from '@apollo/client';

export const GET_PRODUCT_DETAIL = gql`
  query getProducts($slug: String!, $publicationState: PublicationState) {
    products (publicationState: $publicationState, where: {slug: $slug
    }) {
        id
        name
        slug
        published_at
        description
        elevatorPitch
        approvalStatus
        constraints
        status
        marvel
        pixar
        disney
        twentiethCentury
        searchlight
        lucasfilm
        disneyAnimation
        categories {
          id
          name
        }
        thumbnail {
          url
        }
        videoThumbnail {
          url
        }
        subcategories {
          id
          name
          category {
            id
            name
          }
        }
        videoUrl
        media {
          ext
          url
        }
        applications {
          id
          title
        }
        relatedFiles {
          id
          title
          description
          document {
            id
            url
          }
        }
        details
        gallery{
          id
          text
          image{
            id
            caption
            height
            width
            url
          }
        }
        comments{
          id
          text
          title
          author
          restricted
        }
        qa{
          id
          question
          answer
        }
        contacts{
          id
          name
          type
          info
          title
        }
        vendor {
            published_at
            image {
                url
            }
            darkImage {
              url
            }
            name
            Contacts {
                name
                info
            }
            locations {
                city
                state
                country
            }
            websiteUrl
            facebookUrl
            instagramUrl
            twitterUrl
            linkedinUrl
            users {
                id
            }
        }
    }
  }
`;
