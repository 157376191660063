import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useLocation, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { VENDOR_GET_VENDOR } from '../../queries';
import VendorTopNav from './VendorTopNav';
import VendorSideNav from './VendorSideNav';
import VendorFooter from './VendorFooter';
import VendorBreadcrumbs from '../modules/VendorBreadcrumbs';
import Loading from '../modules/Loading';
import { UserContext } from '../../contexts/UserContextProvider';

export const VendorContext = React.createContext();

const VendorLayout = props => {
    const location = useLocation();
    const history = useHistory();
    const { vendorData } = useContext(UserContext);
    const vendorSlug = location.pathname.split('/')[1];
    const { data, loading, refetch } = useQuery(VENDOR_GET_VENDOR, { variables: { slug: `${vendorSlug}_preview` } });
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [vendorName, setVendorName] = useState('');
    const [activeTab, setActiveTab] = useState('home');
    let vendor = {};

    if (data) {
        if (data.vendors.length) {
            vendor = data.vendors[0];
        } else if (data.teams.length) {
            vendor = data.teams[0];
        }
    }

    useEffect(() => {
        let vendorSlugs;
        if (vendorData?.user?.vendors && vendorData?.user?.teams) {
            vendorSlugs = [...vendorData.user.vendors.map(vendor => vendor.slug), ...vendorData.user.teams.map(vendor => vendor.slug)];
        }
        if (vendorData && vendorSlugs && !vendorSlugs.includes(vendorSlug)) {
            history.push('/');
        }
    }, [vendorData, vendorSlug]);

    useEffect(() => {
        if (vendor?.name) {
            setVendorName(vendor.name.replace('_PREVIEW', ''));
        }
    }, [vendor?.name]);

    useEffect(() => {
        if (vendorName && breadcrumbs.length < 1) {
            setBreadcrumbs([]);
        }
    }, [vendorName]);

    if (loading) return <Loading />;
    return (
        <VendorContext.Provider value={{
            vendor,
            vendors: vendorData?.user?.vendors || [],
            teams: vendorData?.user?.teams || [],
            vendorSlug,
            vendorData,
            vendorName,
            refetch,
            breadcrumbs,
            setBreadcrumbs,
            activeTab,
            setActiveTab
        }}
        >
            <Helmet><title>Production Technology Portal</title></Helmet>
            <VendorTopNav />
            <VendorSideNav />
            <div className='VendorPortal'>

                {props.children}
            </div>
            <VendorFooter home={location.pathname.includes('vendor-home')} />
        </VendorContext.Provider>
    );
};

VendorLayout.propTypes = {
    children: PropTypes.object
};

export default VendorLayout;
