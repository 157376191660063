import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable, useFlexLayout, useSortBy } from 'react-table';

const Table = props => {
    const columns = useMemo(() => (props.columns), [props.columns]);

    const data = useMemo(() => (props.data), [props.data]);
    const options = {
        columns,
        data
    };
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(options, useFlexLayout, useSortBy);

    return (
        <table {...getTableProps()} className='ReactTable'>
            <thead>
                {headerGroups.map((headerGroup, idx) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={`headergroup-${idx}`}>
                        {headerGroup.headers.map((column, idx) => (
                            <th
                                key={`header-${idx}`}
                                className='Typography Typography--overline'
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                            >
                                {column.render('Header')}
                                <span>
                                    {column.isSorted ? column.isSortedDesc ? <span>&uarr;</span> : <span>&darr;</span> : ''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, idx) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} onClick={e => props.handleRowClick(row)} className='pointer' key={`row-${idx}`}>
                            {row.cells.map((cell, idx) => {
                                return (
                                    <td
                                        key={`cell-${idx}`}
                                        className='Typography Typography--subtitle-2'
                                        {...cell.getCellProps()}

                                    >
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default Table;

Table.propTypes = {
    // className to pass down to tags
    columns: PropTypes.array,
    data: PropTypes.array,
    handleRowClick: PropTypes.func

};

Table.defaultProps = {
    selectable: false,
    className: '',
    hasBorder: true,
    columns: [],
    data: []
};
