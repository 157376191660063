import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { WidthContext } from '../../contexts/WidthContextProvider';
import marvel from '../../images/marvelWhite.png';
import searchlight from '../../images/searchlightWhite.png';
import lucasfilm from '../../images/lucasfilmWhite.png';
import wdas from '../../images/wdasWhite.png';
import disney from '../../images/disneyWhite.png';
import pixar from '../../images/pixarWhite.png';
import twentieth from '../../images/twentiethWhite.png';
import { Heading } from '../modules/Typography';

const HomeSectionHeader = props => {
    const slug = useParams();
    const { isMobile } = useContext(WidthContext);
    const isBanner = props.isBanner;
    const logo = slug.banner;

    return (

        <div className={`Home__SectionHeader mobile-container ${props.className || ''} `} id={props.id}>
            {isBanner ? (
                <><Heading number={5}>Products Approved By</Heading>
                    <Heading>
                        {logo === 'marvel' && <img src={marvel} />}
                        {logo === 'pixar' && <img src={pixar} />}
                        {logo === 'lucasfilm' && <img src={lucasfilm} />}
                        {logo === 'searchlight' && <img src={searchlight} />}
                        {logo === 'disney' && <img src={disney} />}
                        {logo === 'wdas' && <img src={wdas} />}
                        {logo === 'twentieth' && <img src={twentieth} />}

                    </Heading>
                </>) : (<Heading number={isMobile ? 4 : 3}>{props.name}</Heading>
            )}
            <div className='Home__SectionHeader__divider' />
        </div>
    );
};

HomeSectionHeader.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    isBanner: PropTypes.bool

};

export default HomeSectionHeader;
