import { gql } from '@apollo/client';

export const SEARCH = gql`
    query search ($query: String!, $types: [String]) {
        search(q: $query, types: $types) {
            products {
                id
                slug
                thumbnail {
                    url
                }
                name
                description
                categories {
                    name
                }
                subcategories {
                    category {
                        name
                    }
                }
                vendor {
                    slug
                }
            }
            vendors {
                id
                slug
                name
                description
                image {
                    url
                }
            }
            posts {
                id
                slug
                media {
                    url
                }
                title
                content {
                    ...on ComponentPostsPdf {
                      file {
                        url
                      }
                    }
                    ...on ComponentPostsTextBox {
                      body
                    }
                }
                categories {
                    name
                }
                subcategories {
                    category {
                        name
                    }
                }
            }
            categories {
                id
                slug
                name
            }
        }
    }
`;
