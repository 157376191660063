import React, { useContext, useState, useEffect } from 'react';

import { Link, useParams, useHistory } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';
import { UPDATE_PRODUCT, UPLOAD, VENDOR_GET_PRODUCT, ADD_PRODUCT, SUBMIT_FOR_APPROVAL, MOVE_TO_DRAFT } from '../../queries';
import { VendorContext } from './VendorLayout';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { DataContext } from '../../contexts/DataContextProvider';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { UserContext } from '../../contexts/UserContextProvider';
import Button from '../modules/Button';
import Loading from '../modules/Loading';
import RouterPrompt from '../modules/RouterPrompt';
import ProductInformation from './ProductInformation';
import VendorMenuRight from './VendorMenuRight';

import { Heading, Caption, Overline } from '../modules/Typography';
import { Container, Row, Col } from '../modules/Grid';

const statuses = [
    { text: 'In Progress', value: 'inProgress' },
    { text: 'Ready', value: 'ready' }
];

const approvals = {
    draft: { text: 'Draft', icon: 'note', color: 'secondary-dark' },
    pendingApproval: { text: 'Pending Approval', icon: 'info', color: 'info' },
    published: { text: 'Published', icon: 'check-circle', color: 'success' },
    deleteRequested: { text: 'Delete Requested', icon: 'trash-can', color: 'error' }
};

export const ProductContext = React.createContext();

const ProductDetail = props => {
    const { productSlug } = useParams();
    const history = useHistory();
    const { setBreadcrumbs, setActiveTab, vendor, vendorName, vendorSlug, refetch } = useContext(VendorContext);
    const { isMobile } = useContext(WidthContext);
    const { successSnackbar } = useContext(SnackbarContext);
    const { user } = useContext(UserContext);
    const [getProduct, { data, loading }] = useLazyQuery(VENDOR_GET_PRODUCT, { variables: { slug: `${productSlug}_preview` } });
    const { allCategories } = useContext(DataContext);
    const [updateProduct] = useMutation(UPDATE_PRODUCT);
    const [createProduct] = useMutation(ADD_PRODUCT);
    const [upload] = useMutation(UPLOAD);
    const [submitForApproval] = useMutation(SUBMIT_FOR_APPROVAL);
    const [moveToDraft] = useMutation(MOVE_TO_DRAFT);
    const [applications, setApplications] = useState([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [elevatorPitch, setElevatorPitch] = useState('');
    const [details, setDetails] = useState('');
    const [status, setStatus] = useState('ready');
    const [constraints, setConstraints] = useState('');
    const [thumbnail, setThumbnail] = useState({ src: '', name: '', file: null });
    const [videoThumbnail, setVideoThumbnail] = useState({ src: '', name: '', file: null });
    const [media, setMedia] = useState({ src: '', name: '', file: null });
    const [gallery, setGallery] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [mediaLoading, setMediaLoading] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [category, setCategory] = useState(null);
    const [subcategories, setSubcategories] = useState([]);
    const [selectedSubcategories, setSelectedSubcategories] = useState([]);
    const [videoOption, setVideoOption] = useState('url');
    const [categories, setCategories] = useState([]);
    const [saving, setSaving] = useState(false);
    const [isNewProduct, setIsNewProduct] = useState(true);
    const [errors, setErrors] = useState([]);
    const [error, setError] = useState(false);
    const [approvalStatus, setApprovalStatus] = useState('draft');
    const [contacts, setContacts] = useState([]);
    const [updater, setUpdater] = useState('');
    const [updatedAt, setUpdatedAt] = useState(null);
    const [isDirty, setDirty] = useState(false);

    useEffect(() => {
        if (!isNewProduct) {
            getProduct();
        }
    }, [isNewProduct]);

    const product = data && data.products.length ? data.products[0] : {};

    useEffect(() => {
        setBreadcrumbs([{ text: 'Products' }, { text: product.original ? product.original.name : '' }]);
        setActiveTab('products');
        if (productSlug !== 'create') {
            setIsNewProduct(false);
            setDescription(product.description || '');
            setName(product.name || '');
            setElevatorPitch(product.elevatorPitch || '');
            setStatus(product.status || 'ready');
            setConstraints(product.constraints || '');
            setApprovalStatus(product.approvalStatus || 'draft');
            if (product.updater) {
                setUpdater(product.updater.username);
            }
            setUpdatedAt(product.updated_at);
            if (product.subcategories && product.subcategories.length) {
                setSelectedSubcategories(product.subcategories);
                setCategory(product.subcategories[0].category);
            } else if (product.categories && product.categories.length) {
                setCategory(product.categories[0]);
            }
            if (product.media) {
                setMedia({ name: product.media.name, src: product.media.url });
                setVideoOption('upload');
            } else {
                setVideoUrl(product.videoUrl);
                setVideoOption('url');
            }
            if (product.thumbnail) {
                setThumbnail({ name: product.thumbnail.name, src: product.thumbnail.url });
            }
            if (product.videoThumbnail) {
                setVideoThumbnail({ name: product.videoThumbnail.name, src: product.videoThumbnail.url });
            }
            if (product.relatedFiles) {
                setAttachments([...product.relatedFiles.filter(doc => doc)]);
            }
            if (product.applications) {
                setApplications([...product.applications.filter(doc => doc)]);
            }
            if (product.gallery) {
                setGallery([...product.gallery.filter(image => image)]);
            }
            if (product.details) {
                setDetails(product.details);
            }
            if (product.contacts) {
                setContacts(product.contacts);
            }
        } else {
            setIsNewProduct(true);
        }
        setTimeout(() => {
            setDirty(false);
        }, 1000);
    }, [data, productSlug, vendorName]);

    useEffect(() => {
        if (!isDirty) {
            setDirty(true);
        }
    }, [
        name,
        description,
        elevatorPitch,
        status,
        thumbnail.src,
        attachments,
        category,
        subcategories,
        applications,
        videoThumbnail,
        videoUrl,
        videoOption,
        media,
        details,
        constraints,
        contacts,
        gallery,
        attachments
    ]);

    useEffect(() => {
        setCategories(allCategories);
    }, [allCategories]);

    useEffect(() => {
        if (selectedSubcategories.length < 3) {
            const subcategories = category && category.subcategories
                ? category.subcategories.filter(subcat => (
                    !selectedSubcategories.map(selected => selected.id)
                        .includes(subcat.id)
                ))
                : [];
            setSubcategories(subcategories);
        }
    }, [category, selectedSubcategories]);

    useEffect(() => {
        const errs = [];
        const strippedDescription = description.replace(/(<([^>]+)>)/ig, '');
        if (!name) {
            errs.push('name');
        }
        if (!strippedDescription) {
            errs.push('description');
        }
        if (!thumbnail.src) {
            errs.push('thumbnail');
        }
        if (!category) {
            errs.push('category');
        }
        if (!elevatorPitch) {
            errs.push('pitch');
        }
        if (applications) {
            applications.forEach((el, i) => {
                if (el.title) {
                    if (!el.title) {
                        errs.push(`keyTitle-${i}`);
                    }
                }
            });
        }
        if (attachments) {
            attachments.forEach((el, i) => {
                if (!el.title) {
                    errs.push(`docTitle-${i}`);
                }
                if (!el.description) {
                    errs.push(`docDescription-${i}`);
                }
            });
            if (!videoUrl && (!media || !media.src)) {
                errs.push('video');
            }
        }

        setErrors(errs);
    }, [name, description, thumbnail.src, attachments, elevatorPitch, category, applications, videoUrl, media]);

    const handleGalleryDescription = (text, idx) => {
        const imageData = { ...gallery[idx], text };
        gallery[idx] = imageData;
        setGallery(gallery);
    };

    const handleFile = (file, name, idx) => {
        const reader = new FileReader();
        if (name === 'video') {
            reader.onload = (e) => {
                const src = e.target.result;
                const name = file.name;
                setMedia({ src, name, file });
            };
            handleRadio('upload');
        } else if (name === 'thumbnail') {
            reader.onload = (e) => {
                const src = e.target.result;
                const name = file.name;
                setThumbnail({ src, name, file });
            };
        } else if (name === 'videoThumbnail') {
            reader.onload = (e) => {
                const src = e.target.result;
                const name = file.name;
                setVideoThumbnail({ src, name, file });
            };
        } else if (name === 'gallery') {
            reader.onload = (e) => {
                const src = e.target.result;
                const name = file.name;
                const image = { src, file, title: name, text: '' };
                setGallery(gallery => [...gallery, image]);
            };
        } else if (name === 'applications') {
            reader.onload = (e) => {
                const apps = [...applications];
                const src = e.target.result;
                const name = file.name;
                const application = { ...apps[idx], image: { src, file, name } };
                apps[idx] = application;
                setApplications(apps);
            };
        }
        reader.readAsDataURL(file);
    };
    const handleDelete = (name, idx) => {
        if (name === 'video') {
            setMedia({ src: '', name: '', file: null });
        } else if (name === 'thumbnail') {
            setThumbnail({ src: '', name: '', file: null });
        } else if (name === 'videoThumbnail') {
            setVideoThumbnail({ src: '', name: '', file: null });
        } else if (name === 'attachments') {
            setAttachments(attachments.filter((doc, index) => (idx !== index)));
        } else if (name === 'gallery') {
            setGallery(gallery.filter((doc, index) => (idx !== index)));
        } else if (name === 'applications') {
            const newApp = [...applications];
            const app = { ...newApp[idx], image: { src: '', name: '', url: null } };
            newApp[idx] = app;
            setApplications(newApp);
        }
    };

    const removeFile = (idx, type) => {
        if (type === 'attachment') {
            setAttachments(attachments.filter((item, index) => (idx !== index)));
        } else if (type === 'gallery') {
            setGallery(gallery.filter((item, index) => (idx !== index)));
        } else if (type === 'application') {
            setApplications(applications.filter((item, index) => (idx !== index)));
        }
    };

    const handleSave = async (submit) => {
        if (errors.length) {
            setError(true);
        } else {
            setError(false);

            if (description.length > 2000 || constraints.length > 500 || elevatorPitch.length > 57) {
                return;
            }
            setSaving(true);
            const subcategories = selectedSubcategories.filter(cat => cat.id && cat.id !== cat.name).map(cat => cat.id);

            const data = {
                name,
                elevatorPitch,
                description,
                status,
                constraints,
                videoUrl,
                vendor: vendor.original.id,
                categories: category ? [category.id] : null,
                subcategories,
                contacts,
                details
            };
            if (media.file) {
                setMediaLoading(true);
                const response = await upload({ variables: { file: media.file } });
                setMediaLoading(false);
                data.media = response.data.upload.id;
                setMedia({ ...media, file: null });
            } else if (!media.src) {
                data.media = null;
            }

            if (videoThumbnail.file) {
                const response = await upload({ variables: { file: videoThumbnail.file } });
                data.videoThumbnail = response.data.upload.id;
                setVideoThumbnail({ ...videoThumbnail, file: null });
            } else if (!videoThumbnail.src) {
                data.videoThumbnail = null;
            }
            if (thumbnail.file) {
                const response = await upload({ variables: { file: thumbnail.file } });
                data.thumbnail = response.data.upload.id;
                setThumbnail({ ...thumbnail, file: null });
            } else if (!thumbnail.src) {
                data.thumbnail = null;
            }
            data.relatedFiles = [];
            if (attachments) {
                for (let i = 0; i < attachments.length; i++) {
                    const attachment = attachments[i];
                    let uploadId;
                    if (attachment.file) {
                        const response = await upload({ variables: { file: attachment.file } });
                        uploadId = response.data.upload.id;
                    } else {
                        uploadId = attachment ? attachment.document.id : '';
                    }
                    data.relatedFiles.push({ document: uploadId, description: attachment.description, title: attachment.title });
                }
            // setActiveIdx(null);
            }

            data.applications = [];
            if (applications) {
                for (let i = 0; i < applications.length; i++) {
                    const application = applications[i];
                    if (application.title) {
                        data.applications.push({
                            title: application.title
                        });
                    }
                }
            }

            data.gallery = [];
            if (gallery.length) {
                for (let i = 0; i < gallery.length; i++) {
                    const image = gallery[i];
                    let uploadId;
                    if (image.file) {
                        const response = await upload({ variables: { file: image.file } });
                        uploadId = response.data.upload.id;
                    } else {
                        uploadId = image ? image.image.id : '';
                    }
                    data.gallery.push({ image: uploadId, text: image.text });
                }
            }

            if (isNewProduct) {
                data.published_at = null;
                const response = await createProduct({
                    variables: {
                        data
                    }
                });
                if (submit) {
                    await submitForApproval({ variables: { id: product.id } });
                }
                const { slug } = response.data.createProduct.product;
                refetch();
                setDirty(false);
                setSaving(false);
                setTimeout(() => {
                    history.push(`/${vendorSlug}/products/${slug.replace('_preview', '')}`);
                }, 1000);
            } else {
                const response = await updateProduct({
                    variables: {
                        id: product.id,
                        data
                    }
                });
                const { updater, updated_at: updatedAt } = response.data.updateProduct.product;
                if (updater) {
                    setUpdater(updater.username);
                }
                setUpdatedAt(updatedAt);
                if (submit) {
                    const response = await submitForApproval({ variables: { id: product.id, updatedBy: user.id } });
                    const { updater, updated_at: updatedAt } = response.data.updateProduct.product;
                    setApprovalStatus('pendingApproval');
                    if (updater) {
                        setUpdater(updater.username);
                    }
                    setUpdatedAt(updatedAt);
                } else if (response.approvalStatus !== 'draft') {
                    const response = await moveToDraft({ variables: { id: product.id, updatedBy: user.id } });
                    const { updater, updated_at: updatedAt } = response.data.updateProduct.product;
                    setApprovalStatus('draft');
                    if (updater) {
                        setUpdater(updater.username);
                    }
                    setUpdatedAt(updatedAt);
                }
                const { slug } = response.data.updateProduct.product;
                setDirty(false);
                setSaving(false);
                if (slug.replace('_preview', '') !== productSlug) {
                    setTimeout(() => {
                        history.push(`/${vendorSlug}/products/${slug.replace('_preview', '')}`);
                    }, 1000);
                }
            }
        }
    };

    const handleSubmit = async () => {
        if (errors.length) {
            setError(true);
        } else {
            setError(false);
            await handleSave(true);
            successSnackbar({ text: 'You have successfully submitted and will hear from us soon' });
            history.push(`/${vendorSlug}/products`);
        }
    };

    const handleRadio = (value) => {
        setVideoOption(value);
        if (value === 'url') {
            handleDelete('video');
        } else {
            setVideoUrl('');
        }
    };

    if (loading) return <Loading />;
    const approval = approvals[approvalStatus];
    return (
        <ProductContext.Provider
            value={{
                product,
                handleFile,
                removeFile,
                handleDelete,
                error,
                errors,
                saving,
                mediaLoading,
                attachments,
                applications,
                setApplications,
                setAttachments,
                gallery,
                setGallery,
                name,
                setName,
                contacts,
                thumbnail,
                videoThumbnail,
                videoUrl,
                media,
                categories,
                constraints,
                setConstraints,
                statuses,
                status,
                elevatorPitch,
                setElevatorPitch,
                description,
                setDescription,
                videoOption,
                category,
                subcategories,
                selectedSubcategories,
                setSelectedSubcategories,
                setContacts,
                details,
                setDetails,
                setCategory,
                setStatus,
                setVideoUrl,
                approval,
                updater,
                updatedAt,
                handleRadio,
                isDirty,
                handleGalleryDescription
            }}
        >
            <div className='VendorInformation'>
                <Container className='VendorInformation__container'>
                    <div className='VendorInformation__header flex'>
                        <div>
                            <Heading className='VendorInformation__title' block number={isMobile ? 5 : 3}>Product</Heading>
                        </div>
                        <div className='flex'>
                            <Button
                                type='tertiary'
                                className='VendorInformation__button'
                                size={isMobile ? 'small' : 'regular'}
                            >
                                <Link to={`/${vendorSlug}/products`}>
                                    Cancel
                                </Link>
                            </Button>
                            <div className='VendorInformation__button VendorInformation__button--save'>
                                <Button
                                    type='secondary'
                                    className='VendorInformation__button'
                                    onClick={() => handleSave(false)}
                                    size={isMobile ? 'small' : 'regular'}
                                    loading={saving}
                                    color={description.length > 1000 || constraints.length > 500 || error ? 'error' : 'default'}
                                >Save Draft
                                </Button>
                                <div className='flex column VendorInformation__button--error-container'>
                                    {description.length > 1000 && <Caption error>Description must be less than 1000 characters.</Caption>}
                                    {constraints.length > 500 && <Caption error>Constraints must be less than 500 characters.</Caption>}
                                </div>
                            </div>

                            <Button
                                type='primary'
                                className='VendorInformation__button'
                                onClick={handleSubmit}
                                loading={saving}
                                size={isMobile ? 'small' : 'regular'}
                                color={error ? 'error' : 'default'}
                            >
                                Submit for Approval
                            </Button>
                        </div>
                    </div>
                    <Row>
                        <Col sm={10}>
                            <div>
                                <div className='VendorInformation__tabs flex flex-start'>
                                    <Overline className='VendorInformation__tab tabSelected' block number={2}>Product Information</Overline>
                                </div>
                                <ProductInformation />
                            </div>
                        </Col>
                        <VendorMenuRight vendorSlug={vendorSlug} productSlug={product.slug} loading={saving} />
                    </Row>
                </Container>
                <RouterPrompt when={isDirty} onOK={() => true} onCancel={() => false} />
            </div>
        </ProductContext.Provider>
    );
};

ProductDetail.propTypes = {
};

export default ProductDetail;
