import React from 'react';
import PropTypes from 'prop-types';
import { Caption, Body } from './Typography';
import MenuSurface from './MenuSurface';
import Tooltip from './Tooltip';
import Icon from './Icon';

const Select = props => {
    const selectedOption = props.options?.find(option => option.value === props.selectedValue) || {};
    let text = props.placeholder;
    if (selectedOption.text) {
        if (!props.hideText) {
            text = selectedOption.selectedText || selectedOption.text;
        }
    } else {
        text = props.placeholder || '';
    }
    return (
        <div className={[
            'Select',
            props.disabled ? 'Select--disabled' : null,
            props.tooltip ? 'Select--tooltip' : null,
            props.searchable ? 'Select--searchable' : null,
            `Select--${props.styleType}`,
            props.className
        ].filter(i => i).join(' ')}
        >
            <div className='flex space-between'>
                {props.label && <Caption faded={props.disabled} className='TextField__label Select__label'>{props.label}</Caption>}
                {props.rightLabel && <Caption faded className='TextField__label Select__label Select__rightLabel'>{props.rightLabel}</Caption>}
            </div>
            <div className={`Select__wrapper ${props.fullWidth && 'full-width'} `}>
                {props.icon && <Icon name={props.icon} className='Select__icon' />}
                <MenuSurface
                    searchable={props.searchable}
                    addText={props.addText}
                    className='Select__MenuSurface'
                    icon={props.listIcon}
                    onAdd={props.onAdd}
                    options={props.options.map((option, index) => ({
                        ...option,
                        onClick: () => props.onChange({ value: option.value, index })
                    }))}
                >
                    <div className='Select__bar Tooltip__revealer'>
                        {props.selectedTooltip && <Tooltip>{props.selectedTooltip}</Tooltip>}
                        <Body className='Select__bar__text' number={2}>{text}</Body>
                    </div>
                </MenuSurface>
            </div>
            {props.error && props.errorMessage && <Caption number={2} error className='TextField__error'>{props.errorMessage}</Caption>}
        </div>
    );
};

export default Select;

Select.propTypes = {
    // function to handle select change event
    onChange: PropTypes.func,
    onAdd: PropTypes.func,

    // options in the select drop down
    options: PropTypes.array,

    // whether or not the select input is disabled
    disabled: PropTypes.bool,
    hideText: PropTypes.bool,

    // item that has been selected
    selectedValue: PropTypes.string,

    // className to pass down to the tags
    className: PropTypes.string,

    // label to associate with the select menu
    label: PropTypes.string,
    rightLabel: PropTypes.string,
    placeholder: PropTypes.string,

    // type of style the select is
    styleType: PropTypes.oneOf(['default', 'dark']),

    // icon to showcase left of the select menu
    icon: PropTypes.string,
    listIcon: PropTypes.string,
    addText: PropTypes.string,
    errorMessage: PropTypes.string,
    error: PropTypes.bool,
    tooltip: PropTypes.bool,
    selectedTooltip: PropTypes.string,

    // whether or not the select field is searchable
    searchable: PropTypes.bool,

    // sets width to parent container
    fullWidth: PropTypes.bool
};

Select.defaultProps = {
    disabled: false,
    className: '',
    addText: '',
    styleType: 'default',
    searchable: false,
    placeholder: '',
    fullWidth: false
};
