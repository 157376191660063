import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/mpi-logo.png';
import { VendorContext } from './VendorLayout';
import Icon from '../modules/Icon';
import { Subtitle, Body } from '../modules/Typography';

const VendorSideNav = props => {
    const { vendor, activeTab, vendorData } = useContext(VendorContext);
    return (
        <div className='SideNav'>
            <Subtitle className='SideNav__title'>VENDOR PORTAL</Subtitle>
            {vendorData.user.vendors.map(account => (
                <>
                    <Link to={`/${account.slug}/vendor-information`}>
                        <div className='SideNav__item spacing-lg button'>
                            <Icon className={`SideNav__icon ${activeTab === 'info' ? 'active' : ''}`} name='star' />
                            <Subtitle color='gray'>{account.name}</Subtitle>
                        </div>
                    </Link>
                    <Link to={`/${account.slug}/products`}>
                        <div className='spacing-sm button flex flex-start'>
                            <div>
                                <Icon className='SideNav__icon' name='box' />
                                <Subtitle className='SideNav__text'>{account.products.filter(prod => !prod.name.includes('_PREVIEW')).length > 1 ? 'Products' : 'Product'}</Subtitle>
                                <Subtitle />
                            </div><br />
                        </div>
                    </Link>
                    <div className='flex-start column'>
                        {account.products.filter(prod => !prod.name.includes('_PREVIEW')).map(product => (
                            <div className='SideNav__products spacing-xs'>
                                <Link to={`/${account.slug}/products/${product.slug}`}><Body number={2}>{product.name}</Body> <br /></Link>
                            </div>
                        ))}
                    </div>
                </>
            ))}

            {/* <Link to='/graphiql'>
                <div className='spacing-sm button'>
                    <Icon className={`SideNav__icon ${activeTab === 'home' ? 'active' : ''} ${!vendor.published_at ? 'disabled' : ''}`} name='star' />
                    <Subtitle className={!vendor.published_at ? 'SideNav__text disabled' : ''} color='gray'>graphiql</Subtitle>
                </div>
            </Link>
          <Link to='/faqs'> // deactivating link till FAQ page avail
                <div className='spacing-sm button flex flex-start'>
                    <Icon className={`SideNav__icon ${activeTab === 'products' ? 'active' : ''}`} name='help' />
                    <Subtitle color='gray'>Helpful Resources</Subtitle>
                </div>
            </Link> */}
        </div>

    );
};

export default VendorSideNav;
