import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Overline } from './Typography';

const Breadcrumbs = props => {
    return (
        <div className={`Breadcrumbs ${props.className || ''}`}>
            {props.breadcrumbs.map((breadcrumb, idx) => (
                <div key={breadcrumb.text}>
                    {idx !== 0 && <Overline number={2} className='Breadcrumbs__text'>/</Overline>}
                    {breadcrumb.link ? (
                        <Link to={breadcrumb.link}>
                            <Overline number={2} className='Breadcrumbs__text'>{breadcrumb.text}</Overline>
                        </Link>
                    ) : (
                        <Overline number={2} className='Breadcrumbs__text'>{breadcrumb.text}</Overline>
                    )}
                </div>
            ))}
        </div>
    );
};

Breadcrumbs.propTypes = {
    breadcrumbs: PropTypes.array,
    className: PropTypes.string
};

Breadcrumbs.defaultProps = {
    breadcrumbs: []
};

export default Breadcrumbs;
