import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserContext } from '../../contexts/UserContextProvider';
import { WidthContext } from '../../contexts/WidthContextProvider';
import logo from '../../images/ptp-logo.svg';
import TopNav from '../nav/TopNav';
import Footer from './Footer';
import Loading from '../modules/Loading';

const Layout = props => {
    const history = useHistory();
    const { isSmallScreen } = useContext(WidthContext);
    const { pathname } = useLocation();
    const isPreview = pathname.includes('product-preview') || pathname.includes('vendor-preview');
    const { user, vendorData } = useContext(UserContext);
    useEffect(() => {
        if (user.id && user.role.name === 'Vendor' && vendorData && vendorData.user?.vendors?.length && !isPreview) {
            history.push(`/${vendorData.user.vendors[0].slug}/vendor-home`);
        }
    }, [user, vendorData]);

    useEffect(() => {
        if (window.s) {
            window.s.pageName = pathname;
            window.s.t();
        }
        document.body.classList = ['light'];
    }, [pathname, window.s]);

    if (user.role.name === 'Vendor' && !isPreview) {
        return <Loading />;
    }

    return (
        <div className={`Layout ${isSmallScreen ? 'no-scroll' : ''}`}>
            {isSmallScreen &&
            <>
                <div className='modal-backdrop-layout' />
                <div className='modal-container-layout'>
                    <div className='modal-content-layout '>
                        <img src={logo} />

                        Please open on a desktop for optimal user experience.
                    </div>
                </div>
            </>}
            <TopNav role={user.role.name} />
            <div id='main-window'>
                {props.children}
            </div>
            {!isPreview && <Footer />}
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.object
};

Layout.defaultProps = {

};

export default Layout;
