import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Radio = props => {
    const [id] = useState(`${props.value.toLowerCase().replace(/ /g, '-')}-${Math.floor(Math.random() * 1000)}`);

    return (
        <div className={`Radio ${props.className} ${props.inline ? 'Radio--inline' : ''} ${props.padded ? 'Radio--padded' : ''}`}>
            <input
                checked={props.selected}
                className='Radio__input'
                type='radio'
                id={id}
                name={props.groupName}
                value={props.value}
                onChange={() => props.handleChange(props.value)}
            />

            <label htmlFor={id} className='Radio__label Typography--caption1'>
                {props.flipText && props.name}
                <div className={[
                    'Radio__circle',
                    props.selected ? 'Radio__circle--selected' : null,
                    props.flipText ? 'Radio__circle--flipped' : null,
                    props.className ? `${props.className}__circle` : null
                ].filter(i => i).join(' ')}
                >
                    <div className='Radio__circle__outer' />
                    <div className='Radio__circle__inner' />
                </div>
                {!props.flipText && props.name}
            </label>
        </div>
    );
};

export default Radio;

Radio.propTypes = {
    // whether the radio is selected or not
    selected: PropTypes.bool,

    // label name of the radio button
    name: PropTypes.string,

    // name of the group radio button is a part of
    groupName: PropTypes.string,

    // value of the radio option
    value: PropTypes.string,

    // className to pass down to tags
    className: PropTypes.string,

    // function to handle radio state change
    // (value) => alert(`${value} has been selected`)
    handleChange: PropTypes.func,

    // whether or not the radio and label should be reversed
    flipText: PropTypes.bool,

    // whether or not the radio is inline
    inline: PropTypes.bool,

    // whether or not the radio is padded below
    padded: PropTypes.bool
};

Radio.defaultProps = {
    selected: false,
    className: '',
    flipText: false,
    inline: false,
    padded: true
};
