import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { UserContext } from '../../contexts/UserContextProvider';
import { DataContext } from '../../contexts/DataContextProvider';
import { GET_VENDOR_CATEGORIES, GET_PRODUCT_PAGE } from '../../queries';
import { Container, Col } from '../modules/Grid';
import { Heading } from '../modules/Typography';
import Loading from '../modules/Loading';
import IconButton from '../modules/IconButton';
import HomeSectionHeader from '../home/HomeSectionHeader';
import CategoriesList from '../categories/CategoriesList';
import CategoryProducts from '../categories/CategoryProducts';
import AllCategories from '../categories/AllCategories';
import parseMarkdown from '../../utils/markdownParser';

export const CategoryContext = React.createContext();

const Categories = props => {
    const { slug } = useParams();
    const { pathname } = useLocation();
    const isVendor = pathname.includes('/products');
    const isBanner = pathname.includes('banner');
    const { setSubcategory, subcategory, setCategory } = useContext(DataContext);
    const { data: pageData } = useQuery(GET_PRODUCT_PAGE);
    const { isMobile } = useContext(WidthContext);
    const {
        setUserCategory,
        setUserSubcategory,
        setUserTeamCategory,
        setUserTeamSubcategory,
        publicationState
    } = useContext(UserContext);
    const { data, loading } = useQuery(GET_VENDOR_CATEGORIES, { variables: { publicationState } });
    const [listOpen, setOpen] = useState(!isMobile);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (slug && data) {
            const category = categories.find(cat => cat.slug === slug);
            if (category) {
                setCategory(category.id);
                setSubcategory(`${category.id}-all`);
                if (isVendor) {
                    setUserCategory(category.id);
                    setUserSubcategory(`${category.id}-all`);
                } else {
                    setUserTeamCategory(category.id);
                    setUserTeamSubcategory(`${category.id}-all`);
                }
            }
        }
    }, [slug, data]);

    if (loading) return <Loading />;
    const pageTitle = pageData ? pageData.productShowcasePage : {};
    const { headerDescription, banner } = pageTitle;

    const categories = data && data.categories.filter(cat => (cat.products.length || cat.subcategories.find(sub => (sub.products.length))));

    return (
        <CategoryContext.Provider value={{
            listOpen,
            setOpen,
            slug,
            isVendor
        }}
        >
            <div className='Categories flex column flex-start'>
                <Helmet><title>PTP | Products</title></Helmet>
                <div className='Categories__banner' style={{ backgroundImage: `url(${banner ? banner.url : ''})` }}>
                    <Container>
                        <HomeSectionHeader name='Products' isBanner={isBanner} className='Categories__title' />
                        <Col className='Categories__title__subtitle' md={8}>
                            <Heading number={isMobile ? 7 : 5}>{parseMarkdown(headerDescription)}</Heading>
                        </Col>
                    </Container>
                </div>
                <Container className='Categories__container flex align-flex-start'>
                    {isMobile && !subcategory ? (
                        <div className='flex'>
                            <IconButton className='Categories__button' name={isVendor ? 'gallery-view' : 'cpu'} onClick={() => setOpen(true)} />
                            <Heading number={6}>Categories</Heading>
                        </div>
                    ) : ''}
                    <div className={`Categories__content ${!listOpen ? 'visually-hidden' : ''}`}>
                        <CategoriesList categories={categories} />
                    </div>
                    <div className='Categories__Product'>
                        {subcategory ? <CategoryProducts /> : <AllCategories />}
                    </div>
                </Container>
            </div>
        </CategoryContext.Provider>
    );
};

export default Categories;
