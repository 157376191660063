const getCategoryName = post => {
    let category;
    if (post.subcategories.length) {
        category = post.subcategories[0];
    } else if (post.categories.length) {
        category = post.categories[0];
    }
    return category ? category.name : '';
};

export default getCategoryName;
